import { Chip, Typography, styled } from '@mui/material';

export const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  backgroundColor: '#f0f0f0',
  '& .MuiChip-label': {
    px: theme.spacing(1),
    fontSize: '0.75rem',
    lineHeight: 1.2,
  },
}));

export const TooltipText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.grey[600],
  fontWeight: theme.typography.fontWeightBold,
  cursor: 'pointer',
}));
