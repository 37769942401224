export type AuditLogChangesetValue = string | number | undefined | null;

export enum AuditLogSource {
  OperationDashboard = 'operation-dashboard',
  Notes = 'notes',
  System = 'system',
}

type AuditLogChanges = {
  [field: string]: [AuditLogChangesetValue, AuditLogChangesetValue];
};

export enum AuditLogAction {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE',
}

export interface AuditLog {
  id: number;
  createdAt: string;
  userId: number;
  transactionId: string;
  userFullName: string;
  action: AuditLogAction;
  resourceName: string;
  resourceId: string;
  source: string;
  changes: AuditLogChanges;
}
