import './loader-bubble.css';
export const LoaderBubble = () => {
  return (
    <div className='container'>
      <div className='slice'></div>
      <div className='slice'></div>
      <div className='slice'></div>
      <div className='slice'></div>
      <div className='slice'></div>
      <div className='slice'></div>
    </div>
  );
};
