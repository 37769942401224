import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  List,
  ListItem,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InfoIcon from '@mui/icons-material/Info';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useEffect, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Option } from '../../../interfaces/common';
import {
  Condition,
  ConditionWithRelations,
  Pagination,
  PartialSome,
  Patient,
  PatientConditionFromReport,
  PatientConditionStatus,
} from '../../../interfaces/health-data';
import healthDataService from '../../../services/health-data';
import { arrayWithoutEntry } from '../../../util/array';
import { DEFAULT_PAGE_SIZE, INITIAL_PAGINATION_STATE } from '../../../util/constants';
import { getDateStringFromUTCDate } from '../../../util/dateFormat';
import { BackButton } from '../../common/BackButton';
import { CustomTable } from '../../common/CustomTable';
import { LoaderBubble } from '../../common/LoaderBubble/LoaderBubble';
import { OptionsMenu } from '../../common/OptionsMenu/OptionsMenu';

export const EditPatientCondition = () => {
  const [activePatientConditions, setActivePatientConditions] = useState<
    PatientConditionFromReport[]
  >([]);
  const [resolvedPatientConditions, setResolvedPatientConditions] = useState<
    PatientConditionFromReport[]
  >([]);

  const [updatedPatientConditions, setUpdatedPatientConditions] = useState<
    PatientConditionFromReport[]
  >([]);
  const [newPatientConditions, setNewPatientConditions] = useState<
    PartialSome<PatientConditionFromReport, 'id'>[]
  >([]);

  // Pagination
  const [resolvedPatientConditionsPagination, setResolvedPatientConditionsPagination] =
    useState<Pagination>(INITIAL_PAGINATION_STATE);

  const [patient, setPatient] = useState<Patient | null>(null);
  const [conditionOptionsSelected, setConditionOptionsSelected] = useState<Option<number>[]>([]);
  const [resolvedPatientConditionPage, setResolvedPatientConditionPage] = useState<number>(1);
  const [conditionsWithRelations, setConditionsWithRelations] = useState<ConditionWithRelations[]>(
    [],
  );
  const [breadcrumbLabels, setBreadcrumbLabels] = useState<string[]>([]);
  const [openSaveConfirmationModal, setOpenSaveConfirmationModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const notify = useNotify();
  const redirect = useRedirect();
  const { patientId } = useParams<{
    patientId: string;
  }>();

  const conditionsOptions = conditionsWithRelations.map((c) => ({
    label: c.condition.name,
    value: c.condition.id,
  }));

  const conditionsById: { [id: number]: Condition } = conditionsWithRelations.reduce(
    (pv, cv) => ({ ...pv, [cv.condition.id]: cv.condition }),
    {},
  );

  const activeConditionsByConditionId: { [id: number]: PatientConditionFromReport } =
    activePatientConditions.reduce((pv, cv) => ({ ...pv, [cv.conditionId]: cv }), {});

  const handleSaveConfirmationModal = () =>
    setOpenSaveConfirmationModal(!openSaveConfirmationModal);

  const handleChangeConditionStatus = (
    patientCondition: PatientConditionFromReport,
    status: PatientConditionStatus,
  ) => {
    if (status === PatientConditionStatus.Resolved) {
      const patientConditionIndex = conditionOptionsSelected.findIndex(
        (p) => p.value === patientCondition.conditionId,
      );

      if (patientConditionIndex === -1) {
        return;
      }

      setActivePatientConditions(arrayWithoutEntry(patientConditionIndex, activePatientConditions));

      setUpdatedPatientConditions([...updatedPatientConditions, { ...patientCondition, status }]);
    }
  };

  const handleChangeRecord = (options: Option<number>[]) => {
    setConditionOptionsSelected(options);

    const updatedConditions: PartialSome<PatientConditionFromReport, 'id'>[] = options
      .filter((op) => !activeConditionsByConditionId[op.value])
      .map((condition) => {
        const conditionById = conditionsById[condition.value] ?? {};

        return {
          status: PatientConditionStatus.Active,
          patientId,
          code: `ICD-10: ${conditionById.icd10Code}`,
          conditionId: conditionById.id,
          condition: conditionById.name,
          firstSeen: null,
          lastSeen: null,
          deleteAt: null,
          associatedCondition: conditionById,
        };
      });

    setNewPatientConditions(updatedConditions);
  };

  const handleSubmit = async () => {
    const updateActiveConditionsPayload = updatedPatientConditions.map((c) => ({
      id: c.id,
      status: c.status,
    }));

    const newActiveConditionsPayload = newPatientConditions.map((c) => ({
      condition: c.condition,
      code: `ICD-10: ${c.associatedCondition?.icd10Code}`,
      conditionId: c.conditionId,
      status: c.status || PatientConditionStatus.Active,
      patientId,
    }));

    const result = await healthDataService.updatePatientConditions({
      patientId,
      conditions: { create: newActiveConditionsPayload, update: updateActiveConditionsPayload },
    });

    if (!result.data) {
      notify('There was an error updating the active condition', 'warning');
      return;
    }

    notify('Active Condition updated successfully');
    redirect(`/patients/${patientId}/conditions/show`);
  };

  const handleChangePage = (page: number) => {
    setResolvedPatientConditionPage(page);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const conditionsResponse = await healthDataService.findAllConditions();
      const activePatientConditions = await healthDataService.fetchConditionsAssociatedWithPatient({
        patientId,
        status: PatientConditionStatus.Active,
      });

      if (!activePatientConditions.data) {
        notify(activePatientConditions.message || 'There was an error obtaining patient info');
        return;
      }

      const resolvedPatientConditions =
        await healthDataService.fetchConditionsAssociatedWithPatient({
          patientId,
          status: PatientConditionStatus.Resolved,
        });

      if (!resolvedPatientConditions.data) {
        notify(resolvedPatientConditions.message || 'There was an error obtaining patient info');
        return;
      }
      if (!conditionsResponse.data) {
        notify(conditionsResponse.message || 'There was an error obtaining patient info');
        return;
      }

      setBreadcrumbLabels([
        'Conditions',
        'Patient',
        activePatientConditions?.data.patient?.internalId || 'Unknown',
      ]);

      // Conditions
      setConditionsWithRelations(conditionsResponse?.data.conditions || []);

      // Conditions related to patient
      setActivePatientConditions(activePatientConditions.data.conditions);
      setResolvedPatientConditions(resolvedPatientConditions.data.conditions);
      setResolvedPatientConditionsPagination(resolvedPatientConditions.data.pagination);

      setConditionOptionsSelected(
        activePatientConditions.data.conditions.map((c) => ({
          label: c.condition,
          value: c.conditionId,
        })),
      );

      // Patient
      setPatient(activePatientConditions.data.patient);

      setIsLoading(false);
    })();
  }, []);

  if (!patient && !isLoading) {
    return (
      <Stack spacing={2}>
        <Typography>
          There is an unknown error. Please contact with Enara's Tech Support describing this issue.
        </Typography>
        <BackButton basePath='/patients/conditions' />
      </Stack>
    );
  }

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' className='tab-section-action-bar'>
        <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
          {breadcrumbLabels.map((text, index) => {
            return (
              <Typography
                key={`text-patient-condition-breadcrumb-${index}`}
                textTransform='capitalize'>
                {text}
              </Typography>
            );
          })}
        </Breadcrumbs>

        <Box>
          <Button
            onClick={() => redirect(`/patients/${patientId}/conditions/show`)}
            disabled={isLoading}
            variant='outlined'
            sx={{ textTransform: 'capitalize', marginRight: '1rem' }}>
            Discard
          </Button>

          <Button
            onClick={handleSaveConfirmationModal}
            disabled={
              isLoading ||
              (newPatientConditions.length === 0 && updatedPatientConditions.length === 0)
            }
            variant='contained'
            sx={{ textTransform: 'capitalize' }}>
            Save
          </Button>
        </Box>
      </Box>

      {!isLoading ? (
        <Box>
          <Box className='tab-section patient-condition-section'>
            <Box className='tab-section-form-field patient-condition-section-group'>
              <Typography variant='body2'>Patient Name:</Typography>
              <TextField
                key={'patient-condition-text-field-name'}
                size='small'
                disabled
                value={patient?.name}
              />
            </Box>

            <Box className='tab-section-form-field patient-condition-section-group'>
              <Typography variant='body2'>ID:</Typography>
              <TextField
                key={'patient-condition-text-field-cpt-code'}
                size='small'
                disabled
                value={patient?.internalId}
              />
            </Box>

            <Box className='tab-section-form-field patient-condition-section-group'>
              <Typography variant='body2'>Clinic Name:</Typography>
              <TextField
                key={'patient-condition-text-field-cpt-code'}
                size='small'
                disabled
                value={patient?.clinicName}
              />
            </Box>
          </Box>

          <Box className='tab-section'>
            <Box>
              <Typography mb={4}>Active Conditions</Typography>

              <CustomTable
                columns={[
                  { key: 'icd10Code', label: 'ICD-10 Code', minWidth: '80px' },
                  { key: 'name', label: 'Problem', width: '50%' },
                  { key: 'status', label: 'Status', minWidth: '40px' },
                  {
                    key: 'firstSeenOn',
                    label: 'First Seen',
                    align: 'left',
                    minWidth: '100px',
                  },
                  {
                    key: 'lastSeenOn',
                    label: 'Last Seen',
                    align: 'left',
                    minWidth: '100px',
                  },
                  {
                    key: 'actions',
                    label: 'Actions',
                    align: 'left',
                    minWidth: '70px',
                  },
                ]}
                defaultText='No active conditions associated to patient'
                key={'conditions-form-tests-table'}
                rows={
                  [...activePatientConditions, ...newPatientConditions]?.map((data, index) => {
                    const cleanedCode = data.code?.split(':')?.[1].trim();
                    return {
                      icd10Code: cleanedCode,
                      name: data.condition,
                      status: data.status,
                      firstSeenOn: getDateStringFromUTCDate(data.firstSeen),
                      lastSeenOn: getDateStringFromUTCDate(data.lastSeen),
                      actions: (
                        <Box display='flex' alignItems='center' justifyContent='end'>
                          {activeConditionsByConditionId[data.conditionId] && (
                            <Tooltip
                              title='Previously added conditions cannot be deleted. You can modify their status instead'
                              arrow
                              placement='top-start'>
                              <InfoIcon
                                sx={{ color: '#8c8c8c', fontSize: '1.3rem', padding: '8px' }}
                              />
                            </Tooltip>
                          )}
                          <OptionsMenu
                            variant='outlined'
                            options={[
                              {
                                label: 'Resolve',
                                onClick: () => {
                                  handleChangeConditionStatus(
                                    data as PatientConditionFromReport,
                                    PatientConditionStatus.Resolved,
                                  );
                                },
                                icon: <CheckIcon />,
                              },
                              ...(!activeConditionsByConditionId[data.conditionId]
                                ? [
                                    {
                                      label: 'Remove',
                                      onClick: () => {
                                        const conditionIndex = conditionOptionsSelected.findIndex(
                                          (p) => p.value === data.conditionId,
                                        );

                                        if (conditionIndex === -1) {
                                          return;
                                        }

                                        const options = arrayWithoutEntry(
                                          conditionIndex,
                                          conditionOptionsSelected,
                                        );

                                        setConditionOptionsSelected(options);
                                        handleChangeRecord(options);
                                      },
                                      icon: <DeleteOutlineIcon color='error' />,
                                    },
                                  ]
                                : []),
                            ]}
                          />
                        </Box>
                      ),
                    };
                  }) || []
                }
              />
            </Box>

            <Modal open={openSaveConfirmationModal} className='confirmation-modal' sx={{}}>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                alignItems='center'
                className='confirmation-modal-container'
                sx={{
                  padding: '4rem 3rem 2.5rem',
                  height: 'auto',
                }}>
                <Box display='flex' alignSelf='baseline'>
                  {newPatientConditions.length > 0 && (
                    <Box sx={{ paddingLeft: '20px' }}>
                      <Typography>The following records will be created:</Typography>
                      <List sx={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        {newPatientConditions.map((c, index) => (
                          <ListItem key={index} sx={{ display: 'list-item', paddingLeft: '8px' }}>
                            <Typography>
                              {c.condition} ({c.status})
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}
                  {updatedPatientConditions.length > 0 && (
                    <Box>
                      <Typography fontWeight={500}>
                        The following records will be updated:
                      </Typography>
                      <List sx={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        {updatedPatientConditions.map((c, index) => (
                          <ListItem key={index} sx={{ display: 'list-item', paddingLeft: '8px' }}>
                            <Typography>
                              {c.condition} ({c.status})
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}
                </Box>

                <Box display='flex' gap={2} mt={3}>
                  <Button
                    fullWidth
                    className='confirmation-modal-button'
                    variant='outlined'
                    sx={{
                      color: '#1a667a',
                    }}
                    onClick={handleSaveConfirmationModal}>
                    Dismiss
                  </Button>
                  <Button
                    fullWidth
                    className='confirmation-modal-button'
                    variant='contained'
                    sx={{
                      backgroundColor: '#1a667a',
                    }}
                    onClick={handleSubmit}>
                    Confirm
                  </Button>
                </Box>
              </Box>
            </Modal>

            <Autocomplete
              disableClearable
              renderTags={() => null}
              disablePortal
              options={conditionsOptions}
              sx={{ width: 500, marginTop: '1rem' }}
              multiple
              getOptionLabel={(option) => option.label}
              disableListWrap
              getOptionDisabled={(option) =>
                !!conditionOptionsSelected.find((p) => p.value === option.value)
              }
              value={conditionOptionsSelected}
              fullWidth
              onChange={(e, options) => handleChangeRecord(options)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  key={`autocomplete-patient-condition`}
                  size='small'
                  label='Add a new condition'
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </Box>

          <Box className='tab-section'>
            <Box>
              <Typography mb={4}>Resolved Conditions</Typography>

              <CustomTable
                columns={[
                  { key: 'icd10Code', label: 'ICD-10 Code', minWidth: '50px' },
                  { key: 'name', label: 'Problem', width: '50%' },
                  {
                    key: 'firstSeenOn',
                    label: 'First Seen',
                    align: 'left',
                    minWidth: '100px',
                  },
                  {
                    key: 'lastSeenOn',
                    label: 'Last Seen',
                    align: 'left',
                    minWidth: '100px',
                  },
                ]}
                defaultText='No resolved conditions associated to patient'
                key={'conditions-form-tests-table'}
                pagination={{
                  rowsPerPageOptions: [DEFAULT_PAGE_SIZE],
                  rowPerPage: resolvedPatientConditionsPagination.perPage,
                  page: resolvedPatientConditionsPagination.currentPage,
                  totalCount: resolvedPatientConditionsPagination.totalCount,
                  onPageChange: handleChangePage,
                }}
                rows={
                  [...resolvedPatientConditions, ...updatedPatientConditions]
                    ?.map((data, index) => ({
                      index,
                      icd10Code: data.associatedCondition?.icd10Code || data.code,
                      name: data.condition,
                      firstSeenOn: getDateStringFromUTCDate(data.firstSeen),
                      lastSeenOn: getDateStringFromUTCDate(data.lastSeen),
                    }))
                    .sort((a, b) => b.index - a.index) || []
                }
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='100%'
          width='100%'
          flexDirection='column'>
          <Typography mb={3}>Wait a few seconds...</Typography>
          <LoaderBubble />
        </Box>
      )}
    </Box>
  );
};
