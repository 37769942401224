import { Box, Checkbox, Chip, Link, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BillingPagination, BillingPatient } from '../../../interfaces/billing.rpm';
import { StyledTheme } from '../../../styles/styleTheme';
import { getCapitalizedWord } from '../../../util/stringFormat';
import { CompactTable } from '../../common/CompactTable/CompactTable';
import { Column } from '../../common/CustomTable';
import { getStatusChipColor } from '../common/ChipColors';
import { CodeChipList } from '../common/CodeChipList';
import { PatientDetailsModal } from '../common/PatientDetailsModal';
import { RpmLoading } from '../common/RpmLoading';
import { RpmStatus } from '../types/rpms.types';

interface PatientSelectionTableProps {
  onRowSelectionChange: (selectedRows: number[]) => void;
  selectedRows: number[];
  onRowExclusionChange: (excludedRows: { uuid: string; index: number }[]) => void;
  excludedRows: { uuid: string; index: number }[];
  isLoading?: boolean;
  data: BillingPatient[];
  pagination: BillingPagination;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
  onAllRecordsSelected: (allRecordsSelected: boolean) => void;
  allRecordsSelected: boolean;
}

export const PatientSelectionTable: React.FC<PatientSelectionTableProps> = ({
  onRowSelectionChange,
  selectedRows,
  onRowExclusionChange,
  excludedRows,
  isLoading = false,
  data,
  pagination,
  onPageChange,
  onRowsPerPageChange,
  onAllRecordsSelected,
  allRecordsSelected,
}) => {
  // States
  const [selectedPatient, setSelectedPatient] = useState<BillingPatient | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Row Selection Handlers
  const handleRowSelection = useCallback(
    (id: number) => {
      const patient = data.find((p) => p.id === id);

      if (patient?.rpmExcluded || !patient?.uuid) {
        return;
      }

      const updatedSelectedRows = selectedRows.includes(id)
        ? selectedRows.filter((rowId) => rowId !== id)
        : [...selectedRows, id];

      onRowSelectionChange(updatedSelectedRows);

      if (allRecordsSelected) {
        const updatedExcludedRows = selectedRows.includes(id)
          ? [...excludedRows, { uuid: patient.uuid, index: id }]
          : excludedRows.filter(({ index }) => index !== id);

        onRowExclusionChange(updatedExcludedRows);
      }
    },
    [
      data,
      selectedRows,
      onRowSelectionChange,
      allRecordsSelected,
      excludedRows,
      onRowExclusionChange,
    ],
  );

  const handleSelectAll = useCallback(async () => {
    if (!!selectedRows.length) {
      onRowSelectionChange([]);
    } else {
      onAllRecordsSelected(true);
    }
  }, [onAllRecordsSelected, onRowSelectionChange, selectedRows]);

  const handleDeselectAll = useCallback(() => {
    onAllRecordsSelected(false);
    onRowExclusionChange([]);
  }, [onAllRecordsSelected, onRowExclusionChange]);

  // Pagination Handlers
  const handlePageChange = useCallback(
    (newPage: number) => {
      onPageChange(newPage);
    },
    [onPageChange],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      onRowsPerPageChange(newRowsPerPage);
    },
    [onRowsPerPageChange],
  );

  // Modal Handlers
  const handleOpenModal = useCallback((patient: BillingPatient) => {
    setSelectedPatient(patient);
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedPatient(null);
    setIsModalOpen(false);
  }, []);

  // Table Configuration
  const columns: Column[] = useMemo(() => {
    const validPatients = data.filter((row) => !row.rpmExcluded);
    const validPatientsCount = validPatients.length;
    const someValidPatientsSelected =
      selectedRows.length > 0 && selectedRows.length < validPatientsCount;

    return [
      {
        key: 'select',
        label: (
          <Tooltip title='Select/deselect all valid patients across pages' placement='right'>
            <span>
              <Checkbox
                size='small'
                style={{ color: StyledTheme.tealDark }}
                indeterminate={someValidPatientsSelected}
                checked={allRecordsSelected}
                onChange={(event) =>
                  event.target.checked ? handleSelectAll() : handleDeselectAll()
                }
                aria-label='Select Valid Rows'
              />
            </span>
          </Tooltip>
        ),
        align: 'center',
      },
      {
        key: 'id',
        label: 'ID',
      },
      {
        key: 'name',
        label: 'Name',
      },
      {
        key: 'cycleStartDate',
        label: 'Cycle Start Date',
      },
      {
        key: 'clinic',
        label: 'Clinic',
      },
      {
        key: 'insurance',
        label: 'Payer',
      },
      {
        key: 'cptCodes',
        label: 'CPT Codes',
      },
      {
        key: 'icd10Codes',
        label: 'ICD10 Codes',
      },
      {
        key: 'rpmStatus',
        label: 'Status',
      },
      {
        key: 'rpmStatusDetails',
        label: 'Details',
      },
    ];
  }, [data, selectedRows.length, handleSelectAll, handleDeselectAll, allRecordsSelected]);

  // Table Data Transformation
  const tableRows = useMemo(
    () =>
      data.map((patient) => ({
        select: (
          <Checkbox
            size='small'
            style={{
              color: !patient.rpmExcluded ? StyledTheme.tealDark : StyledTheme.grayLight,
            }}
            checked={selectedRows.includes(patient.id)}
            onChange={() => handleRowSelection(patient.id)}
            disabled={patient.rpmExcluded}
          />
        ),
        id: (
          <Link component='button' variant='body2' onClick={() => handleOpenModal(patient)}>
            {patient.memberId}
          </Link>
        ),
        name: patient.firstName + ' ' + patient.lastName,
        cycleStartDate: patient.lastExportDate || '-',
        clinic: patient.clinicName,
        insurance: patient.insuranceCompany,
        cptCodes: <CodeChipList codes={patient.cptCodes || []} />,
        icd10Codes: <CodeChipList codes={patient.icd10Codes} />,
        rpmStatus: (
          <Chip
            label={getCapitalizedWord(patient.rpmExcluded ? RpmStatus.INVALID : RpmStatus.VALID)}
            color={getStatusChipColor(patient.rpmExcluded ? RpmStatus.INVALID : RpmStatus.VALID)}
            size='small'
          />
        ),
        rpmStatusDetails: <CodeChipList codes={patient.rpmExclusionCriteria} allowSlice={false} />,
      })),
    [data, selectedRows, handleRowSelection, handleOpenModal],
  );

  useEffect(() => {
    if (allRecordsSelected) {
      const allValidIds = data.filter((member) => !member.rpmExcluded).map(({ id }) => id);
      onRowSelectionChange(allValidIds);
    } else {
      onRowSelectionChange([]);
    }
  }, [allRecordsSelected, data, onRowSelectionChange]);

  return (
    <>
      <Box position='relative'>
        {isLoading && <RpmLoading />}
        <CompactTable
          columns={columns}
          rows={tableRows}
          defaultText='No patients found'
          pagination={{
            rowsPerPageOptions: [10, 20, 30, 50],
            rowPerPage: pagination.limit,
            page: pagination.currentPage,
            totalCount: pagination.totalCount,
            onPageChange: handlePageChange,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
        />
      </Box>

      <PatientDetailsModal
        open={isModalOpen}
        onClose={handleCloseModal}
        patient={selectedPatient}
      />
    </>
  );
};
