import { Box } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { IClinic } from '../../../interfaces/clinic';
import { SelectOption } from '../../../interfaces/common';
import { PayerItem } from '../../../interfaces/eligibility';
import { CommonService } from '../../../services/common';
import coreService from '../../../services/core';
import eligibilityService from '../../../services/eligibility';
import { RPMFilters } from '../common/RPMFilters';
import { FilterConfiguration, Filters, LoadingStates, RPMCptCodes } from '../types/rpms.types';
import { RPMExportResultsTable } from './RPMExportResultsTable';

const filterConfigurations = [
  {
    key: 'clinic',
    label: 'Clinic',
    options: [
      { value: 'mankato', label: 'Mankato' },
      { value: 'ehg', label: 'EHG' },
    ],
    type: 'select',
  },
  {
    key: 'insuranceCompany',
    label: 'Insurance Company',
    options: [],
    section: 'Patient Selection',
    type: 'autocomplete',
    fetchOptions: async (searchTerm: string) => {
      try {
        const response = await eligibilityService.getPayers({
          filter: searchTerm || null,
        });
        const options =
          response?.data?.map((payer: PayerItem) => ({
            label: payer.name,
            value: payer.id,
          })) || [];
        return options;
      } catch (error) {
        console.error('Error fetching insurance companies:', error);
        return [];
      }
    },
  },
  {
    key: 'dateOfService',
    label: 'Date Of Service',
    type: 'date',
  },
  {
    key: 'pos',
    label: 'POS (Place of Service)',
    options: [
      { value: '11', label: 'Office' },
      { value: '12', label: 'Home' },
    ],
    type: 'select',
    includeSelectAll: true,
    multiple: true,
  },
  {
    key: 'cptCodes',
    label: 'CPT Codes',
    options: Object.values(RPMCptCodes).map((value) => ({ value, label: value })),
    section: 'Patient Selection',
    type: 'select',
  },
  {
    key: 'memberSearch',
    label: 'Member Search',
    options: [],
    section: 'Patient Selection',
    type: 'autocomplete',
    fetchOptions: async (searchTerm: string) => {
      try {
        if (!searchTerm) {
          return [];
        }

        const response = await coreService.fetchMembers({
          searchTerm,
        });
        const options =
          response?.data?.map((member) => ({
            label: member.fullName,
            value: member.uuid,
          })) || [];
        return options;
      } catch (error) {
        console.error('Error fetching members:', error);
        return [];
      }
    },
  },
] as FilterConfiguration<Filters>[];

const initialFilters: Filters = {
  clinic: [],
  insuranceCompany: [],
  ackDate: [],
  pos: [],
  cptCodes: [],
  memberSearch: [],
};

export const RPMExportResults: React.FC = () => {
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [clinicOptions, setClinicOptions] = useState<SelectOption[]>([]);
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({
    isApplying: false,
    isClearing: false,
  });

  const fetchClinics = useCallback(async () => {
    try {
      const clinics = await CommonService.getClinics();
      setClinicOptions(
        clinics.map((clinic: IClinic) => ({ label: clinic.name, value: `${clinic.id}` })),
      );
    } catch (error) {
      console.error('Error fetching clinics:', error);
    }
  }, []);

  useEffect(() => {
    fetchClinics();
  }, [fetchClinics]);

  const updatedFilterConfigurations = filterConfigurations.map((config) =>
    config.key === 'clinic' ? { ...config, options: clinicOptions } : config,
  );

  const handleApplyFilters = async (appliedFilters: Filters) => {
    setLoadingStates((prev) => ({ ...prev, isApplying: true }));
    try {
      setFilters(appliedFilters);
    } catch (error) {
      console.error('Error applying filters:', error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, isApplying: false }));
    }
  };

  const handleClearFilters = async () => {
    setLoadingStates((prev) => ({ ...prev, isClearing: true }));
    try {
      setFilters(initialFilters);
    } finally {
      setLoadingStates((prev) => ({ ...prev, isClearing: false }));
    }
  };

  return (
    <Box>
      <RPMFilters<Filters>
        configurations={updatedFilterConfigurations}
        onApplyFilters={handleApplyFilters}
        onClearFilters={handleClearFilters}
        initialFilters={initialFilters}
        loadingStates={loadingStates}
      />
      <RPMExportResultsTable filters={filters} />
    </Box>
  );
};
