import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { CreateProcedurePayload } from '../../../../interfaces/health-data';
import healthDataService from '../../../../services/health-data';
import { ProcedureForm } from './ProcedureForm/ProcedureForm';

export const CreateProcedure = () => {
  const [form, setForm] = useState<CreateProcedurePayload | null>(null);

  const breadcrumbLabels = ['procedures', 'create'];

  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = async () => {
    if (!form) {
      return;
    }

    const response = await healthDataService.createProcedure(form);

    if (!response.data) {
      notify(response.message || 'There was an error saving procedure', 'warning');
      return;
    }

    notify('Procedure created successfully', 'success');
    redirect(`/medical-setup/procedures/${response.data.id}/show`);
  };

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' className='tab-section-action-bar'>
        <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
          {breadcrumbLabels.map((text, index) => {
            return (
              <Typography key={`text-procedure-breadcrumb-${index}`} textTransform='capitalize'>
                {text}
              </Typography>
            );
          })}
        </Breadcrumbs>

        <Button
          onClick={handleSubmit}
          disabled={!form}
          variant='contained'
          sx={{ textTransform: 'capitalize' }}>
          Save
        </Button>
      </Box>

      <ProcedureForm onChangeForm={setForm} />
    </Box>
  );
};
