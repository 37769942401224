import { CircularProgress, FormControlLabel, FormGroup } from '@material-ui/core';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { ClaimStatus, ClaimType } from '../../interfaces/billing';
import { SelectOption } from '../../interfaces/common';
import { StyledTheme } from '../../styles/styleTheme';
import { toSelectOptions } from '../../util/eligibility';

interface FiltersProps {
  claimType: string[];
  clinic: string[];
  clinics: SelectOption[];
  forceEhrFetch: boolean;
  fromSyncDate: string;
  patient: string[];
  patients: SelectOption[];
  provider: string[];
  providers: SelectOption[];
  status: string[];
  syncLoading: boolean;
  untilSyncDate: string;
  onClaimStatusChange: (event: SelectChangeEvent<string[]>) => void;
  onClaimTypeChange: (event: SelectChangeEvent<string[]>) => void;
  onClinicChange: (event: SelectChangeEvent<string[]>) => void;
  onFilter: () => void;
  onForceEhrFetch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFromSyncDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPatientChange: (event: any, value: SelectOption[]) => void;
  onProviderChange: (event: SelectChangeEvent<string[]>) => void;
  onSyncRange: () => void;
  onSyncRequests: () => void;
  onUntilSyncDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Filters: React.FC<FiltersProps> = ({
  claimType,
  clinic,
  clinics,
  forceEhrFetch,
  fromSyncDate,
  patient,
  patients,
  provider,
  providers,
  status,
  syncLoading,
  untilSyncDate,
  onClaimStatusChange,
  onClaimTypeChange,
  onClinicChange,
  onFilter,
  onForceEhrFetch,
  onFromSyncDateChange,
  onPatientChange,
  onProviderChange,
  onSyncRange,
  onSyncRequests,
  onUntilSyncDateChange,
}) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleSyncRangeClick = () => {
    if (fromSyncDate && untilSyncDate) {
      const from = moment.tz(fromSyncDate, 'YYYY-MM-DD', moment.tz.guess());
      const until = moment.tz(untilSyncDate, 'YYYY-MM-DD', moment.tz.guess());
      const diff = until.diff(from, 'days');
      if (diff > 7) {
        alert('The maximum range between From and Until dates is 7 days.');
        return;
      }
    }
    onSyncRange();
  };

  return (
    <div>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label='Sync' />
        <Tab label='Filter' />
      </Tabs>
      {activeTab === 0 && (
        <Box sx={{ p: 3 }}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth variant='outlined'>
                <InputLabel>Clinics</InputLabel>
                <Select
                  value={clinic}
                  onChange={onClinicChange}
                  label='Clinics'
                  multiple
                  renderValue={(selected) => selected.join(', ')}>
                  {clinics.map((clinicOption) => (
                    <MenuItem key={clinicOption.value} value={clinicOption.value}>
                      <Checkbox
                        checked={clinic.includes(clinicOption.value)}
                        style={{ padding: '0 8px' }}
                        size='small'
                      />
                      <ListItemText
                        primary={clinicOption.label}
                        primaryTypographyProps={{ variant: 'body2' }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label='From'
                type='date'
                value={fromSyncDate}
                onChange={onFromSyncDateChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label='Until'
                type='date'
                value={untilSyncDate}
                onChange={onUntilSyncDateChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size='small'
                        style={{ color: StyledTheme.tealDark }}
                        value={forceEhrFetch}
                        onChange={onForceEhrFetch}
                        checked={forceEhrFetch}
                      />
                    }
                    label='Force EHR Sync?'
                  />
                </FormGroup>

                <Button
                  size='large'
                  color='primary'
                  variant='contained'
                  disabled={syncLoading}
                  startIcon={
                    syncLoading ? <CircularProgress style={{ color: '#ffffff' }} size={20} /> : null
                  }
                  onClick={handleSyncRangeClick}>
                  Sync Range
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              sx={{
                alignItems: 'center',
                justifyContent: 'right',
                display: 'flex',
                textDecoration: 'underline',
              }}>
              <Button
                size='large'
                color='secondary'
                variant='text'
                disabled={false}
                onClick={onSyncRequests}>
                See sync requests
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      {activeTab === 1 && (
        <Box sx={{ p: 3 }}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth variant='outlined'>
                <InputLabel>Clinics</InputLabel>
                <Select
                  value={clinic}
                  renderValue={(selected) => selected.join(', ')}
                  onChange={onClinicChange}
                  label='Clinics'
                  multiple>
                  {clinics.map((clinicOption) => (
                    <MenuItem key={clinicOption.value} value={clinicOption.value}>
                      <Checkbox
                        checked={clinic.includes(clinicOption.value)}
                        style={{ padding: '0 8px' }}
                        size='small'
                      />
                      <ListItemText
                        primary={clinicOption.label}
                        primaryTypographyProps={{ variant: 'body2' }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={2}>
              <FormControl fullWidth variant='outlined'>
                <InputLabel>Providers</InputLabel>
                <Select
                  value={provider}
                  onChange={onProviderChange}
                  label='Providers'
                  multiple
                  renderValue={(selected) => selected.join(', ')}>
                  {providers.map((providerOption) => (
                    <MenuItem key={providerOption.value} value={providerOption.value}>
                      <Checkbox
                        checked={provider.includes(providerOption.value)}
                        style={{ padding: '0 8px' }}
                        size='small'
                      />
                      <ListItemText
                        primary={providerOption.label}
                        primaryTypographyProps={{ variant: 'body2' }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            {/* <Grid item xs={12} sm={2}>
              <Autocomplete
                multiple
                options={patients}
                getOptionLabel={(option) => option.label}
                value={patients.filter((option) => patient.includes(option.value))}
                onChange={onPatientChange}
                renderTags={(value) => value.map((option) => option.value).join(',')}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox style={{ padding: '0 8px' }} checked={selected} size='small' />
                    <ListItemText primary={option.label} />
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Patients'
                    placeholder='Select Patients'
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth variant='outlined'>
                <InputLabel>Status</InputLabel>
                <Select
                  value={status}
                  onChange={onClaimStatusChange}
                  label='Status'
                  multiple
                  renderValue={(selected) => selected.join(', ')}>
                  {toSelectOptions(Object.values(ClaimStatus)).map((claimStatusOption) => (
                    <MenuItem key={claimStatusOption.value} value={claimStatusOption.value}>
                      <Checkbox
                        checked={status.includes(claimStatusOption.value)}
                        style={{ padding: '0 8px' }}
                        size='small'
                      />
                      <ListItemText
                        primary={claimStatusOption.label}
                        primaryTypographyProps={{ variant: 'body2' }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth variant='outlined'>
                <InputLabel>Types</InputLabel>
                <Select
                  value={claimType}
                  onChange={onClaimTypeChange}
                  label='Types'
                  multiple
                  renderValue={(selected) => selected.join(', ')}>
                  {toSelectOptions(Object.values(ClaimType)).map((claimTypeOption) => (
                    <MenuItem key={claimTypeOption.value} value={claimTypeOption.value}>
                      <Checkbox
                        checked={claimType.includes(claimTypeOption.value)}
                        style={{ padding: '0 8px' }}
                        size='small'
                      />
                      <ListItemText
                        primary={claimTypeOption.label}
                        primaryTypographyProps={{ variant: 'body2' }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                size='large'
                fullWidth
                color='primary'
                variant='contained'
                disabled={syncLoading}
                startIcon={
                  syncLoading ? <CircularProgress style={{ color: '#ffffff' }} size={20} /> : null
                }
                onClick={onFilter}>
                Filter
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};
