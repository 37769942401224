import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { useEffect } from 'react';
import { SelectOption } from '../../../interfaces/common';
import { TrackableElement } from '../../../services/mixpanel';

interface ReusableSelectFilterProps {
  label: string;
  multiple?: boolean;
  name: string;
  onChange: (values: string[]) => void;
  options: SelectOption[];
  reset?: boolean;
  section: string;
  value: string[];
}

export const ReusableSelectFilter: React.FC<ReusableSelectFilterProps> = ({
  label,
  section,
  name,
  value,
  onChange,
  options,
  multiple = true,
  reset = false,
}) => {
  const allOption = { value: 'all', label: 'All' };
  const allValues = options.map((opt) => opt.value);

  const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    const { value: selected } = event.target;
    const selectedValues = typeof selected === 'string' ? selected.split(',') : selected;

    if (selectedValues.includes(allOption.value)) {
      if (value.length === allValues.length) {
        onChange([]);
      } else {
        onChange([...allValues]);
      }
    } else {
      const updatedValues = selectedValues.filter((val) => val !== allOption.value);

      const toggledValues = value.includes(updatedValues[0])
        ? value.filter((val) => val !== updatedValues[0])
        : [...value, ...updatedValues];

      onChange(toggledValues);

      if (toggledValues.length === allValues.length) {
        onChange([...allValues]);
      }
    }
  };

  useEffect(() => {
    if (reset && value.length > 0) {
      onChange([]);
    }
  }, [reset, value, onChange]);

  return (
    <FormControl fullWidth variant='filled' size='small'>
      <InputLabel>{label}</InputLabel>
      <TrackableElement
        properties={{ Section: section, Name: name }}
        key={`${name}-${section}-${value.length}-select`}>
        <Select
          name={name}
          size='small'
          multiple={multiple}
          value={value}
          key={`${name}-${value.length}`}
          onChange={handleSelectChange}
          renderValue={(selected) => (selected as string[]).join(', ')}
          MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}>
          <MenuItem
            key={`${allOption.value}-${name}`}
            value={allOption.value}
            sx={{ fontSize: '0.875rem', padding: '4px 8px' }}>
            <Checkbox
              checked={value.length === allValues.length}
              indeterminate={value.length > 0 && value.length < allValues.length}
            />
            <ListItemText primary={allOption.label} />
          </MenuItem>
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{ fontSize: '0.875rem', padding: '4px 8px' }}>
              <Checkbox checked={value.includes(option.value)} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </TrackableElement>
    </FormControl>
  );
};
