import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { FC, ReactElement, useCallback, useRef, useState } from 'react';
import { SelectOption } from '../../../../interfaces/common';
import {
  IncomingPatientLaboratoryResult,
  LaboratoryResultGroup,
  LaboratoryResultPatient,
} from '../../../../interfaces/health-data';
import coreService from '../../../../services/core';
import { formatDateStringToYYYYMMDD } from '../../../../util/dateFormat';
import { CustomTable } from '../../../common/CustomTable';
import { ReusableAutocomplete } from '../../../rpms/common/ReusableAutocomplete';
import LinkableBreadcrumb, { LinkableBreadcrumbProps } from '../LinkableBreadcrumb';
import LaboratoryTestSelector, { DEFAULT_CODE_TYPE } from './LaboratoryTestSelector';

const NO_UNITS_REQUIRED_PLACEHOLDER = 'n/a';

type Props = {
  isEditing?: boolean;
  patient?: LaboratoryResultGroup['patient'];
  resultsOn?: string;
  labResults?: IncomingPatientLaboratoryResult[];
  onSaveChanges: (
    patient: LaboratoryResultPatient,
    resultsOn: string,
    labResults: IncomingPatientLaboratoryResult[],
  ) => Promise<void>;
} & LinkableBreadcrumbProps;

const LaboratoryResultsForm: FC<Props> = ({
  items,
  isEditing = false,
  patient,
  resultsOn,
  labResults,
  onSaveChanges,
}) => {
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false);
  const [unitsPerLoincCode, setUnitsPerLoincCode] = useState<Record<string, string[]>>({});
  const [formPatient, setFormPatient] = useState<LaboratoryResultPatient | null>(
    patient
      ? {
          internalUuid: patient.internalUuid,
          clinicName: patient.clinic.name || 'Unknown',
          fullName: patient.fullName,
        }
      : null,
  );
  const [formResultsOn, setFormResultsOn] = useState<string | null>(
    resultsOn ? formatDateStringToYYYYMMDD(resultsOn) : null,
  );
  const [formLabResults, setFormLabResults] = useState<IncomingPatientLaboratoryResult[]>(
    labResults || [],
  );
  const [newLab, setNewLab] = useState<IncomingPatientLaboratoryResult>(
    {} as IncomingPatientLaboratoryResult,
  );

  const allPatientsRef = useRef<Record<string, LaboratoryResultPatient>>({});

  const fetchPatients = useCallback(async (searchTerm: string): Promise<SelectOption[]> => {
    try {
      const matchingPatients = (await coreService.fetchMembers({ searchTerm, target: 'members' }))
        .data;

      const options: SelectOption[] = [];

      for (const { clinic, fullName, id, uuid } of matchingPatients) {
        options.push({
          label: `(${id}) ${fullName}, ${clinic.name || 'No Clinic'}`,
          value: uuid,
        });

        allPatientsRef.current[uuid] = {
          internalUuid: uuid,
          clinicName: clinic.name,
          fullName,
        };
      }

      return options;
    } catch (error) {
      return [];
    }
  }, []);

  const createLabResultRow = (lab: IncomingPatientLaboratoryResult, index: number) => ({
    testName: (
      <LaboratoryTestSelector
        value={lab.code}
        onChange={(labTest) => {
          if (labTest) {
            setUnitsPerLoincCode((prev) => ({ ...prev, [labTest.loincCode]: labTest.units }));

            setFormLabResults(
              formLabResults.map((l, i) =>
                i !== index
                  ? l
                  : {
                      ...l,
                      codeType: DEFAULT_CODE_TYPE,
                      code: labTest.loincCode,
                      testName: labTest.name,
                    },
              ),
            );
          }
        }}
      />
    ),
    value: (
      <TextField
        size='small'
        fullWidth
        value={lab.value}
        onChange={(e) =>
          setFormLabResults(
            formLabResults.map((l, i) => (i !== index ? l : { ...l, value: e.target.value })),
          )
        }
      />
    ),
    unit: renderUnitsSelector(lab.code, lab.unit, (e) =>
      setFormLabResults(
        formLabResults.map((l, i) => (i !== index ? l : { ...l, unit: e.target.value })),
      ),
    ),
    comments: (
      <TextField
        size='small'
        type='text'
        fullWidth
        value={lab.comments || ''}
        onChange={(e) =>
          setFormLabResults(
            formLabResults.map((l, i) => (i !== index ? l : { ...l, comments: e.target.value })),
          )
        }
      />
    ),
  });

  const requiresUnitsSelection = (selectedUnit: string | undefined): boolean =>
    selectedUnit?.toLowerCase().trim() !== NO_UNITS_REQUIRED_PLACEHOLDER;

  const canSaveChanges = (): boolean =>
    !!formPatient &&
    !!formResultsOn &&
    formLabResults.length > 0 &&
    formLabResults.every((l) => !!l.code && !!l.testName && !!l.value && !!l.unit);

  const handleAddNewLab = () => {
    setFormLabResults([...formLabResults, newLab]);

    setNewLab({} as IncomingPatientLaboratoryResult);
  };

  const renderUnitsSelector = (
    loincCode: string,
    selectedUnit: string | undefined,
    onChange: (e: SelectChangeEvent<string>) => void,
  ): ReactElement =>
    requiresUnitsSelection(selectedUnit) ? (
      <FormControl sx={{ minWidth: 100 }} size='small'>
        <Select value={selectedUnit || ''} onChange={onChange}>
          {unitsPerLoincCode[loincCode]?.map((unit) => (
            <MenuItem value={unit} key={`laboratory-result-unit-${unit}-${Date.now()}`}>
              {unit}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ) : (
      <></>
    );

  return (
    <>
      <Box mt={2} mb={2} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <LinkableBreadcrumb
          items={[{ text: 'laboratory results', location: '/laboratory-results' }, ...items]}
        />

        <Button
          variant='contained'
          disabled={!canSaveChanges() || isSavingChanges}
          sx={{ textTransform: 'capitalize' }}
          onClick={() => {
            setIsSavingChanges(true);
            onSaveChanges(formPatient!, formResultsOn!, formLabResults);
          }}>
          {isSavingChanges ? (
            <>
              Saving <CircularProgress style={{ marginLeft: 10 }} size={15} />
            </>
          ) : (
            'Save'
          )}
        </Button>
      </Box>

      <Stack className='tab-section laboratory-tests-section' mt={0} gap={10}>
        <CustomTable
          columns={[
            { key: 'patientName', label: 'Patient Name', width: 600 },
            { key: 'dateCollected', label: 'Date Collected' },
          ]}
          rows={[
            {
              patientName: (
                <ReusableAutocomplete
                  label={'Patient Name'}
                  multiple={false}
                  disabled={isEditing}
                  options={[]}
                  defaultSearchTerm={patient?.fullName}
                  value={[formPatient?.internalUuid || '']}
                  onChange={(value) =>
                    setFormPatient(
                      value && allPatientsRef.current[value[0]]
                        ? allPatientsRef.current[value[0]]
                        : null,
                    )
                  }
                  fetchOptions={fetchPatients}
                />
              ),
              dateCollected: (
                <TextField
                  size='small'
                  type='date'
                  value={formResultsOn}
                  onChange={(e) => setFormResultsOn(e.target.value)}
                />
              ),
            },
          ]}
        />

        <CustomTable
          useStickyBottomRow
          columns={[
            { key: 'testName', label: 'Test Name', width: 500 },
            { key: 'value', label: 'Value', width: 300 },
            { key: 'unit', label: 'Unit' },
            { key: 'comments', label: 'Comments' },
            { key: 'actions', label: 'Actions' },
          ]}
          rows={formLabResults
            .map((lab, i) => ({
              ...createLabResultRow(lab, i),
              actions: [
                <Button
                  size='small'
                  variant='text'
                  sx={{ textTransform: 'capitalize' }}
                  onClick={() =>
                    setFormLabResults((prev) => prev.filter((_, index) => index !== i))
                  }>
                  Delete
                </Button>,
              ],
            }))
            .concat([
              {
                testName: (
                  <LaboratoryTestSelector
                    value={newLab.code}
                    onChange={(labTest) => {
                      if (labTest) {
                        setUnitsPerLoincCode((prev) => ({
                          ...prev,
                          [labTest.loincCode]: labTest.units,
                        }));

                        setNewLab((prev) => ({
                          ...prev,
                          codeType: DEFAULT_CODE_TYPE,
                          code: labTest.loincCode,
                          testName: labTest.name,
                          ...(labTest.units.length === 1 && { unit: labTest.units[0] }),
                        }));
                      }
                    }}
                  />
                ),
                value: (
                  <TextField
                    size='small'
                    fullWidth
                    value={newLab.value || ''}
                    onChange={(e) => setNewLab({ ...newLab, value: e.target.value })}
                  />
                ),
                unit: renderUnitsSelector(newLab.code, newLab.unit, (e) =>
                  setNewLab({ ...newLab, unit: e.target.value }),
                ),
                comments: (
                  <TextField
                    size='small'
                    type='text'
                    fullWidth
                    value={newLab.comments || ''}
                    onChange={(e) => setNewLab({ ...newLab, comments: e.target.value })}
                  />
                ),
                actions: [
                  <Button
                    size='small'
                    variant='contained'
                    disabled={
                      !newLab.testName?.length || !newLab.value?.length || !newLab.unit?.length
                    }
                    sx={{ textTransform: 'capitalize' }}
                    onClick={handleAddNewLab}>
                    Add Lab
                  </Button>,
                ],
              },
            ])}
        />
      </Stack>
    </>
  );
};

export default LaboratoryResultsForm;
