// SectionContent.tsx
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { RPMNoteSection, RPMNoteSubSection } from '../../../interfaces/billing.rpm';
import { TableContent } from './TableContent';

interface SectionContentProps {
  section: RPMNoteSection;
}

const formatSectionTitle = (key: string): string => {
  switch (key) {
    case 'fdaDevices':
      return 'FDA Devices';
    case 'cptCodes':
      return 'CPT Codes';
    case 'currentMedications':
      return 'Current Medications';
    default:
      return key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1');
  }
};

const hasTableContent = (content: Array<RPMNoteSubSection | any>): boolean => {
  return content.some((item) => item.isTable || ('content' in item && Array.isArray(item.content)));
};

export const SectionContent: FC<SectionContentProps> = ({ section }) => {
  if (!section.content) return null;

  if (Array.isArray(section.content) && 'code' in (section.content[0] || {})) {
    return <TableContent section={section} />;
  }

  if (section.isTable || (Array.isArray(section.content) && hasTableContent(section.content))) {
    return <TableContent section={section} />;
  }

  if (Array.isArray(section.content) && 'key' in (section.content[0] || {})) {
    return (
      <>
        {(section.content as RPMNoteSubSection[]).map((subsection) => (
          <Box key={subsection.key}>
            <Typography variant='subtitle2' sx={{ fontWeight: 'bold', mb: 1 }}>
              {formatSectionTitle(subsection.key)}:
            </Typography>
            {typeof subsection.content === 'string' ? (
              <Typography sx={{ pl: 2, mb: 1 }}>{subsection.content}</Typography>
            ) : (
              <TableContent section={{ ...section, content: subsection.content }} />
            )}
          </Box>
        ))}
      </>
    );
  }

  return <Typography sx={{ pl: 2 }}>{section.content as string}</Typography>;
};
