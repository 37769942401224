import { Box, Breadcrumbs, Button, Typography } from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useEffect, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Procedure, UpdateProcedurePayload } from '../../../../interfaces/health-data';
import healthDataService from '../../../../services/health-data';
import { LoaderBubble } from '../../../common/LoaderBubble/LoaderBubble';
import { ProcedureForm } from './ProcedureForm/ProcedureForm';

export const EditProcedure = () => {
  const [procedure, setProcedure] = useState<Procedure | null>(null);
  const [breadcrumbLabels, setBreadcrumbLabels] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [form, setForm] = useState<UpdateProcedurePayload | null>(null);

  const notify = useNotify();
  const { procedureId } = useParams<{ procedureId: string }>();
  const redirect = useRedirect();

  const handleUpdateForm = ({ cptCode, status, name }: UpdateProcedurePayload) =>
    setForm({ cptCode, status, name });

  const handleSubmit = async () => {
    if (!form || !procedure?.id) {
      return;
    }

    const response = await healthDataService.updateProcedure(procedure.id, form);

    if (!response.data) {
      notify(response.message || 'There was an error updating the procedure', 'warning');
      return;
    }

    notify('Procedure updated successfully');
    redirect(`/medical-setup/procedures/${procedureId}/show`);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await healthDataService.getProcedure(Number(procedureId));

      if (!response.data) {
        notify(response.message || 'There was an error obtaining the procedure', 'warning');
        setIsLoading(false);
        return;
      }

      setBreadcrumbLabels(['procedures', response.data?.name || 'Unknown']);
      setProcedure(response.data);
      setIsLoading(false);
    })();
  }, [procedureId]);

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' className='tab-section-action-bar'>
        <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
          {breadcrumbLabels.map((text, index) => {
            return (
              <Typography key={`text-procedure-breadcrumb-${index}`} textTransform='capitalize'>
                {text}
              </Typography>
            );
          })}
        </Breadcrumbs>

        <Button
          onClick={handleSubmit}
          disabled={!form}
          variant='contained'
          sx={{ textTransform: 'capitalize' }}>
          Save
        </Button>
      </Box>

      {procedure && !isLoading ? (
        <ProcedureForm onChangeForm={handleUpdateForm} procedure={procedure} />
      ) : (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='100%'
          width='100%'
          flexDirection='column'>
          <Typography mb={3}>Wait a few seconds...</Typography>
          <LoaderBubble />
        </Box>
      )}
    </Box>
  );
};
