import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Procedure } from '../../../../interfaces/health-data';
import healthDataService from '../../../../services/health-data';
import { getCapitalizedWord } from '../../../../util/stringFormat';
import { LoaderBubble } from '../../../common/LoaderBubble/LoaderBubble';

export const ViewProcedure = () => {
  const [procedure, setProcedure] = useState<Procedure | null>(null);
  const [breadcrumbLabels, setBreadcrumbLabels] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { procedureId } = useParams<{ procedureId: string }>();
  const redirect = useRedirect();
  const notify = useNotify();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await healthDataService.getProcedure(Number(procedureId));

      if (!response.data) {
        notify(response.message || 'An unknown error occurred');
        setIsLoading(false);
        return;
      }
      setBreadcrumbLabels(['procedures', response.data?.name || 'Unknown']);
      setProcedure(response.data);
      setIsLoading(false);
    })();
  }, [procedureId]);

  return (
    <>
      {procedure && !isLoading ? (
        <Box>
          <Box display='flex' justifyContent='space-between' className='tab-section-action-bar'>
            <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
              {breadcrumbLabels.map((text, index) => {
                return (
                  <Typography key={`text-procedure-breadcrumb-${index}`} textTransform='capitalize'>
                    {text}
                  </Typography>
                );
              })}
            </Breadcrumbs>

            <Button
              onClick={() => redirect(`/medical-setup/procedures/${procedureId}/edit`)}
              variant='contained'
              sx={{ textTransform: 'capitalize' }}>
              Edit
            </Button>
          </Box>

          <Box className='tab-section procedures-section'>
            <Box display='flex' flexDirection='row' mb={1}>
              <Typography mr={1}>Status:</Typography>
              <Typography textTransform='capitalize' fontWeight={300}>
                {getCapitalizedWord(procedure.status)}
              </Typography>
            </Box>

            <Box display='flex' flexDirection='row' mb={1}>
              <Typography mr={1}>Name: </Typography>
              <Typography fontWeight={300}>{procedure.name}</Typography>
            </Box>

            <Box display='flex' flexDirection='row' mb={1}>
              <Typography mr={1}>CPT Code: </Typography>
              <Typography fontWeight={300}>{procedure.cptCode}</Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='100%'
          width='100%'
          flexDirection='column'>
          <Typography mb={3}>Wait a few seconds...</Typography>
          <LoaderBubble />
        </Box>
      )}
    </>
  );
};
