import { Box } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useRedirect } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { CustomTabs } from '../common/CustomTabs/CustomTabs';
import { TabPanel } from '../common/TabPanel/TabPanel';
import '../common/tab-section.css';
import { PatientConditions } from './conditions/PatientConditions';

type Props = {
  children: ReactNode;
};

const TABS_BY_INDEX: { [index: number]: string } = {
  0: 'conditions',
};

export const Patient = ({ children }: Props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const location = useLocation();

  const redirect = useRedirect();

  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
    redirect(`/conditions/${TABS_BY_INDEX[tabIndex]}`);
  };

  useEffect(() => {
    const path = location.pathname.split('/')?.[2];

    if (!path) {
      redirect('/conditions/');
      return;
    }

    const [index] = Object.entries(TABS_BY_INDEX).find(([_, tab]) => path === tab) || [null];

    if (!index) {
      return;
    }

    setCurrentTabIndex(Number(index));
  }, [location.pathname]);

  return (
    <Box mt={4} className='tab-section-table'>
      <CustomTabs
        tabLabels={[
          {
            label: 'Active Conditions',
            onClick: () => {
              redirect('/patients/conditions');
            },
          },
        ]}
        value={currentTabIndex}
        onChange={handleChange}
      />
      <TabPanel value={currentTabIndex} index={0} key={'conditions'}>
        {children || <PatientConditions />}
      </TabPanel>
    </Box>
  );
};
