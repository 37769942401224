import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Autocomplete,
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import useDebounce from '../../../../../hooks/useDebounce';
import { Option } from '../../../../../interfaces/common';
import {
  ConditionLaboratoryTest,
  ConditionPriority,
  ConditionProcedure,
  ConditionStatus,
  ConditionWithRelationsForm,
  EntityMapping,
  LaboratoryTest,
  LaboratoryTestStatus,
  MedicalSetupEntitiesResponse,
  MedicalSetupEntityType,
  Medication,
  MedicationStatus,
  PartialSome,
  Procedure,
  ProcedureStatus,
  RpmBillingStatus,
  ValidityTimeInterval,
} from '../../../../../interfaces/health-data';
import healthDataService from '../../../../../services/health-data';
import { arrayWithoutEntry } from '../../../../../util/array';
import { getCapitalizedWord } from '../../../../../util/stringFormat';
import { CustomTable } from '../../../../common/CustomTable';
import './condition.css';

type Props = {
  conditionWithRelations?: ConditionWithRelationsForm;
  onChangeForm: (v: ConditionWithRelationsForm) => void;
};

export const ConditionForm = ({ conditionWithRelations, onChangeForm }: Props) => {
  const notify = useNotify();

  const [conditionForm, setConditionForm] = useState<ConditionWithRelationsForm>({
    condition: {
      name: '',
      icd10Code: '',
      status: ConditionStatus.ACTIVE,
      description: '',
      includedInPvq: false,
      billingCriteria: {
        status: RpmBillingStatus.NotBillable,
        priority: ConditionPriority.BEHAVIORAL,
        excludable: false,
      },
    },
    laboratoryTests: [],
    procedures: [],
    medications: [],
  });

  const [laboratoryTests, setLaboratoryTests] = useState<LaboratoryTest[]>([]);
  const [medications, setMedications] = useState<Medication[]>([]);
  const [procedures, setProcedures] = useState<Procedure[]>([]);

  const [laboratoryTestOptionsSelected, setLaboratoryTestOptionsSelected] = useState<
    Option<number>[]
  >([]);
  const [procedureOptionsSelected, setProceduresOptionsSelected] = useState<Option<number>[]>([]);
  const [medicationOptionsSelected, setMedicationOptionsSelected] = useState<Option<number>[]>([]);

  const laboratoryTestOptions = laboratoryTests.map((lb) => ({
    label: lb.name,
    value: lb.id,
  }));

  const procedureOptions = procedures.map((p) => ({
    label: p.name,
    value: p.id,
  }));

  const medicationOptions = medications.map((med) => ({
    label: med.conceptName,
    value: med.id,
  }));

  const getLaboratoryTestsById = useCallback(
    () =>
      laboratoryTests.reduce(
        (pv, cv) => ({
          ...pv,
          [cv.id]: cv,
        }),
        {} as { [id: number]: LaboratoryTest },
      ),
    [laboratoryTests],
  );

  const getMedicationsById = useCallback(
    () =>
      medications.reduce(
        (pv, cv) => ({
          ...pv,
          [cv.id]: cv,
        }),
        {} as { [id: number]: Medication },
      ),
    [medications],
  );

  const getProceduresById = useCallback(
    () =>
      procedures.reduce(
        (pv, cv) => ({
          ...pv,
          [cv.id]: cv,
        }),
        {} as { [id: number]: Procedure },
      ),
    [procedures],
  );

  const previousLaboratoryTestsOptionsById = conditionForm.laboratoryTests.reduce(
    (pv, cv) => ({
      ...pv,
      [cv.laboratoryTest.id]: cv,
    }),
    {} as {
      [id: number]: PartialSome<
        ConditionLaboratoryTest,
        'conditionId' | 'laboratoryTestId' | 'createdAt' | 'updatedAt' | 'id'
      > & { laboratoryTest: LaboratoryTest };
    },
  );

  const previousProceduresOptionsById = conditionForm.procedures.reduce(
    (pv, cv) => ({
      ...pv,
      [cv.procedure.id]: cv,
    }),
    {} as {
      [id: number]: PartialSome<
        ConditionProcedure,
        'conditionId' | 'procedureId' | 'createdAt' | 'updatedAt' | 'id'
      > & { procedure: Procedure };
    },
  );

  const handleAddNewRecord = (options: Option<number>[], entity: MedicalSetupEntityType) => {
    const baseValidityPeriodFrom = `0 ${ValidityTimeInterval.Months}`;
    const baseValidityPeriodTo = `6 ${ValidityTimeInterval.Months}`;

    const updatedForm = { ...conditionForm };

    switch (entity) {
      case MedicalSetupEntityType.LaboratoryTests:
        const laboratoryTestsById = getLaboratoryTestsById();

        updatedForm.laboratoryTests = options.map((laboratoryTest) => ({
          validityPeriodFrom:
            previousLaboratoryTestsOptionsById[laboratoryTest.value]?.validityPeriodFrom ||
            baseValidityPeriodFrom,
          validityPeriodUntil:
            previousLaboratoryTestsOptionsById[laboratoryTest.value]?.validityPeriodUntil ||
            baseValidityPeriodTo,
          laboratoryTest: laboratoryTestsById[laboratoryTest.value],
        }));

        break;

      case MedicalSetupEntityType.Medications:
        const medicationsById = getMedicationsById();

        updatedForm.medications = options.map((med) => ({
          medication: medicationsById[med.value],
        }));
        break;

      case MedicalSetupEntityType.Procedures:
        const proceduresById = getProceduresById();

        updatedForm.procedures = options.map((procedure) => ({
          validityPeriodFrom:
            previousProceduresOptionsById[procedure.value]?.validityPeriodFrom ||
            baseValidityPeriodFrom,
          validityPeriodUntil:
            previousProceduresOptionsById[procedure.value]?.validityPeriodUntil ||
            baseValidityPeriodTo,
          procedure: proceduresById[procedure.value],
        }));
        break;

      default:
        notify(`There was an error updating ${entity} associated`, 'warning');
        break;
    }

    setConditionForm(updatedForm);
  };

  const handleSearch = async <K extends keyof EntityMapping>(
    value: string,
    entity: K,
    callback: Dispatch<SetStateAction<(LaboratoryTest | Medication | Procedure)[]>>,
  ) => {
    let response: MedicalSetupEntitiesResponse<K> | null = null;

    switch (entity) {
      case MedicalSetupEntityType.LaboratoryTests:
        const labTestsResponse = await healthDataService.findAllLaboratoryTestsByParams({
          status: LaboratoryTestStatus.ACTIVE,
          name: value,
        });

        if (!labTestsResponse?.data) {
          notify(labTestsResponse.message || 'There is a problem fetching laboratory tests');
          return;
        }

        response = labTestsResponse.data as MedicalSetupEntitiesResponse<K>;

        break;

      case MedicalSetupEntityType.Medications:
        const medicationsResponse = await healthDataService.findAllMedicationsByParams({
          status: MedicationStatus.ACTIVE,
          conceptName: value,
        });

        if (!medicationsResponse?.data) {
          notify(medicationsResponse.message || 'There is a problem fetching medications');
          return;
        }

        response = medicationsResponse.data as MedicalSetupEntitiesResponse<K>;
        break;

      case MedicalSetupEntityType.Procedures:
        const proceduresResponse = await healthDataService.findAllProceduresByParams({
          status: ProcedureStatus.ACTIVE,
          name: value,
        });

        if (!proceduresResponse?.data) {
          notify(proceduresResponse.message || 'There is a problem fetching procedures');
          return;
        }

        response = proceduresResponse.data as MedicalSetupEntitiesResponse<K>;
        break;

      default:
        notify('There was an error pulling information', 'warning');
        return;
    }

    if (!response) {
      callback([]);
      return;
    }

    const { pagination, ...rest } = response;

    callback(Object.values(rest).flat() as unknown as (LaboratoryTest | Medication | Procedure)[]);
  };

  const debouncedHandleSearch = useDebounce<{
    value: string;
    entity: keyof EntityMapping;
    callback: Dispatch<SetStateAction<(LaboratoryTest | Medication | Procedure)[]>>;
  }>((params) => handleSearch(params.value, params.entity, params.callback));

  const handleChangeValidityPeriod = (
    e: SelectChangeEvent<string> | ChangeEvent<Element>,
    previousValue: string,
    type: string,
  ) => {
    const { value: newValue } = e.target as { value: string };

    const [previousAmount, previousPeriod] = previousValue.split(' ');

    const isNumber = type === 'number';
    const isString = type === 'string';

    (e.target as SelectChangeEvent<string>['target']).value = `${
      isNumber ? newValue : previousAmount
    } ${isString ? newValue : previousPeriod}`;

    handleChangeForm(e);
  };

  const handleChangeForm = (e: SelectChangeEvent<string> | ChangeEvent<Element>) => {
    const { value, name } = e.target as {
      value: string;
      name: string;
    };

    const path = name.split('.');

    const updatedForm = { ...conditionForm } as ConditionWithRelationsForm;

    let form = updatedForm;

    for (let i = 0; i < path.length - 1; i++) {
      const key = path[i] as keyof typeof updatedForm;
      form = form[key] as unknown as ConditionWithRelationsForm;
    }

    const nestedPath = path[path.length - 1] as keyof typeof form;

    (form[nestedPath] as unknown as string) = value;

    setConditionForm({ ...updatedForm });
  };

  useEffect(() => {
    if (conditionWithRelations) {
      const { laboratoryTests, procedures, medications } = conditionWithRelations;
      const laboratoryOptions = laboratoryTests.map((lb) => ({
        label: lb.laboratoryTest.name,
        value: lb.laboratoryTest.id,
      }));
      const procedureOptions = procedures.map((p) => ({
        label: p.procedure.name,
        value: p.procedure.id,
      }));
      const medicationOptions = medications.map((md) => ({
        label: md.medication.conceptName,
        value: md.medication.id,
      }));

      setLaboratoryTestOptionsSelected(laboratoryOptions);
      setProceduresOptionsSelected(procedureOptions);
      setMedicationOptionsSelected(medicationOptions);
      setConditionForm(conditionWithRelations);
    }
  }, [conditionWithRelations]);

  useEffect(() => {
    onChangeForm(conditionForm);
  }, [conditionForm]);

  useEffect(() => {
    (async () => {
      const laboratoryTestsResult = await healthDataService.findAllLaboratoryTestsByParams({
        status: LaboratoryTestStatus.ACTIVE,
      });
      const medicationsResult = await healthDataService.findAllMedicationsByParams({
        status: MedicationStatus.ACTIVE,
      });
      const proceduresResult = await healthDataService.findAllProceduresByParams({
        status: ProcedureStatus.ACTIVE,
      });

      if (!laboratoryTestsResult?.data) {
        notify(laboratoryTestsResult.message || 'There is a problem fetching laboratory tests');
        return;
      }

      if (!medicationsResult?.data) {
        notify(medicationsResult.message || 'There is a problem fetching medications');
        return;
      }

      if (!proceduresResult?.data) {
        notify(proceduresResult.message || 'There is a problem fetching procedures');
        return;
      }

      setLaboratoryTests(laboratoryTestsResult.data.laboratoryTests || []);
      setProcedures(proceduresResult.data.procedures || []);
      setMedications(medicationsResult.data.medications || []);
    })();
  }, []);

  return (
    <Box>
      <Box className='tab-section conditions-section'>
        <Box className='tab-section-form-field condition-form-condition-section'>
          <Typography variant='body2'>Status:</Typography>
          <FormControl sx={{ minWidth: 120 }} size='small' key='form-control-condition-status'>
            <Select
              name='condition.status'
              value={conditionForm.condition.status}
              onChange={handleChangeForm}>
              {Object.entries(ConditionStatus).map(([label, value], index) => {
                return (
                  <MenuItem value={value} key={`item-condition-status-${index}`}>
                    {getCapitalizedWord(label)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <Box className='tab-section-form-field condition-form-condition-section'>
          <Typography variant='body2'>Name:</Typography>
          <TextField
            key={'condition-text-field-name'}
            size='small'
            placeholder='Write a name'
            value={conditionForm.condition.name}
            name='condition.name'
            onChange={handleChangeForm}
          />
        </Box>

        <Box className='tab-section-form-field condition-form-condition-section'>
          <Typography variant='body2'>ICD-10 Code:</Typography>
          <TextField
            key={'condition-text-field-icd10Code'}
            name='condition.icd10Code'
            size='small'
            placeholder='Write an ICD-10 Code'
            value={conditionForm.condition.icd10Code}
            onChange={handleChangeForm}
          />
        </Box>
      </Box>

      <Box className='tab-section conditions-section'>
        <Typography fontWeight={600} mb={3}>
          Tests
        </Typography>

        <CustomTable
          columns={[
            { key: 'loincCode', label: 'LOINC', width: '13%' },
            { key: 'name', label: 'Test Name', width: '50%' },
            { key: 'status', label: 'Status', width: '12%' },
            {
              key: 'validityPeriodFrom',
              label: 'From',
              align: 'left',
              width: '12.5%',
              minWidth: '200px',
            },
            {
              key: 'validityPeriodUntil',
              label: 'Until',
              align: 'left',
              width: '12.5%',
              minWidth: '200px',
            },
            {
              key: 'actions',
              label: 'Actions',
              align: 'left',
              width: '5%',
              minWidth: '50px',
            },
          ]}
          defaultText='No tests associated'
          key={'conditions-form-tests-table'}
          rows={conditionForm.laboratoryTests.map((data, index) => {
            const [fromAmount, fromPeriod] = data.validityPeriodFrom.split(' ');
            const [untilAmount, untilPeriod] = data.validityPeriodUntil.split(' ');

            return {
              loincCode: data.laboratoryTest.loincCode,
              status: data.laboratoryTest.status,
              name: data.laboratoryTest.name,
              validityPeriodFrom: [
                <TextField
                  key={`text-field-test-validity-from`}
                  size='small'
                  sx={{ maxWidth: 60, marginRight: '8px' }}
                  value={fromAmount}
                  onChange={(e) =>
                    handleChangeValidityPeriod(
                      e,
                      conditionForm.laboratoryTests[index].validityPeriodFrom,
                      'number',
                    )
                  }
                  name={`laboratoryTests.${index}.validityPeriodFrom`}
                />,
                <FormControl
                  sx={{ minWidth: 120 }}
                  size='small'
                  key='form-control-test-validity-from'>
                  <Select
                    name={`laboratoryTests.${index}.validityPeriodFrom`}
                    value={fromPeriod}
                    onChange={(e) =>
                      handleChangeValidityPeriod(
                        e,
                        conditionForm.laboratoryTests[index].validityPeriodFrom,
                        'string',
                      )
                    }>
                    {Object.entries(ValidityTimeInterval).map(([label, value], index) => {
                      return (
                        <MenuItem value={value} key={`item-tests-validity-from-${index}`}>
                          {`${label.substring(0, label.length - 1).toLocaleLowerCase()}(s)`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>,
              ],
              validityPeriodUntil: [
                <TextField
                  name={`laboratoryTests.${index}.validityPeriodUntil`}
                  key={`text-field-test-validity-until`}
                  size='small'
                  sx={{ maxWidth: 60, marginRight: '8px' }}
                  value={untilAmount}
                  onChange={(e) =>
                    handleChangeValidityPeriod(
                      e,
                      conditionForm.laboratoryTests[index].validityPeriodUntil,
                      'number',
                    )
                  }
                />,
                <FormControl
                  sx={{ minWidth: 120 }}
                  size='small'
                  key='form-control-test-validity-until'>
                  <Select
                    name={`laboratoryTests.${index}.validityPeriodUntil`}
                    value={untilPeriod}
                    onChange={(e) =>
                      handleChangeValidityPeriod(
                        e,
                        conditionForm.laboratoryTests[index].validityPeriodUntil,
                        'string',
                      )
                    }>
                    {Object.entries(ValidityTimeInterval).map(([label, value], index) => {
                      return (
                        <MenuItem value={value} key={`item-tests-validity-until-${index}`}>
                          {`${label.substring(0, label.length - 1).toLocaleLowerCase()}(s)`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>,
              ],
              actions: (
                <IconButton
                  onClick={() => {
                    const labTestOptionIndex = laboratoryTestOptionsSelected.findIndex(
                      (p) => p.value === data.laboratoryTest.id,
                    );
                    if (labTestOptionIndex === -1) {
                      return;
                    }

                    const options = arrayWithoutEntry(
                      labTestOptionIndex,
                      laboratoryTestOptionsSelected,
                    );
                    setLaboratoryTestOptionsSelected(options);
                    handleAddNewRecord(options, MedicalSetupEntityType.LaboratoryTests);
                  }}>
                  <DeleteOutlineIcon color='error' />
                </IconButton>
              ),
            };
          })}
        />

        <Autocomplete
          disableClearable
          renderTags={() => null}
          disablePortal
          options={laboratoryTestOptions}
          sx={{ width: 500 }}
          multiple
          getOptionLabel={(option) => option.label}
          disableListWrap
          getOptionDisabled={(option) =>
            !!laboratoryTestOptionsSelected.find((p) => p.value === option.value)
          }
          value={laboratoryTestOptionsSelected}
          fullWidth
          onChange={(e, options) => {
            setLaboratoryTestOptionsSelected(options);
            handleAddNewRecord(options, MedicalSetupEntityType.LaboratoryTests);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              key={`autocomplete-tests`}
              size='small'
              label='Add a new laboratory test'
              onChange={(e) =>
                debouncedHandleSearch({
                  value: e.target.value,
                  entity: MedicalSetupEntityType.LaboratoryTests,
                  callback: setLaboratoryTests as Dispatch<
                    SetStateAction<(LaboratoryTest | Medication | Procedure)[]>
                  >,
                })
              }
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
      </Box>

      <Box className='tab-section conditions-section'>
        <Typography fontWeight={600} mb={3}>
          Procedures
        </Typography>

        <CustomTable
          columns={[
            { key: 'status', label: 'Status', width: '13%' },
            { key: 'name', label: 'Name', width: '50%' },
            { key: 'cptCode', label: 'CPT', width: '12%' },
            {
              key: 'validityPeriodFrom',
              label: 'From',
              align: 'left',
              width: '12.5%',
              minWidth: '200px',
            },
            {
              key: 'validityPeriodUntil',
              label: 'Until',
              align: 'left',
              width: '12.5%',
              minWidth: '200px',
            },
            {
              key: 'actions',
              label: 'Actions',
              align: 'left',
              width: '5%',
              minWidth: '50px',
            },
          ]}
          defaultText='No procedures associated'
          key={'conditions-form-procedures-table'}
          rows={conditionForm.procedures.map((data, index) => {
            const [fromAmount, fromPeriod] = data.validityPeriodFrom.split(' ');
            const [untilAmount, untilPeriod] = data.validityPeriodUntil.split(' ');

            return {
              status: data.procedure.status,
              name: data.procedure.name,
              cptCode: data.procedure.cptCode,
              validityPeriodFrom: [
                <TextField
                  name={`procedures.${index}.validityPeriodFrom`}
                  key={`text-field-procedure-validity-from`}
                  size='small'
                  sx={{ maxWidth: 60, marginRight: '8px' }}
                  value={fromAmount}
                  onChange={(e) =>
                    handleChangeValidityPeriod(
                      e,
                      conditionForm.procedures[index].validityPeriodFrom,
                      'number',
                    )
                  }
                />,
                <FormControl
                  sx={{ minWidth: 120 }}
                  size='small'
                  key='form-control-procedure-validity-from'>
                  <Select
                    name={`procedures.${index}.validityPeriodFrom`}
                    value={fromPeriod}
                    onChange={(e) =>
                      handleChangeValidityPeriod(
                        e,
                        conditionForm.procedures[index].validityPeriodFrom,
                        'string',
                      )
                    }>
                    {Object.entries(ValidityTimeInterval).map(([label, value], index) => {
                      return (
                        <MenuItem value={value} key={`item-procedures-validity-from-${index}`}>
                          {`${label.substring(0, label.length - 1).toLocaleLowerCase()}(s)`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>,
              ],
              validityPeriodUntil: [
                <TextField
                  name={`procedures.${index}.validityPeriodUntil`}
                  key={`text-field-procedure-validity-until`}
                  size='small'
                  sx={{ maxWidth: 60, marginRight: '8px' }}
                  value={untilAmount}
                  onChange={(e) =>
                    handleChangeValidityPeriod(
                      e,
                      conditionForm.procedures[index].validityPeriodUntil,
                      'number',
                    )
                  }
                />,
                <FormControl
                  sx={{ minWidth: 120 }}
                  size='small'
                  key='form-control-procedure-validity-until'>
                  <Select
                    name={`procedures.${index}.validityPeriodUntil`}
                    value={untilPeriod}
                    onChange={(e) =>
                      handleChangeValidityPeriod(
                        e,
                        conditionForm.procedures[index].validityPeriodUntil,
                        'string',
                      )
                    }>
                    {Object.entries(ValidityTimeInterval).map(([label, value], index) => {
                      return (
                        <MenuItem value={value} key={`item-procedures-validity-until-${index}`}>
                          {`${label.substring(0, label.length - 1).toLocaleLowerCase()}(s)`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>,
              ],
              actions: (
                <IconButton
                  onClick={() => {
                    const procedureIndex = procedureOptionsSelected.findIndex(
                      (p) => p.value === data.procedure.id,
                    );
                    if (procedureIndex === -1) {
                      return;
                    }
                    const options = arrayWithoutEntry(procedureIndex, procedureOptionsSelected);
                    setProceduresOptionsSelected(options);
                    handleAddNewRecord(options, MedicalSetupEntityType.Procedures);
                  }}>
                  <DeleteOutlineIcon color='error' />
                </IconButton>
              ),
            };
          })}
        />

        <Autocomplete
          disableClearable
          renderTags={() => null}
          disablePortal
          options={procedureOptions}
          sx={{ width: 500 }}
          multiple
          getOptionLabel={(option) => option.label}
          disableListWrap
          getOptionDisabled={(option) =>
            !!procedureOptionsSelected.find((p) => p.value === option.value)
          }
          value={procedureOptionsSelected}
          fullWidth
          onChange={(e, options) => {
            setProceduresOptionsSelected(options);
            handleAddNewRecord(options, MedicalSetupEntityType.Procedures);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              key={`autocomplete-procedures`}
              size='small'
              label='Add a new procedure'
              onChange={(e) =>
                debouncedHandleSearch({
                  value: e.target.value,
                  entity: MedicalSetupEntityType.Procedures,
                  callback: setProcedures as Dispatch<
                    SetStateAction<(LaboratoryTest | Medication | Procedure)[]>
                  >,
                })
              }
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
      </Box>

      <Box className='tab-section conditions-section'>
        <Typography fontWeight={600} fontSize='14px' mb={3}>
          Medications
        </Typography>

        <CustomTable
          columns={[
            { key: 'conceptName', label: 'Concept Name', width: '63%' },
            { key: 'status', label: 'Status', width: '12%' },
            { key: 'ndcCode', label: 'NDC', width: '12.5%', minWidth: '200px' },
            { key: 'rxCuiCode', label: 'RXCUI', width: '12.5%', minWidth: '200px' },
            {
              key: 'actions',
              label: 'Actions',
              align: 'left',
              width: '5%',
              minWidth: '50px',
            },
          ]}
          defaultText='No medications associated'
          key={'conditions-form-medications-table'}
          rows={conditionForm.medications.map((data) => {
            return {
              conceptName: data.medication.conceptName,
              status: data.medication.status,
              rxCuiCode: data.medication.rxCuiCode,
              ndcCode: data.medication.ndcCode,
              actions: (
                <IconButton
                  onClick={() => {
                    const medicationIndex = medicationOptionsSelected.findIndex(
                      (p) => p.value === data.medication.id,
                    );
                    if (medicationIndex === -1) {
                      return;
                    }
                    const options = arrayWithoutEntry(medicationIndex, medicationOptionsSelected);
                    setMedicationOptionsSelected(options);
                    handleAddNewRecord(options, MedicalSetupEntityType.Medications);
                  }}>
                  <DeleteOutlineIcon color='error' />
                </IconButton>
              ),
            };
          })}
        />

        <Autocomplete
          disablePortal
          options={medicationOptions}
          sx={{ width: 500 }}
          multiple
          clearOnBlur
          getOptionLabel={(option) => option.label}
          disableListWrap
          getOptionDisabled={(option) => !!medicationOptionsSelected.find((med) => false)}
          value={medicationOptionsSelected}
          fullWidth
          disableClearable
          renderTags={() => null}
          onChange={(e, options) => {
            setMedicationOptionsSelected(options);
            handleAddNewRecord(options, MedicalSetupEntityType.Medications);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              key={`autocomplete-medications`}
              size='small'
              label='Add a new medication'
              onChange={(e) =>
                debouncedHandleSearch({
                  value: e.target.value,
                  entity: MedicalSetupEntityType.Medications,
                  callback: setMedications as Dispatch<
                    SetStateAction<(LaboratoryTest | Medication | Procedure)[]>
                  >,
                })
              }
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
};
