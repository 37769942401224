import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { useRedirect } from 'react-admin';
import '../../common/tab-section.css';
import LinkableBreadcrumb from './LinkableBreadcrumb';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const AddLaboratoryResult: FC = () => {
  const redirect = useRedirect();

  return (
    <>
      <Box mt={2} mb={2}>
        <LinkableBreadcrumb
          items={[
            { text: 'laboratory results', location: '/laboratory-results' },
            { text: 'add new lab' },
          ]}
        />
      </Box>

      <Box className='tab-section laboratory-tests-section' mt={0}>
        <Box className='laboratory-tests-header'>
          <Typography>Add New Lab Results</Typography>

          <Box style={{ borderTop: '1px solid #e0e0e0' }} />
          <Stack gap={5} width={300}>
            <Button component='label' variant='contained' startIcon={<CloudUploadIcon />} fullWidth>
              Upload file
              <VisuallyHiddenInput
                type='file'
                onChange={(event) => console.log('Pending', event.target.files)}
                multiple={false}
              />
            </Button>
            <Typography>Or</Typography>
            <Button
              component='label'
              variant='outlined'
              fullWidth
              onClick={() => redirect('/laboratory-results/manual-input')}>
              Manual Input
            </Button>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default AddLaboratoryResult;
