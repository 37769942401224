import { Box } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useRedirect } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { CustomTabs } from '../common/CustomTabs/CustomTabs';
import { TabPanel } from '../common/TabPanel/TabPanel';
import '../common/tab-section.css';
import { Conditions } from './tabs/Conditions/Conditions';
import { LaboratoryTests } from './tabs/LaboratoryTests/LaboratoryTests';
import { Procedures } from './tabs/Procedures/Procedures';

type Props = {
  children: ReactNode;
};

const TABS_BY_INDEX: { [index: number]: string } = {
  0: 'conditions',
  1: 'laboratory-tests',
  2: 'medications',
  3: 'procedures',
};

export const MedicalSetup = ({ children }: Props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const location = useLocation();

  const redirect = useRedirect();

  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
    redirect(`/medical-setup/${TABS_BY_INDEX[tabIndex]}`);
  };

  useEffect(() => {
    const path = location.pathname.split('/')?.[2];

    if (!path) {
      redirect('/medical-setup/conditions');
      return;
    }

    const [index] = Object.entries(TABS_BY_INDEX).find(([index, tab]) => path === tab) || [null];

    if (!index) {
      return;
    }

    setCurrentTabIndex(Number(index));
  }, [location.pathname]);

  return (
    <Box mt={4} className='tab-section-table'>
      <CustomTabs
        tabLabels={[
          {
            label: 'Conditions',
            onClick: () => {
              redirect('/medical-setup/conditions');
            },
          },
          {
            label: 'Laboratory Tests',
            onClick: () => {
              redirect('/medical-setup/laboratory-tests');
            },
          },
          {
            label: 'Medications',
            onClick: () => {
              redirect('/medical-setup/medications');
            },
          },
          {
            label: 'Procedures',
            onClick: () => {
              redirect('/medical-setup/procedures');
            },
          },
        ]}
        value={currentTabIndex}
        onChange={handleChange}
      />
      <TabPanel value={currentTabIndex} index={0} key={'conditions'}>
        {children || <Conditions />}
      </TabPanel>
      <TabPanel value={currentTabIndex} index={1} key={'laboratory-tests'}>
        {children || <LaboratoryTests />}
      </TabPanel>
      <TabPanel value={currentTabIndex} index={2} key={'medications'}>
        {children || 'Medications'}
      </TabPanel>
      <TabPanel value={currentTabIndex} index={3} key={'procedures'}>
        {children || <Procedures />}
      </TabPanel>
    </Box>
  );
};
