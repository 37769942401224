import Looks3Icon from '@mui/icons-material/Looks3';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import { Box } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useNotify } from 'react-admin';
import { BillingService } from '../../../services';
import { CustomTabs } from '../../common/CustomTabs/CustomTabs';
import { RPMExportResults } from '../export-history/RPMExportResults';
import { PatientSelection } from '../patient-selection/PatientSelection';
import { RPMCalculation } from '../rpm-calculation/RPMCalculation';

interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
}

const TabPanel = ({ children, value, index }: TabPanelProps) => (
  <div role='tabpanel' hidden={value !== index}>
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

export const TabsComponent = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [enabledTabs, setEnabledTabs] = React.useState([0]);

  const notify = useNotify();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (enabledTabs.includes(newValue)) {
      setTabIndex(newValue);
    }
  };

  const enableAndSwitchTab = (index: number) => {
    setEnabledTabs((prev) => [...prev, index]);
    setTabIndex(index);
  };

  const getCurrentTab = useCallback(async (): Promise<number> => {
    const billingService = new BillingService();
    const results = await Promise.all([
      billingService.getClaimsCalculated({}),
      billingService.getExportedClaims({}),
    ]);

    if (!results[1]) {
      notify('There was an error getting the exported claims', 'warning');
    }
    if (!results[0]) {
      notify('There was an error getting the calculated claims', 'warning');
    }

    if (!!results[1]?.pagination.totalCount) {
      return 2;
    }
    if (!!results[0]?.pagination.totalCount) {
      return 1;
    }
    return 0;
  }, [notify]);

  useEffect(() => {
    // Choose tab based on the existing claims
    (async () => {
      const selectedTab = await getCurrentTab();
      setTabIndex(selectedTab);
    })();
  }, [getCurrentTab]);

  return (
    <Box sx={{ width: '100%' }}>
      <CustomTabs
        tabLabels={[
          { label: 'Patient Selection', icon: <LooksOneIcon /> },
          { label: 'RPM Calculation', icon: <LooksTwoIcon /> },
          { label: 'RPM Export Results', icon: <Looks3Icon /> },
        ]}
        value={tabIndex}
        onChange={handleTabChange}
        enabledTabs={enabledTabs}
        aria-label='RPM Process Tabs'
        useEnabledTabs={true}
      />
      <TabPanel value={tabIndex} index={0}>
        <PatientSelection onAdvance={() => enableAndSwitchTab(1)} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <RPMCalculation
          onAdvance={() => enableAndSwitchTab(2)}
          onMoveTo={(tabIndex) => {
            setTabIndex(tabIndex);
          }}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <RPMExportResults />
      </TabPanel>
    </Box>
  );
};
