import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, CircularProgress, Grid, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from 'react';
import { FilterConfiguration, LoadingStates } from '../types/rpms.types';
import { ReusableAutocomplete } from './ReusableAutocomplete';
import { ReusableSelectFilter } from './ReusableSelectFilter';

interface RPMFiltersProps<T extends Record<string, string[]>> {
  configurations: FilterConfiguration<T>[];
  onApplyFilters: (filters: T) => void;
  onClearFilters?: () => void | Promise<void>;
  onProcessFilters?: () => void;
  onProcessLabel?: string;
  initialFilters: T;
  filters?: T;
  selectedCount?: number;
  loadingStates: LoadingStates;
  additionalButtons?: React.ReactNode;
  allRecordsSelection?: boolean;
}

export const RPMFilters = <T extends Record<string, string[]>>({
  configurations,
  onApplyFilters,
  onClearFilters,
  onProcessFilters,
  onProcessLabel = 'Process',
  initialFilters,
  selectedCount = 0,
  loadingStates,
  additionalButtons,
  filters = initialFilters,
  allRecordsSelection = false,
}: RPMFiltersProps<T>) => {
  const [localFilters, setLocalFilters] = useState<T>(filters);

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const handleFilterChange = <K extends keyof T>(key: K, value: T[K]) => {
    setLocalFilters((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* Dynamic Filter Fields */}
      <Grid container spacing={2}>
        {configurations.map((config) => (
          <Grid key={config.key as string} item xs={12} sm={6} md={3}>
            {config.type === 'autocomplete' ? (
              <ReusableAutocomplete
                fetchOptions={config.fetchOptions}
                includeSelectAll={config.includeSelectAll ?? false}
                label={config.label}
                multiple={config.multiple ?? false}
                onChange={(value) => handleFilterChange(config.key, value as T[keyof T])}
                options={config.options!}
                value={localFilters[config.key]}
              />
            ) : config.type === 'select' ? (
              <ReusableSelectFilter
                label={config.label}
                name={config.key as string}
                multiple={config.multiple ?? false}
                onChange={(value: string[]) => handleFilterChange(config.key, value as T[keyof T])}
                options={config.options!}
                section={config.section}
                value={localFilters[config.key]}
                reset={loadingStates.isClearing}
              />
            ) : config.type === 'date' ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={config.label}
                  value={localFilters[config.key]}
                  onChange={(value) => handleFilterChange(config.key, value as T[keyof T])}
                  renderInput={(params) => <TextField {...params} variant='filled' fullWidth />}
                />
              </LocalizationProvider>
            ) : null}
          </Grid>
        ))}
      </Grid>

      {/* Action Buttons */}
      <Grid container spacing={2} mt={2}>
        {/* Left Buttons */}
        <Grid item xs={12} sm={6} md={6}>
          <Box display='flex' gap={2}>
            <Button
              color='primary'
              disabled={loadingStates.isApplying}
              fullWidth
              variant='contained'
              onClick={() => onApplyFilters(localFilters)}
              startIcon={
                loadingStates.isApplying ? (
                  <CircularProgress style={{ color: '#ffffff' }} size={20} />
                ) : (
                  <FilterAltOutlinedIcon />
                )
              }>
              Apply Filters
            </Button>
            <Button
              color='primary'
              disabled={loadingStates.isClearing}
              fullWidth
              variant='contained'
              onClick={onClearFilters}
              startIcon={
                loadingStates.isClearing ? (
                  <CircularProgress style={{ color: '#ffffff' }} size={20} />
                ) : (
                  <RefreshIcon />
                )
              }>
              Clear Filters
            </Button>
          </Box>
        </Grid>

        {/* Right Buttons */}
        <Grid item xs={12} sm={6} md={6}>
          <Box display='flex' justifyContent='flex-end' gap={2}>
            {additionalButtons}
            {onProcessFilters && (
              <Button
                variant='contained'
                onClick={onProcessFilters}
                endIcon={
                  loadingStates.isProcessing ? (
                    <CircularProgress style={{ color: '#ffffff' }} size={20} />
                  ) : (
                    <NavigateNextIcon />
                  )
                }
                disabled={
                  allRecordsSelection ? false : selectedCount === 0 || loadingStates.isProcessing
                }>
                {onProcessLabel}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
