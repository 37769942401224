import { FeaturesApiClient } from '@enara-modules/features-client';
import { GetFeaturesResult } from '@enara-modules/features-client/dist/services/features-api/features-api.types';

export enum FeaturesName {
  OpsDashShowPatientsLabResults = 'ops-dash-show-patients-lab-results',
  OpsDashShowMedicalSetup = 'ops-dash-show-medical-setup',
  OpsDashShowPatientConditions = 'ops-dash-show-patient-conditions',
  OpsDashShowAuditLogs = 'ops-dash-show-audit-logs',
}

class FeaturesClient extends FeaturesApiClient {
  constructor() {
    super({
      targetEnv: process.env.REACT_APP_FEATURES_API_ENV!,
      baseUrl: process.env.REACT_APP_FEATURES_API_BASE_URL!,
    });
  }

  public async fetchFeatureFlagsStatus(
    features: { name: FeaturesName; anyId?: number }[],
  ): Promise<boolean[]> {
    try {
      const featuresResult = await this.getFeatures({ featureNames: features.map((f) => f.name) });

      return features.map((f) => this.isFeatureEnabled(featuresResult.values, f));
    } catch (error) {
      console.error(error);
    }

    return [...Array.from({ length: features.length }, () => false)];
  }

  /**
   * @param anyId Any as we tend to store there clinic IDs, member IDs, etc.
   */
  private isFeatureEnabled(
    values: GetFeaturesResult<FeaturesName>['values'],
    feature: { name: FeaturesName; anyId?: number },
  ): boolean {
    if (feature.anyId) {
      return !!values[feature.name]?.clinicIds?.includes(feature.anyId);
    }

    return !!values[feature.name]?.enabled;
  }
}

const featuresClient = new FeaturesClient();

export default featuresClient;
