import { Box, Button, TextField, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import useDebounce from '../../../hooks/useDebounce';
import { LaboratoryResultGroup, Pagination } from '../../../interfaces/health-data';
import healthDataService from '../../../services/health-data';
import { DEFAULT_PAGE_SIZE, INITIAL_PAGINATION_STATE } from '../../../util/constants';
import { formatDateStringToMMDDYYYY } from '../../../util/dateFormat';
import { CustomTable } from '../../common/CustomTable';
import '../../common/tab-section.css';
import { preloadLaboratoryTests } from './Form/LaboratoryTestSelector';
import LinkableBreadcrumb from './LinkableBreadcrumb';

const LaboratoryResults: FC = () => {
  const [laboratoryResultGroups, setLaboratoryResultGroups] = useState<LaboratoryResultGroup[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<Pagination>(INITIAL_PAGINATION_STATE);

  const redirect = useRedirect();
  const notify = useNotify();

  const fetchLaboratoryResults = useCallback(
    async (patientName?: string) => {
      const response = await healthDataService.findAllPatientLaboratoryResults({
        patientName,
        page,
      });

      if (!response.data) {
        return notify('Could not fetch laboratory results', 'error');
      }

      const { labResultGroups, pagination } = response.data;

      setLaboratoryResultGroups(labResultGroups);
      setPagination(pagination);
    },
    [page, notify],
  );

  useEffect(() => {
    fetchLaboratoryResults();

    // So it is ready to populate selectors when creating or editing laboratory results
    preloadLaboratoryTests();
  }, [page, fetchLaboratoryResults]);

  const fetchLabResultsCallback = useDebounce(fetchLaboratoryResults);

  return (
    <>
      <Box mt={2} mb={2} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <LinkableBreadcrumb items={[{ text: 'laboratory results' }]} />

        <Button
          size='small'
          variant='contained'
          sx={{ fontSize: '12px', padding: '7px 8px', textTransform: 'capitalize' }}
          onClick={() => redirect('/laboratory-results/add')}>
          Add New Lab
        </Button>
      </Box>
      <Box className='tab-section laboratory-tests-section' marginTop={0}>
        <Box className='laboratory-tests-header'>
          <Box>
            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <Typography mb={1} sx={{ '&.MuiTypography-root': { fontSize: '14px' } }}>
                Search by patient name
              </Typography>
              <TextField
                size='small'
                placeholder='Type patient name'
                sx={{ width: '400px', minWidth: '250px' }}
                onChange={(e) => fetchLabResultsCallback(e.target.value)}
              />
            </Box>
          </Box>
        </Box>

        <CustomTable
          columns={[
            { key: 'memberId', label: 'ID' },
            { key: 'name', label: 'Name' },
            { key: 'clinicName', label: 'Clinic' },
            { key: 'date', label: 'Date' },
            { key: 'actions', label: 'Actions', width: '150px', minWidth: '150px' },
          ]}
          defaultText='No laboratory results found'
          rows={laboratoryResultGroups.map(({ groupId, date, patient, labResults }) => ({
            memberId: patient.internalId,
            name: patient.fullName,
            clinicName: patient.clinic.name || 'Unknown',
            date: formatDateStringToMMDDYYYY(date),
            actions: [
              <Button
                size='small'
                variant='text'
                sx={{ textTransform: 'capitalize' }}
                onClick={() =>
                  redirect(`/laboratory-results/${groupId}/edit`, undefined, undefined, undefined, {
                    groupId,
                    date,
                    labResults,
                    patient,
                  })
                }>
                Edit
              </Button>,
            ],
          }))}
          pagination={{
            rowsPerPageOptions: [DEFAULT_PAGE_SIZE],
            rowPerPage: pagination.perPage,
            totalCount: pagination.totalCount,
            page: pagination.currentPage,
            onPageChange: (page: number) => setPage(page),
          }}
        />
      </Box>
    </>
  );
};

export default LaboratoryResults;
