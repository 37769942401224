import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Button, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import {
  Patient,
  PatientConditionFromReport,
  PatientConditionStatus,
} from '../../../interfaces/health-data';
import healthDataService from '../../../services/health-data';
import { getDateStringFromUTCDate } from '../../../util/dateFormat';
import { getCapitalizedWord } from '../../../util/stringFormat';
import { BackButton } from '../../common/BackButton';
import { CustomTable } from '../../common/CustomTable';
import { LoaderBubble } from '../../common/LoaderBubble/LoaderBubble';

export const ViewPatientCondition = () => {
  const [patient, setPatient] = useState<Patient | null>(null);
  const [activePatientConditions, setActivePatientConditions] = useState<
    PatientConditionFromReport[]
  >([]);
  const [resolvedPatientConditions, setPatientResolvedConditions] = useState<
    PatientConditionFromReport[]
  >([]);
  const [breadcrumbLabels, setBreadcrumbLabels] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { patientId } = useParams<{ patientId: string }>();
  const redirect = useRedirect();
  const notify = useNotify();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const activeConditionsResponse = await healthDataService.fetchConditionsAssociatedWithPatient(
        {
          status: PatientConditionStatus.Active,
          patientId,
        },
      );

      if (!activeConditionsResponse.data) {
        notify(
          activeConditionsResponse.message || 'There was an error obtaining patient information',
          'error',
        );
        setIsLoading(false);
        return;
      }

      const resolvedConditionsResponse =
        await healthDataService.fetchConditionsAssociatedWithPatient({
          status: PatientConditionStatus.Resolved,
          patientId,
        });

      if (!resolvedConditionsResponse.data) {
        notify(
          resolvedConditionsResponse.message || 'There was an error obtaining patient information',
          'error',
        );
        setIsLoading(false);
        return;
      }

      setActivePatientConditions(activeConditionsResponse.data.conditions);
      setPatientResolvedConditions(resolvedConditionsResponse.data.conditions);

      const currentPatient =
        activeConditionsResponse.data.patient || resolvedConditionsResponse.data.patient;

      setBreadcrumbLabels(['Conditions', 'Patient', currentPatient.internalId || 'Unknown']);
      setPatient(currentPatient);
      setIsLoading(false);
    })();
  }, [patientId]);

  if (patientId && !patient && !isLoading) {
    return (
      <Stack spacing={2}>
        <Typography>
          There is an unknown error. Please contact with Enara's Tech Support describing this issue.
        </Typography>
        <BackButton basePath='/patients/conditions' />
      </Stack>
    );
  }

  return (
    <>
      {patient && activePatientConditions && resolvedPatientConditions && !isLoading ? (
        <Box>
          <Box display='flex' justifyContent='space-between' className='tab-section-action-bar'>
            <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
              {breadcrumbLabels.map((text, index) => {
                return (
                  <Typography
                    key={`text-patientWithConditions-breadcrumb-${index}`}
                    textTransform='capitalize'>
                    {text}
                  </Typography>
                );
              })}
            </Breadcrumbs>

            <Button
              onClick={() => redirect(`/patients/${patientId}/conditions/edit`)}
              variant='contained'
              sx={{ textTransform: 'capitalize' }}>
              Edit
            </Button>
          </Box>

          <Box className='tab-section conditions-section'>
            <Box display='flex' flexDirection='row' mb={1}>
              <Typography mr={1}>Patient Name:</Typography>
              <Typography textTransform='capitalize' fontWeight={300}>
                {getCapitalizedWord(patient.name)}
              </Typography>
            </Box>

            <Box display='flex' flexDirection='row' mb={1}>
              <Typography mr={1}>Clinic: </Typography>
              <Typography fontWeight={300}>{patient.clinicName}</Typography>
            </Box>

            <Box display='flex' flexDirection='row' mb={1}>
              <Typography mr={1}>ID: </Typography>
              <Typography fontWeight={300}>{patient.internalId}</Typography>
            </Box>
          </Box>

          <Box className='tab-section'>
            <Typography mb={4}>Active Conditions</Typography>

            <CustomTable
              columns={[
                { key: 'icd10Code', label: 'ICD-10 Code', minWidth: '80px' },
                { key: 'name', label: 'Problem', width: '50%' },
                { key: 'status', label: 'Status', minWidth: '40px' },
                {
                  key: 'firstSeenOn',
                  label: 'First Seen',
                  align: 'left',
                  minWidth: '100px',
                },
                {
                  key: 'lastSeenOn',
                  label: 'Last Seen',
                  align: 'left',
                  minWidth: '100px',
                },
              ]}
              defaultText='No active conditions associated to patient'
              key={'conditions-form-tests-table'}
              rows={
                activePatientConditions.map((data, index) => {
                  return {
                    icd10Code: data.associatedCondition?.icd10Code || data.code,
                    name: data.condition,
                    status: data.status,
                    firstSeenOn: getDateStringFromUTCDate(data.firstSeen),
                    lastSeenOn: getDateStringFromUTCDate(data.lastSeen),
                  };
                }) || []
              }
            />
          </Box>

          <Box className='tab-section'>
            <Box>
              <Typography mb={4}>Resolved Conditions</Typography>

              <CustomTable
                columns={[
                  { key: 'icd10Code', label: 'ICD-10 Code', minWidth: '50px' },
                  { key: 'name', label: 'Problem', width: '50%' },
                  {
                    key: 'firstSeenOn',
                    label: 'First Seen',
                    align: 'left',
                    minWidth: '100px',
                  },
                  {
                    key: 'lastSeenOn',
                    label: 'Last Seen',
                    align: 'left',
                    minWidth: '100px',
                  },
                ]}
                defaultText='No resolved conditions associated to patient'
                key={'conditions-form-tests-table'}
                rows={
                  resolvedPatientConditions
                    ?.map((data, index) => ({
                      index,
                      icd10Code: data.associatedCondition?.icd10Code || data.code,
                      name: data.condition,
                      firstSeenOn: getDateStringFromUTCDate(data.firstSeen),
                      lastSeenOn: getDateStringFromUTCDate(data.lastSeen),
                    }))
                    .sort((a, b) => b.index - a.index) || []
                }
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='100%'
          width='100%'
          flexDirection='column'>
          <Typography mb={3}>Wait a few seconds...</Typography>
          <LoaderBubble />
        </Box>
      )}
    </>
  );
};
