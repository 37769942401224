import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Medication } from '../../../../interfaces/health-data';
import { getCapitalizedWord } from '../../../../util/stringFormat';
import { LoaderBubble } from '../../../common/LoaderBubble/LoaderBubble';

export const ViewMedication = () => {
  const [medication, setMedication] = useState<Medication | null>(null);
  const [breadcrumbLabels, setBreadcrumbLabels] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { medicationId } = useParams<{ medicationId: string }>();
  const redirect = useRedirect();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      //const medication = await healthDataService.getMedication(Number(medicationId));
      //setBreadcrumbLabels(['medications', medication?.name || 'Unknown']);
      setMedication(medication);
      setIsLoading(false);
    })();
  }, [medicationId]);

  return (
    <>
      {medication && !isLoading ? (
        <Box>
          <Box display='flex' justifyContent='space-between' className='tab-section-action-bar'>
            <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
              {breadcrumbLabels.map((text, index) => {
                return (
                  <Typography
                    key={`text-medication-breadcrumb-${index}`}
                    textTransform='capitalize'>
                    {text}
                  </Typography>
                );
              })}
            </Breadcrumbs>

            <Button
              onClick={() => redirect(`medical-setup/medications/${medicationId}/edit`)}
              variant='contained'
              sx={{ textTransform: 'capitalize' }}>
              Edit
            </Button>
          </Box>

          <Box className='tab-section medications-section'>
            <Box display='flex' flexDirection='row' mb={1}>
              <Typography mr={1}>Status:</Typography>
              <Typography textTransform='capitalize' fontWeight={300}>
                {getCapitalizedWord(medication.status)}
              </Typography>
            </Box>

            <Box display='flex' flexDirection='row' mb={1}>
              <Typography mr={1}>Name: </Typography>
              <Typography fontWeight={300}>{medication.conceptName}</Typography>
            </Box>

            <Box display='flex' flexDirection='row' mb={1}>
              <Typography mr={1}>NDC Code: </Typography>
              <Typography fontWeight={300}>{medication.ndcCode}</Typography>
            </Box>

            <Box display='flex' flexDirection='row' mb={1}>
              <Typography mr={1}>RxCui Code: </Typography>
              <Typography fontWeight={300}>{medication.rxCuiCode}</Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='100%'
          width='100%'
          flexDirection='column'>
          <Typography mb={3}>Wait a few seconds...</Typography>
          <LoaderBubble />
        </Box>
      )}
    </>
  );
};
