import { RpmExclusionCriteria, RpmStatus } from '../types/rpms.types';

export const getStatusChipColor = (status: string) => {
  switch (status) {
    case RpmStatus.VALID:
      return 'success';
    case RpmStatus.INVALID:
      return 'error';
    default:
      return 'default';
  }
};

export const getDetailChipColor = (detail: string) => {
  switch (detail) {
    case RpmExclusionCriteria.ClinicDisabled:
    case RpmExclusionCriteria.PayerDisabled:
    case RpmExclusionCriteria.RpmDisabled:
    case RpmExclusionCriteria.InvalidBillingCycleDates:
      return { backgroundColor: '#F8D7DA', color: '#721C24' };

    case RpmExclusionCriteria.Icd10InsufficientCodes:
    case RpmExclusionCriteria.Icd10NoECode:
    case RpmExclusionCriteria.Icd10NonBillableCodesIncluded:
    case RpmExclusionCriteria.NoCptCodes:
      return { backgroundColor: '#FFF3CD', color: '#856404' };

    case RpmExclusionCriteria.InvalidStatus:
    case RpmExclusionCriteria.NotActive:
    case RpmExclusionCriteria.NoProfileStatus:
      return { backgroundColor: '#E2E3E5', color: '#383D41' };

    case RpmExclusionCriteria.NoInsurance:
    case RpmExclusionCriteria.NoCoverageType:
      return { backgroundColor: '#D1ECF1', color: '#0C5460' };

    case RpmExclusionCriteria.InsufficientHealthData:
      return { backgroundColor: '#D4EDDA', color: '#155724' };

    default:
      return { backgroundColor: '#F5F5F5', color: '#212121' };
  }
};
