import { Box, Button, Chip, Snackbar, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRedirect } from 'react-admin';
import useDebounce from '../../../hooks/useDebounce';
import {
  Pagination,
  Patient,
  PatientConditionFromReport,
  PatientConditionStatus,
} from '../../../interfaces/health-data';
import healthDataService from '../../../services/health-data';
import { DEFAULT_PAGE_SIZE, INITIAL_PAGINATION_STATE } from '../../../util/constants';
import { getCapitalizedWord } from '../../../util/stringFormat';
import { CustomTable } from '../../common/CustomTable';
import './patient-condition.css';

export const PatientConditions = () => {
  const [conditionsByPatient, setConditionsByPatient] = useState<
    { conditions: PatientConditionFromReport[]; patient: Patient }[]
  >([]);
  const [message, setMessage] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);

  const redirect = useRedirect();

  const [pagination, setPagination] = useState<Pagination>(INITIAL_PAGINATION_STATE);

  const fetchAllPatientConditions = async ({
    patientSearchString,
  }: { patientSearchString?: string } = {}) => {
    const response = await healthDataService.retrieveConditionsByPatient({
      params: { patientSearchString, status: PatientConditionStatus.Active },
      page,
    });

    if (!response.data) {
      setMessage(response.message || 'There is a problem fetching active conditions');
      return;
    }

    setPagination(response.data.pagination);
    setConditionsByPatient(response.data.conditionsByPatient);
  };

  const debouncedFetchPatientCondition = useDebounce(fetchAllPatientConditions);

  const handleChangePage = (page: number) => setPage(page);

  useEffect(() => {
    (async () => await fetchAllPatientConditions())();
  }, [page]);

  return (
    <>
      <Box className='tab-section conditions-section' marginTop={0}>
        <Box className='patient-conditions-header'>
          <Typography>Active Condition</Typography>
          <Box>
            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <Typography
                mb={1}
                sx={{
                  '&.MuiTypography-root': { fontSize: '14px' },
                }}>
                Search Patient
              </Typography>
              <TextField
                key={`autocomplete-conditions`}
                size='small'
                placeholder='Search in patients'
                sx={{
                  width: '400px',
                  minWidth: '250px',
                }}
                onChange={(e) =>
                  debouncedFetchPatientCondition({
                    patientSearchString: e.target.value,
                  })
                }
              />
            </Box>
          </Box>
        </Box>

        <CustomTable
          columns={[
            { key: 'id', label: 'ID' },
            { key: 'patientName', label: 'Patient Name' },
            { key: 'clinicName', label: 'Clinic' },
            { key: 'patientConditions', label: 'Active Conditions' },
            { key: 'actions', label: 'Actions', width: '150px', minWidth: '150px' },
          ]}
          defaultText='No patients with active conditions found'
          key={'patientConditions-table'}
          rows={conditionsByPatient.map((data) => {
            const { conditions, patient } = data;

            const conditionsChips = conditions.map((c) => (
              <Chip
                size='small'
                label={getCapitalizedWord(c.condition)}
                sx={{
                  fontSize: '11px',
                }}
              />
            ));

            return {
              id: patient.internalId || 'Unknown',
              patientName: patient.name,
              clinicName: patient.clinicName || 'Unknown',
              patientConditions: conditionsChips,
              actions: [
                <Button
                  size='small'
                  variant='text'
                  onClick={() => redirect(`/patients/${patient.id}/conditions/show`)}
                  sx={{
                    textTransform: 'capitalize',
                  }}>
                  View
                </Button>,
                <Button
                  size='small'
                  variant='text'
                  onClick={() => redirect(`/patients/${patient.id}/conditions/edit`)}
                  sx={{
                    textTransform: 'capitalize',
                  }}>
                  Edit
                </Button>,
              ],
            };
          })}
          pagination={{
            rowsPerPageOptions: [DEFAULT_PAGE_SIZE],
            rowPerPage: pagination.perPage,
            page: pagination.currentPage,
            totalCount: pagination.totalCount,
            onPageChange: handleChangePage,
          }}
        />
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!message}
        autoHideDuration={10000}
        onClose={() => setMessage('')}
        message={message}
        ContentProps={{
          style: { marginTop: '50px' },
        }}
      />
    </>
  );
};
