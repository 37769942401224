import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { ConditionWithRelations } from '../../../../interfaces/health-data';
import healthDataService from '../../../../services/health-data';
import { getCapitalizedWord } from '../../../../util/stringFormat';
import { CustomTable } from '../../../common/CustomTable';
import { LoaderBubble } from '../../../common/LoaderBubble/LoaderBubble';

export const ViewCondition = () => {
  const [conditionData, setConditionData] = useState<ConditionWithRelations | null>(null);
  const [breadcrumbLabels, setBreadcrumbLabels] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { conditionId } = useParams<{ conditionId: string }>();
  const redirect = useRedirect();
  const notify = useNotify();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await healthDataService.getCondition(Number(conditionId));

      if (!response.data) {
        notify(response.message || 'There was an error obtaining the condition', 'warning');
        setIsLoading(false);
        return;
      }
      setBreadcrumbLabels(['conditions', response.data.condition.name || 'Unknown']);
      setConditionData(response.data);
      setIsLoading(false);
    })();
  }, [conditionId]);

  return (
    <>
      {conditionData && !isLoading ? (
        <Box>
          <Box display='flex' justifyContent='space-between' className='tab-section-action-bar'>
            <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
              {breadcrumbLabels.map((text, index) => {
                return (
                  <Typography key={`text-condition-breadcrumb-${index}`} textTransform='capitalize'>
                    {text}
                  </Typography>
                );
              })}
            </Breadcrumbs>

            <Button
              onClick={() => redirect(`/medical-setup/conditions/${conditionId}/edit`)}
              variant='contained'
              sx={{ textTransform: 'capitalize' }}>
              Edit
            </Button>
          </Box>

          <Box className='tab-section conditions-section'>
            <Box display='flex' flexDirection='row' mb={1}>
              <Typography mr={1}>Status:</Typography>
              <Typography textTransform='capitalize' fontWeight={300}>
                {getCapitalizedWord(conditionData.condition.status)}
              </Typography>
            </Box>

            <Box display='flex' flexDirection='row' mb={1}>
              <Typography mr={1}>Name: </Typography>
              <Typography fontWeight={300}>{conditionData.condition.name}</Typography>
            </Box>

            <Box display='flex' flexDirection='row' mb={1}>
              <Typography mr={1}>ICD-10 Code: </Typography>
              <Typography fontWeight={300}>{conditionData.condition.icd10Code}</Typography>
            </Box>
          </Box>

          <Box className='tab-section conditions-section'>
            <Typography fontWeight={600} mb={3}>
              Tests
            </Typography>

            <CustomTable
              columns={[
                { key: 'loincCode', label: 'LOINC' },
                { key: 'name', label: 'Test Name' },
                { key: 'status', label: 'Status' },
                { key: 'validityPeriodFrom', label: 'From' },
                { key: 'validityPeriodUntil', label: 'Until' },
              ]}
              defaultText='No tests associated'
              key={'conditions-form-tests-table'}
              rows={conditionData.laboratoryTests.map((data) => {
                return {
                  loincCode: data.laboratoryTest.loincCode,
                  status: data.laboratoryTest.status,
                  name: data.laboratoryTest.name,
                  validityPeriodFrom: data.validityPeriodFrom,
                  validityPeriodUntil: data.validityPeriodUntil,
                };
              })}
            />
          </Box>

          <Box className='tab-section conditions-section'>
            <Typography fontWeight={600} mb={3}>
              Procedures
            </Typography>

            <CustomTable
              columns={[
                { key: 'status', label: 'Status' },
                { key: 'name', label: 'Name' },
                { key: 'cptCode', label: 'CPT' },
                { key: 'validityPeriodFrom', label: 'From' },
                { key: 'validityPeriodUntil', label: 'Until' },
              ]}
              defaultText='No procedures associated'
              key={'conditions-form-procedures-table'}
              rows={conditionData.procedures.map((data, index) => {
                return {
                  status: data.procedure.status,
                  name: data.procedure.name,
                  cptCode: data.procedure.cptCode,
                  validityPeriodFrom: data.validityPeriodFrom,
                  validityPeriodUntil: data.validityPeriodUntil,
                };
              })}
            />
          </Box>

          <Box className='tab-section conditions-section'>
            <Typography fontWeight={600} fontSize='14px' mb={3}>
              Medications
            </Typography>

            <CustomTable
              columns={[
                { key: 'conceptName', label: 'Concept Name' },
                { key: 'status', label: 'Status' },
                { key: 'ndcCode', label: 'NDC' },
                { key: 'rxCuiCode', label: 'RXCUI' },
              ]}
              defaultText='No medications associated'
              key={'conditions-form-medications-table'}
              rows={conditionData.medications.map((data) => {
                return {
                  conceptName: data.medication.conceptName,
                  status: data.medication.status,
                  rxCuiCode: data.medication.rxCuiCode,
                  ndcCode: data.medication.ndcCode,
                };
              })}
            />
          </Box>
        </Box>
      ) : (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='100%'
          width='100%'
          flexDirection='column'>
          <Typography mb={3}>Wait a few seconds...</Typography>
          <LoaderBubble />
        </Box>
      )}
    </>
  );
};
