import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Button, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { CreateLaboratoryTestPayload } from '../../../../interfaces/health-data';
import healthDataService from '../../../../services/health-data';
import { preloadLaboratoryTests } from '../../../patient/laboratory-results/Form/LaboratoryTestSelector';
import { LaboratoryTestForm } from './LaboratoryTestForm/LaboratoryTestForm';

export const CreateLaboratoryTest = () => {
  const [form, setForm] = useState<CreateLaboratoryTestPayload | null>(null);

  const breadcrumbLabels = ['laboratory-tests', 'create'];

  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = async () => {
    if (!form) {
      return;
    }

    const response = await healthDataService.createLaboratoryTest(form);

    if (!response.data) {
      notify(response.message || 'There was an error saving laboratory test', 'warning');
      return;
    }

    // Renew laboratory tests as they were updated recently
    preloadLaboratoryTests({ force: true });

    notify('LaboratoryTest created successfully', 'success');
    redirect(`/medical-setup/laboratory-tests/${response.data.id}/show`);
  };

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' className='tab-section-action-bar'>
        <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
          {breadcrumbLabels.map((text, index) => {
            return (
              <Typography
                key={`text-laboratory-test-breadcrumb-${index}`}
                textTransform='capitalize'>
                {text.replaceAll('-', ' ')}
              </Typography>
            );
          })}
        </Breadcrumbs>

        <Button
          onClick={handleSubmit}
          disabled={
            form?.loincCode.length === 0 || form?.name.length === 0 || form?.units.length === 0
          }
          variant='contained'
          sx={{ textTransform: 'capitalize' }}>
          Save
        </Button>
      </Box>

      <LaboratoryTestForm
        onChangeForm={useCallback((l) => setForm(l as CreateLaboratoryTestPayload), [])}
      />
    </Box>
  );
};
