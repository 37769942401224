import styled from '@emotion/styled';
import { Table, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';

export const StyledTableContainer = styled(TableContainer)({});

export const StyledTable = styled(Table)({
  '& .MuiTableCell-root': {
    padding: '4px 8px',
    fontSize: '0.875rem',
    lineHeight: 1.2,
  },
  '& .MuiTableRow-root': {
    height: '40px',
  },
});

export const StyledTableCell = styled(TableCell)({
  padding: '4px 8px',
  fontSize: '0.875rem',
});

export const StyledTableRow = styled(TableRow)({
  height: '40px',
});

export const StyledCheckboxCell = styled(TableCell)({
  padding: '4px',
  width: '40px',
});

export const StyledTablePagination = styled(TablePagination)({
  '.MuiTablePagination-toolbar': {
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& p': {
      fontSize: '0.875rem',
    },
  },
  display: 'flex',
  justifyContent: 'flex-end',
});
