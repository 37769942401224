import { Box, SxProps } from '@mui/material';

type Props = {
  children?: React.ReactNode;
  value: number;
  index: number;
  sx?: SxProps;
  className?: string;
  onClick?: () => void;
};
export const TabPanel = ({ children, value, index, sx, className, onClick, ...other }: Props) => {
  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      onClick={onClick}
      {...other}>
      {value === index && (
        <Box className={className} sx={{ p: 3, ...sx }}>
          {children}
        </Box>
      )}
    </Box>
  );
};
