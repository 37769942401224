import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  CreateLaboratoryTestPayload,
  LaboratoryTest,
  LaboratoryTestStatus,
} from '../../../../../interfaces/health-data';
import { getCapitalizedWord } from '../../../../../util/stringFormat';
import { CustomTable } from '../../../../common/CustomTable';
import './laboratory-test.css';

type Props = {
  laboratoryTest?: LaboratoryTest;
  onChangeForm: (v: Partial<LaboratoryTest>) => void;
};

export const LaboratoryTestForm = ({ laboratoryTest, onChangeForm }: Props) => {
  const [newUnit, setNewUnit] = useState<string>('');
  const [laboratoryTestForm, setLaboratoryTestForm] = useState<CreateLaboratoryTestPayload>({
    name: '',
    loincCode: '',
    status: LaboratoryTestStatus.ACTIVE,
    units: [],
  });

  const handleChangeForm = (e: SelectChangeEvent<string> | ChangeEvent<Element>) => {
    const { value, name } = e.target as {
      value: string;
      name: string;
    };

    setLaboratoryTestForm({ ...laboratoryTestForm, [name]: value });
  };

  useEffect(() => {
    onChangeForm(laboratoryTestForm as LaboratoryTest);
  }, [laboratoryTestForm, onChangeForm]);

  useEffect(() => {
    if (laboratoryTest) {
      setLaboratoryTestForm(laboratoryTest);
    }
  }, [laboratoryTest]);

  return (
    <Box>
      <Box className='tab-section conditions-section'>
        <Box className='tab-section-form-field laboratory-test-form-laboratory-test-section'>
          <Typography variant='body2'>Status:</Typography>
          <FormControl
            sx={{ minWidth: 120 }}
            size='small'
            key='form-control-laboratory-test-status'>
            <Select name='status' value={laboratoryTestForm.status} onChange={handleChangeForm}>
              {Object.entries(LaboratoryTestStatus).map(([label, value], index) => {
                return (
                  <MenuItem value={value} key={`item-laboratory-test-status-${index}`}>
                    {getCapitalizedWord(label)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <Box className='tab-section-form-field laboratory-test-form-laboratory-test-section'>
          <Typography variant='body2'>Name:</Typography>
          <TextField
            key={'laboratory-test-text-field-name'}
            size='small'
            placeholder='Write a name'
            value={laboratoryTestForm.name}
            name='name'
            onChange={handleChangeForm}
          />
        </Box>

        <Box className='tab-section-form-field laboratory-test-form-laboratory-test-section'>
          <Typography variant='body2'>LOINC Code:</Typography>
          <TextField
            key={'laboratory-test-text-field-loinc-code'}
            name='loincCode'
            size='small'
            placeholder='Write a LOINC Code'
            value={laboratoryTestForm.loincCode}
            onChange={handleChangeForm}
          />
        </Box>

        <Box style={{ width: 550 }}>
          <Typography variant='body2'>Units:</Typography>

          <CustomTable
            useStickyBottomRow
            minWidth={550}
            columns={[
              { key: 'units', label: 'Value' },
              { key: 'actions', label: 'Actions' },
            ]}
            rows={laboratoryTestForm.units
              .map((unit, index) => ({
                units: (
                  <TextField
                    size='small'
                    type='text'
                    value={unit}
                    onChange={(e) =>
                      setLaboratoryTestForm({
                        ...laboratoryTestForm,
                        units: laboratoryTestForm.units.map((u, i) =>
                          index === i ? e.target.value : u,
                        ),
                      })
                    }
                  />
                ),
                actions: [
                  <Button
                    size='small'
                    variant='text'
                    disabled={unit.trim().length === 0}
                    sx={{ textTransform: 'capitalize' }}
                    onClick={() =>
                      setLaboratoryTestForm({
                        ...laboratoryTestForm,
                        units: laboratoryTestForm.units.filter((u) => u !== unit),
                      })
                    }>
                    Delete
                  </Button>,
                ],
              }))
              .concat([
                {
                  units: (
                    <TextField
                      size='small'
                      type='text'
                      value={newUnit}
                      onChange={(e) => setNewUnit(e.target.value)}
                    />
                  ),
                  actions: [
                    <Button
                      size='small'
                      variant='contained'
                      disabled={newUnit.trim().length === 0}
                      sx={{ textTransform: 'capitalize' }}
                      onClick={() => {
                        setLaboratoryTestForm({
                          ...laboratoryTestForm,
                          units: [...laboratoryTestForm.units, newUnit],
                        });

                        setNewUnit('');
                      }}>
                      Add Unit
                    </Button>,
                  ],
                },
              ])}
          />
        </Box>
      </Box>
    </Box>
  );
};
