export interface BillingTransform {
  success: boolean;
  data: {
    file: string;
    errors: string;
  };
}

export interface BillingTransformAccess {
  hasAccess: boolean;
}

export interface IBillingClinic {
  target: {
    value: string;
  };
}

export enum ExtensionType {
  zip = 'zip',
  csv = 'csv',
}

export enum ClaimStatus {
  Pending = 'pending',
  Uploaded = 'uploaded',
  UploadedWithErrors = 'uploaded-with-errors',
  Processed = 'processed',
}

export enum ClaimType {
  RPM = 'rpm',
  Appointment = 'appointment',
  Unknown = 'unknown',
}

export enum EhrName {
  Drchrono = 'drchrono',
}

export interface EHRSyncPayload {
  fromDate?: string;
  untilDate?: string;
  clinicIds?: number[];
  forceEhrFetching?: boolean;
  enableSlackMessages?: boolean;
}

export interface EHRClaimSync {
  fromDate: string;
  untilDate: string;
  clinicIds?: string;
  providerIds?: string;
  patientIds?: string;
  statuses?: string;
  sources?: string;
}

export enum EHRClaimSyncStatus {
  Completed = 'completed',
  CompletedWithErrors = 'completed-with-errors',
  InProgress = 'in-progress',
  Pending = 'pending',
  Stopped = 'stopped',
}

export interface EHRClaimSyncRequest {
  id: number;
  uuid: string;
  clinicId: number;
  fromDate: string;
  untilDate: string;
  status: EHRClaimSyncStatus;
  forceEhrFetching: boolean;
  enableSlackMessages: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  uploaded: number;
  uploadedWithErrors: number;
  requested: number;
}

export interface EHRClaimSyncRequestPayload {
  clinicIds?: string;
  statuses?: string;
  page?: number;
  perPage?: number;
}

export interface EHRClaimSyncRequestResults {
  success: boolean;
  pagination?: { currentPage: number; perPage: number; totalCount: number; totalPages: number };
  claimSyncs: EHRClaimSyncRequest[];
}

export interface AperoClaimSyncResult {
  success: boolean;
  id: number;
  status: string;
}

export interface AperoAsyncClinicResult {
  success: boolean;
  message?: string;
  data?: {
    clinicId: number;
  };
  requestId: number;
}

export interface AperoSyncClinicsResponse {
  success: boolean;
  message?: string;
  clinics: AperoAsyncClinicResult[];
}

export interface DrchronoAppointment {
  id: number;
  patient: number;
}

export interface Scheduling {
  id: number;
}

export interface DrchronoCompleteAppointment {
  source: ClaimType;
  appointment: DrchronoAppointment;
}

export type EhrAppointment = DrchronoCompleteAppointment;

export interface Invoice {
  id: number;
}

export interface CoreLiteUser {
  id: number;
  firstName: string;
  lastName: string;
}

export type AperoInvoiceData = {
  submittedAt: string;
  withErrors: boolean;
  invoice: Invoice;
};

export enum EhrBillableLogEntryType {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}
export interface EhrClaimLogEntry {
  id: number;
  type: EhrBillableLogEntryType;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export interface EhrClaim {
  id: number;
  uuid: string;
  clinicId: number;
  serviceDate: string;
  status: ClaimStatus;
  source: ClaimType;
  ehrName: EhrName;
  ehrAppointmentData: EhrAppointment | null;
  coreAppointmentData: Scheduling | null;
  coreProvider: CoreLiteUser | null;
  corePatient: CoreLiteUser | null;
  aperoInvoiceData: AperoInvoiceData | null;
  ehrClaimLogs: EhrClaimLogEntry[];
}

export interface EhrClaimsResponse {
  success: boolean;
  message?: string;
  claims: EhrClaim[];
}

export interface EhrClaimResponse {
  success: boolean;
  message?: string;
  claim: EhrClaim | null;
}

export interface EhrClaimResyncPayload {
  forceEhrFetching?: boolean;
  enableSlackMessages?: boolean;
}

export interface RpmPatientSelectionFilters {
  clinicIds?: number[];
  icd10Codes?: string[];
  rpmStatuses?: string[];
  insuranceCompanyIds?: string[];
  cptCodes?: string[];
  optOutStatus?: string[];
  memberIds?: string[];
  page?: number;
  limit?: number;
}

export interface RpmCalculationFilters {
  clinicIds?: number[];
  insuranceCompanyIds?: string[];
  dateOfService?: string;
  placeOfService?: string[];
  cptCodes?: string[];
  memberUuids?: string[];
  page?: number;
  limit?: number;
}

export interface RpmExportedFilters {
  clinicId?: number[];
  insuranceCompany?: string[];
  dateOfService?: string;
  placeOfService?: string[];
  cptCodes?: string[];
  memberName?: string[];
  page?: number;
  limit?: number;
}
