import axios, { AxiosError } from 'axios';
import {
  EligibilityAllEntities,
  EligibilityTab,
  PlanNameOption,
  WithJoinFields,
} from '../components/eligibility/eligibility.types';
import { TaskView } from '../components/eligibility/tasks/tasks.types';
import {
  CreateOrUpdateEntityResponse,
  EditableInsurancePlanFields,
  EditableLeadFields,
  EditablePayerFields,
  EligibilityStatus,
  InsurancePlanPaginated,
  InsurancePlansPaginatedResponse,
  MembershipComputeByVersionPayload,
  MembershipComputeByVersionResponse,
  PVerifyEligibility,
  PaginatedEntities,
  PaginatedEntitiesResult,
  PaginationParams,
  Payer,
  PayerFilteredResponse,
  PayerPaginatedResponse,
  ProcessACEResponse,
} from '../interfaces/eligibility';
import { User } from '../interfaces/provider';
import { Roles } from '../util/constants';
import { AuthService } from './';
import { CommonService } from './common';

const ELIGIBILITY_BASE_URL = process.env.REACT_APP_PUBLIC_ELIGIBILITY_BASE_URL;
const DEFAULT_PER_PAGE = 30;

class EligibilityService {
  public async listPaginatedData<T extends Payer | InsurancePlanPaginated | TaskView>({
    perPage,
    page,
    resource,
    sortBy,
    order,
    filters,
  }: PaginationParams): Promise<PaginatedEntitiesResult<T> | null> {
    const auth = AuthService.getAuth();

    try {
      const { entities, totalCount } = (
        await axios.get<{ entities: T[]; totalCount: number }>(
          `${ELIGIBILITY_BASE_URL}/v1/${resource}`,
          {
            headers: { Authorization: `Bearer ${auth?.auth_token}` },
            params: {
              perPage,
              page,
              sortBy,
              order,
              filters: JSON.stringify(filters),
            },
          },
        )
      ).data;

      return {
        entities: entities.reduce(
          (allEntities, entity, index) => ({
            ...allEntities,
            [entity.id]: { ...entity, rowId: index + 1 },
          }),
          {} as PaginatedEntities<T>,
        ),
        totalCount,
      };
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  public async listInsurancesByQueryParams({
    payerId,
    clinicId,
  }: {
    payerId: string;
    clinicId: string;
  }): Promise<PlanNameOption[] | null> {
    const auth = AuthService.getAuth();

    try {
      const planNameOptions = (
        await axios.get<{ success: boolean; insurances: PlanNameOption[] }>(
          `${ELIGIBILITY_BASE_URL}/v1/entities/insurance-plans`,
          {
            headers: { Authorization: `Bearer ${auth?.auth_token}` },
            params: { payerId, clinicId },
          },
        )
      ).data;

      return planNameOptions.insurances;
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  public async getDetails<T>({
    id,
    resource,
  }: {
    id: string;
    resource: EligibilityTab;
  }): Promise<T | null> {
    const auth = AuthService.getAuth();

    try {
      const response = (
        await axios.get<T>(`${ELIGIBILITY_BASE_URL}/v1/${resource}/${id}`, {
          headers: { Authorization: `Bearer ${auth?.auth_token}` },
        })
      ).data;

      return response || null;
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  public async editDetails<T>({
    id,
    fields,
    resource,
  }: {
    id: string;
    fields:
      | EditablePayerFields
      | EditableLeadFields
      | Omit<EditableInsurancePlanFields, 'planCategory'>;
    resource: EligibilityTab;
  }): Promise<
    | CreateOrUpdateEntityResponse<T>
    | {
        success: boolean;
        message?: string;
      }
    | null
  > {
    const auth = AuthService.getAuth();

    try {
      return (
        await axios.put<CreateOrUpdateEntityResponse<T>>(
          `${ELIGIBILITY_BASE_URL}/v1/${resource}/${id}`,
          { ...fields, updatedBy: auth?.user_id },
          { headers: { Authorization: `Bearer ${auth?.auth_token}` } },
        )
      ).data;
    } catch (error) {
      console.error(error);

      const axiosError = error as AxiosError;
      const reason = axiosError.response?.data?.reason || axiosError.response?.data?.error;
      if (reason) {
        return {
          success: false,
          message: reason,
        };
      }
    }

    return null;
  }

  public async createRegister<T>({
    fields,
    resource,
  }: {
    fields: EditablePayerFields | EditableLeadFields | EditableInsurancePlanFields;
    resource: EligibilityTab;
  }): Promise<
    | CreateOrUpdateEntityResponse<T>
    | {
        success: boolean;
        message?: string;
      }
    | null
  > {
    const auth = AuthService.getAuth();

    try {
      const response = (
        await axios.post<CreateOrUpdateEntityResponse<T>>(
          `${ELIGIBILITY_BASE_URL}/v1/${resource}`,
          { ...fields, createdBy: auth?.user_id },
          { headers: { Authorization: `Bearer ${auth?.auth_token}` } },
        )
      ).data;

      return response;
    } catch (error) {
      console.error(error);

      const axiosError = error as AxiosError;
      const reason = axiosError.response?.data?.reason || axiosError.response?.data?.error;
      if (reason) {
        return {
          success: false,
          message: reason,
        };
      }
    }

    return null;
  }

  public async listData<T>({
    entity,
    filters,
  }: {
    entity: EligibilityAllEntities;
    filters?: WithJoinFields;
  }): Promise<{
    success: boolean;
    totalCount: number;
    entities: T[];
  } | null> {
    const auth = AuthService.getAuth();

    try {
      const response = (
        await axios.get<{
          success: boolean;
          totalCount: number;
          entities: T[];
        }>(`${ELIGIBILITY_BASE_URL}/v1/entities?entryType=${entity}`, {
          headers: { Authorization: `Bearer ${auth?.auth_token}` },
          params: {
            ...(filters && { filters: filters }),
          },
        })
      ).data;

      return response || null;
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  public async getEntityInfoByQueryParams<T>({
    property,
    value,
    entity,
  }: {
    property: string;
    value: string;
    entity: EligibilityAllEntities;
  }): Promise<T | null> {
    const auth = AuthService.getAuth();

    try {
      return (
        await axios.get<T>(
          `${ELIGIBILITY_BASE_URL}/v1.1/entities?entryType=${entity}&property=${property}&value=${value}`,
          { headers: { Authorization: `Bearer ${auth?.auth_token}` } },
        )
      ).data;
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  async getTaskOwners(): Promise<User[] | null> {
    try {
      const roles = await CommonService.getRoles();
      const roleFound = roles.find((role) => {
        return role.name === Roles.Billing;
      });

      if (!roleFound) {
        return null;
      }

      const resp = await CommonService.getUserByRole(roleFound.id);
      if (!resp?.success) {
        console.warn("Can't get task owners...");
        return null;
      }

      return resp.data;
    } catch (err) {
      console.error('getTaskOwners::err: ', err);
    }

    return null;
  }

  async getPVerifyInsuranceStatus({
    eligibilityData,
  }: {
    eligibilityData: PVerifyEligibility;
  }): Promise<EligibilityStatus | null> {
    const auth = AuthService.getAuth();
    const url = `${ELIGIBILITY_BASE_URL}/v1/insurances/status`;

    try {
      const response = await axios.post<EligibilityStatus>(url, eligibilityData, {
        headers: { Authorization: `Bearer ${auth?.auth_token}` },
      });

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async computeMembership(
    payload: MembershipComputeByVersionPayload,
  ): Promise<MembershipComputeByVersionResponse | null> {
    const auth = AuthService.getAuth();
    const url = `${ELIGIBILITY_BASE_URL}/v1/membership/compute`;

    try {
      const response = await axios.post<MembershipComputeByVersionResponse>(
        url,
        { ...payload },
        { headers: { Authorization: `Bearer ${auth?.auth_token}` } },
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  public async getPayerPaginated({
    perPage = DEFAULT_PER_PAGE,
    page,
    clinicId,
    filters,
  }: {
    clinicId?: string;
    page: number;
    perPage?: number;
    filters?: { name?: string; payerId?: string };
  }): Promise<Payer[]> {
    const auth = AuthService.getAuth();

    const response = (
      await axios.get<PayerPaginatedResponse>(`${ELIGIBILITY_BASE_URL}/v1/payers`, {
        params: {
          page,
          ...(perPage && { pageSize: perPage }),
          ...(clinicId && { clinicId }),
          ...(filters && { filters: JSON.stringify(filters) }),
        },
        headers: { Authorization: `Bearer ${auth?.auth_token}` },
      })
    ).data;

    if (!response?.success) {
      return [];
    }

    return response.entities;
  }

  public async getInsurancePlansPaginated({
    perPage = DEFAULT_PER_PAGE,
    page,
    filters,
  }: {
    page: number;
    perPage?: number;
    filters?: {
      payerId?: string;
      clinicId?: string;
      groupId?: string;
      payerName?: string;
    };
  }): Promise<InsurancePlansPaginatedResponse> {
    const auth = AuthService.getAuth();

    const config = {
      params: {
        page,
        ...(perPage && { pageSize: perPage }),
        ...(filters && { filters: JSON.stringify(filters) }),
      },
      headers: { Authorization: `Bearer ${auth?.auth_token}` },
    };

    const response = (
      await axios.get<InsurancePlansPaginatedResponse>(
        `${ELIGIBILITY_BASE_URL}/v1/insurance-plans`,
        config,
      )
    ).data;

    return response;
  }

  public async processACECalendars({
    cookie,
    content,
    clinicId,
  }: {
    cookie: string;
    content: string;
    clinicId: string;
  }): Promise<ProcessACEResponse | null> {
    const auth = AuthService.getAuth();
    const url = `${ELIGIBILITY_BASE_URL}/v1/ace/calendars`;

    try {
      const response = await axios.post<ProcessACEResponse>(
        url,
        { cookie, calendarRaw: content, clinicId },
        { headers: { Authorization: `Bearer ${auth?.auth_token}` } },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getPayers({ filter }: { filter?: string | null }) {
    const auth = AuthService.getAuth();
    const url = `${ELIGIBILITY_BASE_URL}/v2/payers`;

    try {
      const response = await axios.get<PayerFilteredResponse>(url, {
        params: {
          ...(filter && { filter }),
        },
        headers: { Authorization: `Bearer ${auth?.auth_token}` },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
}

const eligibilityService = new EligibilityService();

export default eligibilityService;
