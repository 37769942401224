import { Box, Button, Chip, Snackbar, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { EHRClaimSyncRequestResults, EHRClaimSyncStatus } from '../../interfaces/billing';
import { BillingService } from '../../services';
import { DEFAULT_PAGE_SIZE } from '../../util/constants';
import { getCapitalizedWord } from '../../util/stringFormat';
import { CustomTable } from '../common/CustomTable';
import './claim-sync-requests.css';

interface Props {
  onClose: () => void;
}

export const ClaimSyncRequests = ({ onClose }: Props) => {
  const [syncRequestsResult, setSyncRequestsResult] = useState<EHRClaimSyncRequestResults | null>(
    null,
  );
  const [message, setMessage] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchClaimSyncRequests = useCallback(async () => {
    setIsLoading(true);
    const response = await new BillingService().getClaimSyncRequests({
      page,
      perPage: DEFAULT_PAGE_SIZE,
    });
    setIsLoading(false);

    if (!response) {
      setMessage('There is a problem fetching sync requests');
      return;
    }
    setSyncRequestsResult(response);
  }, [page]);

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleRefresh = async () => {
    await fetchClaimSyncRequests();
  };

  const resolveColor = (status: EHRClaimSyncStatus) => {
    switch (status) {
      case EHRClaimSyncStatus.Completed:
        return '#1A667A';

      case EHRClaimSyncStatus.InProgress:
        return 'rgba(156, 39, 176, 0.992)';

      case EHRClaimSyncStatus.Stopped:
      case EHRClaimSyncStatus.CompletedWithErrors:
        return 'rgb(219, 108, 87)';

      default:
        return 'rgb(44, 62, 80)';
    }
  };

  useEffect(() => {
    (async () => await fetchClaimSyncRequests())();
  }, [page, fetchClaimSyncRequests]);

  return (
    <>
      <Box className={`claim-sync-requests-section`}>
        <Box className='header'>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography>Claim Sync Requests</Typography>
            &nbsp;
            <Button
              variant='outlined'
              color='secondary'
              size='small'
              onClick={handleRefresh}
              sx={{ textDecoration: 'underline' }}>
              Refresh
            </Button>
          </Box>

          <Button variant='contained' onClick={onClose}>
            Close
          </Button>
        </Box>

        <Box className='table-wrapper'>
          <CustomTable
            columns={[
              { key: 'id', label: 'ID' },
              { key: 'status', label: 'Status' },
              { key: 'clinicId', label: 'Clinic ID', minWidth: '90px' },
              { key: 'fromDate', label: 'From' },
              { key: 'untilDate', label: 'Until' },
              { key: 'requested', label: 'N Requested' },
              { key: 'uploaded', label: 'N Uploaded' },
              { key: 'createdAt', label: 'Created at', minWidth: '150px' },
            ]}
            defaultText={isLoading ? 'Loading requests...' : 'No requests'}
            key={'claim-sync-requests-table'}
            rows={
              isLoading
                ? []
                : (syncRequestsResult ? syncRequestsResult.claimSyncs : []).map((data) => {
                    return {
                      id: `#${data.id}`,
                      status: (
                        <Chip
                          label={getCapitalizedWord(data.status)}
                          sx={{
                            color: 'white',
                            backgroundColor: resolveColor(data.status),
                          }}
                        />
                      ),
                      clinicId: data.clinicId,
                      fromDate: data.fromDate.slice(0, 10),
                      untilDate: data.untilDate.slice(0, 10),
                      requested: data.requested,
                      uploaded: data.uploaded,
                      createdAt: data.createdAt.slice(0, 16).split('T').join(' '),
                    };
                  })
            }
            pagination={{
              rowsPerPageOptions: [DEFAULT_PAGE_SIZE],
              totalCount:
                syncRequestsResult?.pagination?.totalCount ||
                syncRequestsResult?.claimSyncs.length ||
                0,
              rowPerPage: DEFAULT_PAGE_SIZE,
              page,
              onPageChange: handleChangePage,
            }}
          />
        </Box>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!message}
        autoHideDuration={10000}
        onClose={() => setMessage('')}
        message={message}
        ContentProps={{
          style: { marginTop: '50px' },
        }}
      />
    </>
  );
};
