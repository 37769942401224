import { Box, Link, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CompactTable } from '../../common/CompactTable/CompactTable';
import { Column } from '../../common/CustomTable';
import { CodeChipList } from '../common/CodeChipList';
import { RpmLoading } from '../common/RpmLoading';
import { Claim, Filters } from '../types/rpms.types';

// Mock Data
const mockData: Claim[] = Array.from({ length: 15 }, (_, index) => ({
  id: 40600 + index,
  name: `Patient ${index + 1}`,
  clinic: `Clinic ${index % 3}`,
  insurance: `Insurance ${index % 2}`,
  claimId: index % 2 === 0 ? '324123' : 'Failed',
  pos: 11,
  cptCodes: ['99454', '99457', '90091', '99458'],
  note: 'View Note',
  amount: parseFloat((Math.random() * 200).toFixed(2)),
  issues: index % 2 === 0 ? [] : ["⚠️ 99019: Can't be billed along with 99457"],
  dos: '12/31/1990',
  noteId: 12345,
  userId: 12345,
  status: index % 2 === 0 ? 'success' : 'failed',
}));

interface RPMExportResultsTableProps {
  filters: Filters;
  isLoading?: boolean;
}

export const RPMExportResultsTable: React.FC<RPMExportResultsTableProps> = ({
  filters,
  isLoading = false,
}) => {
  const [dataClaims, setDataClaims] = useState<Claim[]>(mockData);
  const [filteredData, setFilteredData] = useState<Claim[]>(mockData);
  const [paginatedData, setPaginatedData] = useState<Claim[]>([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Data Fetching
  useEffect(() => {
    const fetchData = async () => {
      try {
        setDataClaims(mockData as Claim[]);
      } catch (error) {
        console.error('Error fetching claims:', error);
      }
    };

    fetchData();
  }, []);

  // Pagination
  useEffect(() => {
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    setPaginatedData(filteredData.slice(startIndex, endIndex));
  }, [filteredData, page, rowsPerPage]);

  // Pagination Handlers
  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(newRowsPerPage);
      const maxPage = Math.ceil(filteredData.length / newRowsPerPage);
      if (page > maxPage) {
        setPage(maxPage || 1);
      } else {
        setPage(1);
      }
    },
    [filteredData.length, page],
  );

  const columnGroups = useMemo(
    () => [
      {
        id: 'claims',
        title: 'Calculated claims for the period',
        startColumn: 4,
        colspan: 6,
      },
    ],
    [],
  );

  // Columns Configuration
  const columns: Column[] = useMemo(
    () => [
      {
        key: 'id',
        label: 'ID',
      },
      {
        key: 'name',
        label: 'Name',
      },
      {
        key: 'clinic',
        label: 'Clinic',
      },
      {
        key: 'insurance',
        label: 'Insurance',
      },
      {
        key: 'claimId',
        label: 'Claim ID',
      },
      {
        key: 'dos',
        label: 'Date of Service',
      },
      {
        key: 'pos',
        label: 'POS',
      },
      {
        key: 'cptCodes',
        label: 'CPT Codes',
        width: 150,
      },
      {
        key: 'note',
        label: 'Note',
      },
      {
        key: 'amount',
        label: 'Amount',
      },
      {
        key: 'issues',
        label: 'Issues',
      },
    ],
    [],
  );

  const tableRows = useMemo(
    () =>
      paginatedData.map((row) => ({
        id: (
          <Link href='#' target='_blank' rel='noopener noreferrer'>
            {row.userId}
          </Link>
        ),
        name: row.name,
        clinic: row.clinic,
        insurance: row.insurance,
        claimId:
          row.status === 'success' ? (
            <Link href='#' target='_blank' rel='noopener noreferrer'>
              {row.id}
            </Link>
          ) : (
            <Tooltip title='Claim failed' arrow>
              <Typography color='error' sx={{ cursor: 'pointer' }}>
                {row.id}
              </Typography>
            </Tooltip>
          ),
        dos: row.dos,
        pos: row.pos,
        cptCodes: <CodeChipList codes={row.cptCodes} />,
        note: (
          <Link href='#' target='_blank' rel='noopener noreferrer'>
            {row?.noteId}
          </Link>
        ),
        amount: row.amount ?? '',
        issues: row.issues,
      })),
    [paginatedData],
  );

  return (
    <Box position='relative'>
      {isLoading && <RpmLoading />}
      <CompactTable
        columns={columns}
        rows={tableRows}
        defaultText='No export results found'
        columnGroups={columnGroups}
        pagination={{
          rowsPerPageOptions: [5, 10, 15],
          rowPerPage: rowsPerPage,
          page,
          totalCount: filteredData.length,
          onPageChange: handlePageChange,
          onRowsPerPageChange: handleChangeRowsPerPage,
        }}
      />
    </Box>
  );
};
