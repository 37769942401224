import { Theme } from '@mui/material';

export const customTabStyles = (theme: Theme) => ({
  borderBottom: '1px solid #d3d3d3',
  '& .MuiTab-root': {
    color: '#757575',
    '&.Mui-selected': {
      color: '#1a667a',
    },
    minHeight: '20px',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#1a667a',
  },
});
