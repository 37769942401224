import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import './options-menu.css';

type Props = {
  variant?: 'text' | 'contained' | 'outlined';
  options: {
    label: string;
    icon?: React.ReactNode;
    onClick: () => void;
  }[];
};

export const OptionsMenu = ({ options, ...props }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id='actions-menu-button'
        variant={props.variant || 'contained'}
        disableElevation
        onClick={handleClick}
        sx={{
          textTransform: 'capitalize',
        }}
        endIcon={<KeyboardArrowDownIcon />}>
        Options
      </Button>
      <Menu
        id='actions-menu'
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {options.map((op) => (
          <MenuItem
            onClick={() => {
              op.onClick();
              handleClose();
            }}
            disableRipple>
            {op.icon}
            {op.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
