import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { CreateMedicationPayload, MedicationStatus } from '../../../../../interfaces/health-data';
import { getCapitalizedWord } from '../../../../../util/stringFormat';
import './medication.css';

type Props = {
  medication?: CreateMedicationPayload;
  onChangeForm: (v: CreateMedicationPayload) => void;
};

export const MedicationForm = ({ medication, onChangeForm }: Props) => {
  const [medicationForm, setMedicationForm] = useState<CreateMedicationPayload>({
    conceptName: '',
    ndcCode: '',
    rxCuiCode: '',
    status: MedicationStatus.ACTIVE,
  });

  const handleChangeForm = (e: SelectChangeEvent<string> | ChangeEvent<Element>) => {
    const { value, name } = e.target as {
      value: string;
      name: string;
    };

    setMedicationForm({ ...medicationForm, [name]: value });
  };

  useEffect(() => {
    onChangeForm(medicationForm);
  }, [medicationForm]);

  useEffect(() => {
    if (medication) {
      setMedicationForm(medication);
    }
  }, [medication]);

  return (
    <Box>
      <Box className='tab-section conditions-section'>
        <Box className='tab-section-form-field medication-form-medication-section'>
          <Typography variant='body2'>Status:</Typography>
          <FormControl sx={{ minWidth: 120 }} size='small' key='form-control-medication-status'>
            <Select name='status' value={medicationForm.status} onChange={handleChangeForm}>
              {Object.entries(MedicationStatus).map(([label, value], index) => {
                return (
                  <MenuItem value={value} key={`item-medication-status-${index}`}>
                    {getCapitalizedWord(label)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <Box className='tab-section-form-field medication-form-medication-section'>
          <Typography variant='body2'>Name:</Typography>
          <TextField
            key={'medication-text-field-name'}
            size='small'
            placeholder='Write a concept name'
            value={medicationForm.conceptName}
            name='name'
            onChange={handleChangeForm}
          />
        </Box>

        <Box className='tab-section-form-field medication-form-medication-section'>
          <Typography variant='body2'>NDC Code:</Typography>
          <TextField
            key={'medication-text-field-ndc-code'}
            name='ndcCode'
            size='small'
            placeholder='Write a NDC Code'
            value={medicationForm.ndcCode}
            onChange={handleChangeForm}
          />
        </Box>

        <Box className='tab-section-form-field medication-form-medication-section'>
          <Typography variant='body2'>RxCUI Code:</Typography>
          <TextField
            key={'medication-text-field-rx-cui-code'}
            name='rxCuiCode'
            size='small'
            placeholder='Write a RxCUI Code'
            value={medicationForm.rxCuiCode}
            onChange={handleChangeForm}
          />
        </Box>
      </Box>
    </Box>
  );
};
