import { useEffect, useState } from 'react';
import { Admin, Resource } from 'react-admin';
import authProvider from './authProvider';
import Eligibility from './components/eligibility/Eligibility';
import { CustomLayout } from './config/CustomLayout';
import { customRoutes } from './config/customRoutes';
import dataProvider from './dataProvider';
import featuresClient, { FeaturesName } from './lib/features';
import { AuthService } from './services/auth';
import { theme } from './styles/theme';
import { Roles } from './util/constants';

const dataProviderLive = dataProvider();

const App = () => {
  const [shouldShow, setShouldShow] = useState<{
    patientLabResults: boolean;
    medicalSetup: boolean;
    patientConditions: boolean;
    auditLogs: boolean;
  }>({ patientLabResults: false, medicalSetup: false, patientConditions: false, auditLogs: false });

  useEffect(() => {
    (async () => {
      const [patientLabResults, medicalSetup, patientConditions, auditLogs] =
        await featuresClient.fetchFeatureFlagsStatus([
          { name: FeaturesName.OpsDashShowPatientsLabResults },
          { name: FeaturesName.OpsDashShowMedicalSetup },
          { name: FeaturesName.OpsDashShowPatientConditions },
          { name: FeaturesName.OpsDashShowAuditLogs },
        ]);

      setShouldShow({ patientLabResults, medicalSetup, patientConditions, auditLogs });
    })();
  }, []);

  return (
    <Admin
      authProvider={authProvider}
      customRoutes={customRoutes(
        shouldShow.patientLabResults,
        shouldShow.medicalSetup,
        shouldShow.patientConditions,
        shouldShow.auditLogs,
      )}
      dataProvider={dataProviderLive}
      disableTelemetry
      layout={CustomLayout}
      theme={theme}>
      {() => [
        AuthService.checkRoleAccess(Roles.Billing) && [
          <Resource name='eligibility' list={Eligibility} />,
        ],
      ]}
    </Admin>
  );
};

export default App;
