import { Box, Button, Chip, Snackbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRedirect } from 'react-admin';
import {
  ConditionStatus,
  FindAllConditionsResponse,
  Pagination,
} from '../../../../interfaces/health-data';
import healthDataService from '../../../../services/health-data';
import { DEFAULT_PAGE_SIZE, INITIAL_PAGINATION_STATE } from '../../../../util/constants';
import { getCapitalizedWord } from '../../../../util/stringFormat';
import { CustomTable } from '../../../common/CustomTable';
import './css/conditions.css';

export const Conditions = () => {
  const [conditions, setConditions] = useState<FindAllConditionsResponse['conditions']>([]);
  const [message, setMessage] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);

  const [pagination, setPagination] = useState<Pagination>(INITIAL_PAGINATION_STATE);

  const redirect = useRedirect();

  const fetchConditions = async () => {
    const response = await healthDataService.findAllConditions({
      status: ConditionStatus.ACTIVE,
      page,
      pageSize: DEFAULT_PAGE_SIZE,
    });

    if (!response?.data) {
      setMessage(response.message || 'There is a problem fetching conditions');
      return;
    }
    setPagination(response.data.pagination);
    setConditions(response.data.conditions);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const formatWithTotalCount = (names: string[], totalCount: number) => {
    if (names.length === 0) {
      return [];
    }

    return (
      <Box display='flex' alignItems='center' maxWidth='110px'>
        <Chip
          label={names[0]}
          size='small'
          sx={{
            marginRight: '1em',
            display: 'inline-block',
            alignContent: 'center',
            fontSize: '11px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            height: '1.7rem',
          }}
        />
        <Typography fontSize='10px'>{totalCount > 1 ? `+${totalCount - 1}` : ''}</Typography>
      </Box>
    );
  };

  useEffect(() => {
    (async () => await fetchConditions())();
  }, [page]);

  return (
    <>
      <Box className='tab-section conditions-section' marginTop={0}>
        <Box className='conditions-header'>
          <Typography>Conditions</Typography>
          <Button
            variant='contained'
            sx={{ fontSize: '11px', padding: '7px 8px' }}
            onClick={() => redirect(`/medical-setup/conditions/create`)}
            size='small'>
            Add Condition
          </Button>
        </Box>

        <CustomTable
          columns={[
            { key: 'status', label: 'Status' },
            { key: 'name', label: 'Name' },
            { key: 'icd10Code', label: 'ICD-10 Code' },
            { key: 'procedures', label: 'Procedures' },
            { key: 'laboratoryTests', label: 'Laboratory Tests' },
            { key: 'medications', label: 'Medications' },
            { key: 'billingStatus', label: 'For Billing' },
            { key: 'actions', label: 'Actions', minWidth: '130px' },
          ]}
          defaultText='No conditions created'
          key={'conditions-table'}
          rows={conditions.map((data) => {
            const laboratoryTestWithTotalCount = formatWithTotalCount(
              data.laboratoryTests.map((m) => m.laboratoryTest.name),
              data.laboratoryTests.length,
            );

            const medicationWithTotalCount = formatWithTotalCount(
              data.medications.map((m) => m.medication.conceptName),
              data.medications.length,
            );

            const proceduresWithTotalCount = formatWithTotalCount(
              data.procedures.map((m) => m.procedure.name),
              data.procedures.length,
            );

            return {
              status: (
                <Chip
                  size='small'
                  label={getCapitalizedWord(data.condition.status)}
                  sx={{
                    fontSize: '11px',
                    color: 'white',
                    backgroundColor:
                      data.condition.status === ConditionStatus.ACTIVE ? '#1FAF84' : '#FF4A47',
                  }}
                />
              ),
              name: data.condition.name,
              icd10Code: data.condition.icd10Code,
              includedInPvq: data.condition.includedInPvq,
              procedures: proceduresWithTotalCount,
              laboratoryTests: laboratoryTestWithTotalCount,
              medications: medicationWithTotalCount,
              billingStatus: (
                <Chip
                  size='small'
                  sx={{ fontSize: '11px' }}
                  label={data.condition.billingCriteria.status}
                />
              ),
              actions: [
                <Button
                  size='small'
                  variant='text'
                  onClick={() => redirect(`/medical-setup/conditions/${data?.condition.id}/show`)}
                  sx={{
                    textTransform: 'capitalize',
                  }}>
                  View
                </Button>,
                <Button
                  size='small'
                  variant='text'
                  onClick={() => redirect(`/medical-setup/conditions/${data?.condition.id}/edit`)}
                  sx={{
                    textTransform: 'capitalize',
                  }}>
                  Edit
                </Button>,
              ],
            };
          })}
          pagination={{
            rowsPerPageOptions: [DEFAULT_PAGE_SIZE],
            rowPerPage: pagination.perPage,
            page: pagination.currentPage,
            totalCount: pagination.totalCount,
            onPageChange: handleChangePage,
          }}
        />
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!message}
        autoHideDuration={10000}
        onClose={() => setMessage('')}
        message={message}
        ContentProps={{
          style: { marginTop: '50px' },
        }}
      />
    </>
  );
};
