import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { LaboratoryTest } from '../../../../interfaces/health-data';
import healthDataService from '../../../../services/health-data';
import { getCapitalizedWord } from '../../../../util/stringFormat';
import { LoaderBubble } from '../../../common/LoaderBubble/LoaderBubble';

export const ViewLaboratoryTest = () => {
  const [laboratoryTest, setLaboratoryTest] = useState<LaboratoryTest | null>(null);
  const [breadcrumbLabels, setBreadcrumbLabels] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { laboratoryTestId } = useParams<{ laboratoryTestId: string }>();
  const redirect = useRedirect();
  const notify = useNotify();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await healthDataService.getLaboratoryTest(Number(laboratoryTestId));

      if (!response.data) {
        notify(response.message || 'There was an error obtaining the laboratory test', 'warning');
        setIsLoading(false);
        return;
      }

      setBreadcrumbLabels(['laboratoryTests', response.data.name || 'Unknown']);
      setLaboratoryTest(response.data);
      setIsLoading(false);
    })();
  }, [laboratoryTestId, notify]);

  return (
    <>
      {laboratoryTest && !isLoading ? (
        <Box>
          <Box display='flex' justifyContent='space-between' className='tab-section-action-bar'>
            <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
              {breadcrumbLabels.map((text, index) => {
                return (
                  <Typography
                    key={`text-laboratory-test-breadcrumb-${index}`}
                    textTransform='capitalize'>
                    {text}
                  </Typography>
                );
              })}
            </Breadcrumbs>

            <Button
              onClick={() => redirect(`/medical-setup/laboratory-tests/${laboratoryTestId}/edit`)}
              variant='contained'
              sx={{ textTransform: 'capitalize' }}>
              Edit
            </Button>
          </Box>

          <Box className='tab-section laboratory-test-section'>
            <Box display='flex' flexDirection='row' mb={1}>
              <Typography mr={1}>Status:</Typography>
              <Typography textTransform='capitalize' fontWeight={300}>
                {getCapitalizedWord(laboratoryTest.status)}
              </Typography>
            </Box>

            <Box display='flex' flexDirection='row' mb={1}>
              <Typography mr={1}>Name: </Typography>
              <Typography fontWeight={300}>{laboratoryTest.name}</Typography>
            </Box>

            <Box display='flex' flexDirection='row' mb={1}>
              <Typography mr={1}>LOINC Code: </Typography>
              <Typography fontWeight={300}>{laboratoryTest.loincCode}</Typography>
            </Box>

            <Box display='flex' flexDirection='row' mb={1}>
              <Typography mr={1}>Units: </Typography>
              <Typography fontWeight={300}>
                {laboratoryTest.units.join(', ') || 'No Units'}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='100%'
          width='100%'
          flexDirection='column'>
          <Typography mb={3}>Wait a few seconds...</Typography>
          <LoaderBubble />
        </Box>
      )}
    </>
  );
};
