import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Button, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { LaboratoryTest, UpdateLaboratoryTestPayload } from '../../../../interfaces/health-data';
import healthDataService from '../../../../services/health-data';
import { LoaderBubble } from '../../../common/LoaderBubble/LoaderBubble';
import { preloadLaboratoryTests } from '../../../patient/laboratory-results/Form/LaboratoryTestSelector';
import { LaboratoryTestForm } from './LaboratoryTestForm/LaboratoryTestForm';

export const EditLaboratoryTest = () => {
  const [laboratoryTest, setLaboratoryTest] = useState<LaboratoryTest | null>(null);
  const [breadcrumbLabels, setBreadcrumbLabels] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [form, setForm] = useState<UpdateLaboratoryTestPayload | null>(null);

  const { laboratoryTestId } = useParams<{ laboratoryTestId: string }>();
  const redirect = useRedirect();

  const notify = useNotify();

  const handleUpdateForm = useCallback(
    ({ name, loincCode, status, units }: Partial<LaboratoryTest>) =>
      setForm({ name, loincCode, status, units }),
    [],
  );

  const handleSubmit = async () => {
    if (!form || !laboratoryTest?.id) {
      return;
    }

    const result = await healthDataService.updateLaboratoryTest(laboratoryTest.id, form);

    if (!result) {
      notify('There was an error updating the laboratory test', 'warning');
      return;
    }

    // Renew laboratory tests as they were updated recently
    preloadLaboratoryTests({ force: true });

    notify('Laboratory test updated successfully');
    redirect(`/medical-setup/laboratory-tests/${laboratoryTestId}/show`);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await healthDataService.getLaboratoryTest(Number(laboratoryTestId));

      if (!response.data) {
        notify(response.message || 'There was an error obtaining the laboratory test', 'warning');
        setIsLoading(false);
        return;
      }

      setBreadcrumbLabels(['laboratoryTests', response.data.name || 'Unknown']);
      setLaboratoryTest(response.data);
      setIsLoading(false);
    })();
  }, [laboratoryTestId, notify]);

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' className='tab-section-action-bar'>
        <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
          {breadcrumbLabels.map((text, index) => {
            return (
              <Typography
                key={`text-laboratory-test-breadcrumb-${index}`}
                textTransform='capitalize'>
                {text}
              </Typography>
            );
          })}
        </Breadcrumbs>

        <Button
          onClick={handleSubmit}
          disabled={!form || form.units?.length === 0}
          variant='contained'
          sx={{ textTransform: 'capitalize' }}>
          Save
        </Button>
      </Box>

      {laboratoryTest && !isLoading ? (
        <LaboratoryTestForm onChangeForm={handleUpdateForm} laboratoryTest={laboratoryTest} />
      ) : (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='100%'
          width='100%'
          flexDirection='column'>
          <Typography mb={3}>Wait a few seconds...</Typography>
          <LoaderBubble />
        </Box>
      )}
    </Box>
  );
};
