import { Title } from 'react-admin';
import { TabsComponent } from './common/TabsComponent';

export const RPMs: React.FC = () => {
  return (
    <>
      <Title title='RPMs' />
      <TabsComponent />
    </>
  );
};
