import { Box, Breadcrumbs, Button, Typography } from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useEffect, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Medication, UpdateMedicationPayload } from '../../../../interfaces/health-data';
import { LoaderBubble } from '../../../common/LoaderBubble/LoaderBubble';
import { MedicationForm } from './MedicationForm/MedicationForm';

export const EditMedication = () => {
  const [medication, setMedication] = useState<Medication | null>(null);
  const [breadcrumbLabels, setBreadcrumbLabels] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [form, setForm] = useState<UpdateMedicationPayload | null>(null);

  const notify = useNotify();
  const { medicationId } = useParams<{ medicationId: string }>();
  const redirect = useRedirect();

  const handleUpdateForm = ({ ndcCode, rxCuiCode, status, conceptName }: UpdateMedicationPayload) =>
    setForm({ ndcCode, status, rxCuiCode, conceptName });

  const handleSubmit = async () => {
    if (!form) {
      return;
    }

    // TODO: Uncomment this line when implementation is done
    // const result = await healthDataService.updateMedication(form);

    const result = null;

    if (!result) {
      notify('There was an error updating the medication', 'warning');
      return;
    }

    notify('Medication updated successfully');
    //redirect(`/medical-setup/medications/${result.id}/show`);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      // TODO: Uncomment lines when implementation is done
      // const medication = await healthDataService.getMedication(Number(medicationId));
      // setBreadcrumbLabels(['medications', medication?.name || 'Unknown']);
      setMedication(medication);
      setIsLoading(false);
    })();
  }, [medicationId]);

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' className='tab-section-action-bar'>
        <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
          {breadcrumbLabels.map((text, index) => {
            return (
              <Typography key={`text-medication-breadcrumb-${index}`} textTransform='capitalize'>
                {text}
              </Typography>
            );
          })}
        </Breadcrumbs>

        <Button
          onClick={handleSubmit}
          disabled={!form}
          variant='contained'
          sx={{ textTransform: 'capitalize' }}>
          Save
        </Button>
      </Box>

      {medication && !isLoading ? (
        <MedicationForm onChangeForm={handleUpdateForm} medication={medication} />
      ) : (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='100%'
          width='100%'
          flexDirection='column'>
          <Typography mb={3}>Wait a few seconds...</Typography>
          <LoaderBubble />
        </Box>
      )}
    </Box>
  );
};
