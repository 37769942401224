import axios, { AxiosInstance } from 'axios';
import { AuditLog, AuditLogSource } from '../interfaces/audit-log';
import { ApiResponse } from '../interfaces/common';
import {
  Condition,
  ConditionWithRelations,
  CreateConditionPayload,
  CreateConditionResponse,
  CreateLaboratoryTestPayload,
  CreatePatientLaboratoryResultPayload,
  CreateProcedurePayload,
  FindAllAuditLogsByUser,
  FindAllConditionsParams,
  FindAllConditionsResponse,
  FindAllLaboratoryResultsResponse,
  FindConditionsRelatedToPatientResponse,
  LaboratoryTest,
  LaboratoryTestsResponse,
  LaboratoryTestStatus,
  MedicationsResponse,
  MedicationStatus,
  PatientConditionStatus,
  Procedure,
  ProceduresResponse,
  ProcedureStatus,
  retrieveConditionsByPatientResponse,
  UpdateConditionPayload,
  UpdateLaboratoryTestPayload,
  UpdatePatientConditionsPayload,
  UpdatePatientConditionsResponse,
  UpdatePatientLaboratoryResultPayload,
  UpdateProcedurePayload,
} from '../interfaces/health-data';
import { DEFAULT_PAGE_SIZE } from '../util/constants';
import { AuthService } from './';

const HEALTH_DATA_BASE_URL = process.env.REACT_APP_PUBLIC_HEALTH_DATA_BASE_URL;

class HealthDataService {
  private axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({
      baseURL: HEALTH_DATA_BASE_URL,
      headers: {
        Authorization: `Bearer ${AuthService.getAuth()?.auth_token}`,
      },
    });
  }

  public async findAllConditions(
    params: FindAllConditionsParams = {},
  ): Promise<ApiResponse<FindAllConditionsResponse>> {
    try {
      const response = (
        await this.axios.get<ApiResponse<FindAllConditionsResponse>>(`/medical-setup/conditions`, {
          params,
        })
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as FindAllConditionsResponse,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async createCondition(
    payload: CreateConditionPayload,
  ): Promise<ApiResponse<CreateConditionResponse>> {
    try {
      const response = (
        await this.axios.post<ApiResponse<CreateConditionResponse>>(
          `/medical-setup/conditions`,
          payload,
        )
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as CreateConditionResponse,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async getCondition(conditionId: number): Promise<ApiResponse<ConditionWithRelations>> {
    try {
      const response = (
        await this.axios.get<ApiResponse<ConditionWithRelations>>(
          `/medical-setup/conditions/${conditionId}`,
        )
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as ConditionWithRelations,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async updateCondition(
    conditionId: number,
    payload: UpdateConditionPayload,
  ): Promise<ApiResponse<Condition>> {
    try {
      const response = (
        await this.axios.put<ApiResponse<Condition>>(
          `/medical-setup/conditions/${conditionId}`,
          payload,
        )
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as Condition,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async findAllProceduresByParams(params: {
    name?: string;
    cptCode?: string;
    status?: ProcedureStatus;
    page?: number;
    pageSize?: number;
  }): Promise<ApiResponse<ProceduresResponse>> {
    try {
      const response = (
        await this.axios.get<ApiResponse<ProceduresResponse>>(`/medical-setup/procedures`, {
          params,
        })
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as ProceduresResponse,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async createProcedure(payload: CreateProcedurePayload): Promise<ApiResponse<Procedure>> {
    try {
      const response = (
        await this.axios.post<ApiResponse<Procedure>>(`/medical-setup/procedures`, payload)
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as Procedure,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async getProcedure(procedureId: number): Promise<ApiResponse<Procedure>> {
    try {
      const response = (
        await this.axios.get<ApiResponse<Procedure>>(`/medical-setup/procedures/${procedureId}`)
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as Procedure,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async updateProcedure(
    procedureId: number,
    payload: UpdateProcedurePayload,
  ): Promise<ApiResponse<Procedure>> {
    try {
      const response = (
        await this.axios.put<ApiResponse<Procedure>>(
          `/medical-setup/procedures/${procedureId}`,
          payload,
        )
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as Procedure,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async findAllLaboratoryTestsByParams(params: {
    name?: string;
    loincCode?: string;
    status?: LaboratoryTestStatus;
    page?: number;
    pageSize?: number;
  }): Promise<ApiResponse<LaboratoryTestsResponse>> {
    try {
      const response = (
        await this.axios.get<ApiResponse<LaboratoryTestsResponse>>(
          `/medical-setup/laboratory-tests`,
          {
            params,
          },
        )
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as LaboratoryTestsResponse,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async createLaboratoryTest(
    payload: CreateLaboratoryTestPayload,
  ): Promise<ApiResponse<LaboratoryTest>> {
    try {
      const response = (
        await this.axios.post<ApiResponse<LaboratoryTest>>(
          `/medical-setup/laboratory-tests`,
          payload,
        )
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as LaboratoryTest,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async getLaboratoryTest(laboratoryId: number): Promise<ApiResponse<LaboratoryTest>> {
    try {
      const response = (
        await this.axios.get<ApiResponse<LaboratoryTest>>(
          `/medical-setup/laboratory-tests/${laboratoryId}`,
        )
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as LaboratoryTest,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async updateLaboratoryTest(
    laboratoryTestId: number,
    payload: UpdateLaboratoryTestPayload,
  ): Promise<ApiResponse<LaboratoryTest>> {
    try {
      const response = (
        await this.axios.put<ApiResponse<LaboratoryTest>>(
          `/medical-setup/laboratory-tests/${laboratoryTestId}`,
          payload,
        )
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as LaboratoryTest,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async findAllMedicationsByParams(params: {
    conceptName?: string;
    ndcCode?: string;
    rxCuiCode?: string;
    status?: MedicationStatus;
    page?: number;
    pageSize?: number;
  }): Promise<ApiResponse<MedicationsResponse>> {
    try {
      const response = (
        await this.axios.get<ApiResponse<MedicationsResponse>>(`/medical-setup/medications`, {
          params,
        })
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as MedicationsResponse,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async findAllPatientLaboratoryResults({
    patientName,
    page,
  }: {
    patientName?: string;
    page: number;
  }): Promise<ApiResponse<FindAllLaboratoryResultsResponse>> {
    try {
      const response = (
        await this.axios.get<ApiResponse<FindAllLaboratoryResultsResponse>>(
          '/patients/laboratory-results',
          {
            params: { searchString: patientName, page, pageSize: DEFAULT_PAGE_SIZE },
          },
        )
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as FindAllLaboratoryResultsResponse,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async createLaboratoryResult(
    payload: CreatePatientLaboratoryResultPayload,
  ): Promise<{ labResultGroupId: string } | null> {
    try {
      const response = await this.axios.post<{ labResultGroupId: string }>(
        '/patients/laboratory-results',
        payload,
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  public async editPatientLaboratoryResult(
    groupId: string,
    payload: UpdatePatientLaboratoryResultPayload,
  ): Promise<ApiResponse<boolean>> {
    try {
      const response = (
        await this.axios.put<ApiResponse<boolean>>(
          `/patients/laboratory-results/groups/${groupId}`,
          payload,
        )
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as boolean,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async retrieveConditionsByPatient({
    params,
    page,
  }: {
    params?: {
      patientSearchString?: string;

      status: PatientConditionStatus;
    };
    page: number;
  }): Promise<ApiResponse<retrieveConditionsByPatientResponse>> {
    try {
      const response = (
        await this.axios.get<ApiResponse<retrieveConditionsByPatientResponse>>(
          `/patients/conditions`,
          {
            params: { page, pageSize: DEFAULT_PAGE_SIZE, ...params },
          },
        )
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as retrieveConditionsByPatientResponse,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async fetchConditionsAssociatedWithPatient({
    patientId,
    status,
  }: {
    patientId: string;
    status: PatientConditionStatus;
  }): Promise<ApiResponse<FindConditionsRelatedToPatientResponse>> {
    try {
      const response = (
        await this.axios.get<ApiResponse<FindConditionsRelatedToPatientResponse>>(
          `/patients/${patientId}/conditions`,
          { params: { status } },
        )
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as FindConditionsRelatedToPatientResponse,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async updatePatientConditions({
    patientId,
    conditions,
  }: UpdatePatientConditionsPayload): Promise<ApiResponse<UpdatePatientConditionsResponse>> {
    try {
      const response = (
        await this.axios.put<ApiResponse<UpdatePatientConditionsResponse>>(
          `/patients/${patientId}/conditions`,
          { conditions },
        )
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as UpdatePatientConditionsResponse,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async getAuditLogsByParams(params?: {
    source?: AuditLogSource;
    from?: string;
    to?: string;
    userName?: string;
    userId?: string;
    eventSearchString?: string;
    page?: number;
    pageSize?: number;
  }): Promise<ApiResponse<FindAllAuditLogsByUser>> {
    try {
      const response = (
        await this.axios.get<ApiResponse<FindAllAuditLogsByUser>>('/audit-logs', {
          params,
        })
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as FindAllAuditLogsByUser,
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }

  public async getAuditLogsByTransactionId(transactionId: string) {
    try {
      const response = (
        await this.axios.get<ApiResponse<AuditLog[]>>(`/audit-logs/${transactionId}`)
      ).data;

      // TODO: Provisional, until the backend response is standardized
      if (response.statusCode) {
        return {
          success: false,
          statusCode: response.statusCode,
          message: response.message,
        };
      }

      return {
        success: true,
        statusCode: response.statusCode,
        message: response.message,
        data: response as unknown as AuditLog[],
      };
    } catch (error) {
      console.error(error);

      return {
        success: false,
        statusCode: 500,
        message: 'An error has occurred, please contact support',
      };
    }
  }
}

const healthDataService = new HealthDataService();

export default healthDataService;
