import { CoreUsersByNameResponse } from '../interfaces/core';
import { InternalUserByNameResponse } from '../interfaces/core.adapter';

export const toInternalUserByNameResponse = (
  response: CoreUsersByNameResponse,
): InternalUserByNameResponse => {
  return {
    success: response.success,
    pagination: {
      currentPage: response.pagination.current_page,
      perPage: response.pagination.per_page,
      totalCount: response.pagination.total_count,
      totalPages: response.pagination.total_pages,
    },
    data: response.users.map((user) => ({
      clinic: {
        id: user.clinic.id,
        name: user.clinic.name,
      },
      email: user.email,
      fullName: user.full_name,
      id: user.id,
      uuid: user.uuid,
    })),
  };
};
