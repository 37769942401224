import { EventClickArg } from '@fullcalendar/core';
import { Box, Portal, SelectChangeEvent, Snackbar } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Title } from 'react-admin';
import { ClaimStatus, ClaimType, EhrClaim } from '../../interfaces/billing';
import { IClinic } from '../../interfaces/clinic';
import { SelectOption } from '../../interfaces/common';
import { getClinicsForSelect } from '../../lib/clinic';
import { BillingService } from '../../services';
import { Loader } from '../common/Loader';
import { ClaimDetails } from './ClaimDetails';
import { ClaimLegend } from './ClaimLegend';
import { ClaimList } from './ClaimList';
import './claims.css';
import { ClaimsCalendar } from './ClaimsCalendar';
import { ClaimSyncRequests } from './ClaimSyncRequests';
import { Filters } from './Filters';

export const Claims: React.FC = () => {
  const [clinicOptions, setClinicOptions] = useState<SelectOption[]>([]);
  const [clinics, setClinics] = useState<string[]>([]);

  // For sync
  const [fromSyncDate, setFromSyncDate] = useState(
    moment().subtract(7, 'days').format('YYYY-MM-DD'),
  );
  const [untilSyncDate, setUntilSyncDate] = useState(moment().format('YYYY-MM-DD'));

  // For calendar and filters
  const [fromFilterDate, setFromFilterDate] = useState<string | null>(
    moment().subtract(7, 'days').format('YYYY-MM-DD'),
  );
  const [untilFilterDate, setUntilFilterDate] = useState<string | null>(
    moment().format('YYYY-MM-DD'),
  );

  const [forceEhrFetching, setForceEhrFetching] = useState(false);
  const [forceResyncEhrFetching, setForceResyncEhrFetching] = useState(false);
  const [provider, setProvider] = useState<string[]>([]);
  const [providers, setProviders] = useState<SelectOption[]>([]);
  const [patient, setPatient] = useState<string[]>([]);
  const [patients, setPatients] = useState<SelectOption[]>([]);
  const [status, setStatus] = useState<string[]>([
    ClaimStatus.Pending,
    ClaimStatus.Uploaded,
    ClaimStatus.UploadedWithErrors,
    ClaimStatus.Processed,
  ]);
  const [claimType, setClaimType] = useState<string[]>([
    ClaimType.Appointment,
    ClaimType.RPM,
    ClaimType.Unknown,
  ]);
  const [selectedClaim, setSelectedClaim] = useState<EhrClaim | null>(null);
  const [selectedDateClaims, setSelectedDateClaims] = useState<EhrClaim[]>([]);
  const [viewingClaimList, setViewingClaimList] = useState(false);
  const [viewingClaimRequests, setViewingClaimRequests] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [claims, setClaims] = useState<EhrClaim[]>([]);
  const [message, setMessage] = useState<string>('');
  const [syncLoading, setSyncLoading] = useState(false);
  const [calendarLoading, setCalendarLoading] = useState(false);

  useEffect(() => {
    fetchClinics();
    fetchProviders();
    fetchPatients();
  }, []);

  const fetchClinics = async () => {
    const clinics = await getClinicsForSelect();
    if (!clinics.length) return;

    const clinicEhrGroups: { [clientId: string]: IClinic[] } = {};

    for (const clinic of clinics.sort((a, b) => a.id - b.id)) {
      if (!!clinic.ehr_config?.drchrono?.client_id) {
        clinicEhrGroups[clinic.ehr_config.drchrono.client_id] =
          clinicEhrGroups[clinic.ehr_config.drchrono.client_id] || [];
        clinicEhrGroups[clinic.ehr_config.drchrono.client_id].push(clinic);
      }
    }

    const filteredClinics: IClinic[] = [];
    for (const clinic of clinics) {
      if (!(clinic.apero_config?.apero_claims_sync_status === 'enabled')) {
        continue;
      }

      if (!clinic.ehr_config?.drchrono?.client_id) {
        continue;
      }

      const clinicGroup = clinicEhrGroups[clinic.ehr_config.drchrono.client_id];

      if (clinicGroup[0].id !== clinic.id) {
        continue;
      }

      // Clinic is the main member of the group

      const groupMembers = clinicGroup.slice(1).map((c) => c.id);
      const groupName = groupMembers.length > 0 ? ` (+ ${groupMembers.join(', ')})` : '';

      filteredClinics.push({
        ...clinic,
        name: `${clinic.name}${groupName}`,
      });
    }

    const clinicOptions = filteredClinics.map((clinic) => ({
      value: `${clinic.id}`,
      label: clinic.name,
    }));
    setClinicOptions(clinicOptions);
    return filteredClinics;
  };

  const handleFetchClaims = async (startDate: string, endDate: string) => {
    let defaultClinicIds: string[] = [];
    if (clinics.length === 0 && clinicOptions.length > 0) {
      defaultClinicIds = [clinicOptions[0].value];
      setClinics(defaultClinicIds);
    } else {
      defaultClinicIds = clinics.map((id) => id);
    }

    setCalendarLoading(true);
    try {
      const claimsData = await new BillingService().getClaims({
        fromDate: startDate,
        untilDate: endDate,
        clinicIds: defaultClinicIds.join(','),
        ...(provider.length && { providerIds: provider.map((id) => id).join(',') }),
        ...(patient.length && { patientIds: patient.map((id) => id).join(',') }),
        ...(status.length && { statuses: status.map((s) => s).join(',') }),
        ...(claimType.length && { sources: claimType.map((type) => type).join(',') }),
      });

      if (!claimsData || !claimsData.success) {
        setMessage(
          claimsData?.message || `Error fetching claims from range: ${startDate} to ${endDate}`,
        );
        return;
      }

      setClaims(claimsData.claims);
    } catch (error) {
      setMessage(`Error fetching claims from range: ${startDate} to ${endDate}: ${error}`);
    } finally {
      setCalendarLoading(false);
    }
  };

  const handleClinicChange = (event: SelectChangeEvent<string[]>) => {
    setClinics(event.target.value as string[]);
  };

  const handleFromSyncDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFromSyncDate(event.target.value);
  };

  const handleUntilSyncDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUntilSyncDate(event.target.value);
  };

  const handleProviderChange = (event: SelectChangeEvent<string[]>) => {
    setProvider(event.target.value as string[]);
  };

  const handlePatientChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: SelectOption[],
  ) => {
    setPatient(value.map((patient) => patient.value));
  };

  const handleStatusChange = (event: SelectChangeEvent<string[]>) => {
    setStatus(event.target.value as string[]);
  };

  const handleClaimTypeChange = (event: SelectChangeEvent<string[]>) => {
    setClaimType(event.target.value as string[]);
  };

  const handleForceEhrFetch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForceEhrFetching(event.target.checked);
  };

  const handleForceResyncEhrFetch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForceResyncEhrFetching(event.target.checked);
  };

  const handleEventClick = async (clickInfo: EventClickArg) => {
    try {
      setCalendarLoading(true);

      const claimId = parseInt(clickInfo.event.id);
      const selectedDate = moment.utc(clickInfo.event.start).format('YYYY-MM-DD');
      const eventsForDate = claims.filter(
        (claim) => moment.utc(claim.serviceDate).format('YYYY-MM-DD') === selectedDate,
      );
      const selectedClaim = await new BillingService().getClaim(claimId);
      if (!selectedClaim) {
        setMessage(`Error fetching claim details for claim ID: ${claimId}`);
        return;
      }

      setSelectedDateClaims(eventsForDate);
      setSelectedDate(selectedDate || '');
      setViewingClaimList(false);
      setSelectedClaim({
        id: claimId,
        date: selectedDate,
        ...selectedClaim.claim,
      } as unknown as EhrClaim);
    } catch (error) {
      setMessage(`Error fetching claim details for claim ID: ${clickInfo.event.id}, ${error}`);
    } finally {
      setCalendarLoading(false);
    }
  };

  const handleSyncRange = async () => {
    try {
      setSyncLoading(true);

      // Validate clinic
      if (clinics.length === 0) {
        setMessage('Please select at least one clinic');
        setSyncLoading(false);
        return;
      }

      const response = await new BillingService().runEhrClaimSync({
        clinicIds: clinics.map((id) => parseInt(id)),
        enableSlackMessages: false,
        forceEhrFetching,
        fromDate: fromSyncDate,
        untilDate: untilSyncDate,
      });

      if (!response || !response.success) {
        setMessage(
          response?.message ||
            `Error syncing claims from range: ${fromSyncDate} to ${untilSyncDate}`,
        );
        return;
      }

      const compactedResults = response.clinics
        .map((clinic) => {
          return clinic.success ? `#${clinic.requestId}` : '';
        })
        .filter((r) => !!r)
        .join(',');

      setMessage(
        `Claims sync requested for range:${fromSyncDate} to ${untilSyncDate}. Request ids:(${compactedResults})`,
      );

      if (fromFilterDate && untilFilterDate) {
        await handleFetchClaims(fromFilterDate, untilFilterDate);
      }
    } catch (error) {
      setMessage(`Error syncing claims from range: ${fromSyncDate} to ${untilSyncDate}: ${error}`);
    } finally {
      setSyncLoading(false);
    }
  };

  const handleAttemptResync = async () => {
    if (!selectedClaim) {
      setMessage('No claim selected for resync');
      return;
    }

    const response = await new BillingService().reSyncEhrClaim(selectedClaim.id, {
      forceEhrFetching: forceResyncEhrFetching,
    });

    if (!response) {
      setMessage('Unexpected error resyncing claim');
      return;
    }

    if (response.success) {
      setMessage('Claim resync finished. See claim logs for further details.');
    } else if (!!response.message) {
      setMessage(response.message);
    }

    const claimsCopy = [...claims];
    const claimIndex = claimsCopy.findIndex((c) => c.id === response?.claim?.id);
    if (claimIndex < 0) {
      return;
    }

    const newClaim = response.claim!;
    claimsCopy[claimIndex] = newClaim;
    setClaims(claimsCopy);
    setSelectedClaim(newClaim);
  };

  const handleFilter = async () => {
    try {
      setSyncLoading(true);

      // Validate clinic
      if (clinics.length === 0) {
        setMessage('Please select at least one clinic');
        setSyncLoading(false);
        return;
      }

      // Validate ranges
      if (!fromFilterDate || !untilFilterDate) {
        setMessage('Please select a range of dates');
        setSyncLoading(false);
        return;
      }

      await handleFetchClaims(fromFilterDate, untilFilterDate);
    } catch (error) {
      setMessage(
        `Error syncing claims from range: ${fromFilterDate} to ${untilFilterDate}: ${error}`,
      );
    } finally {
      setSyncLoading(false);
    }
  };

  const handleCloseDetails = () => {
    setSelectedClaim(null);
    setViewingClaimList(false);
  };

  const handleBackToList = () => {
    setSelectedClaim(null);
    setViewingClaimList(true);
  };

  const handleSelectEventFromList = async (event: EhrClaim) => {
    try {
      setCalendarLoading(true);

      const selectedClaim = await new BillingService().getClaim(event.id);
      if (!selectedClaim) {
        setMessage(`Error fetching claim details for claim ID: ${event.id}`);
        return;
      }

      setSelectedClaim({
        id: event.id,
        date: moment.utc(event.serviceDate).format('YYYY-MM-DD'),
        ...selectedClaim.claim,
      } as unknown as EhrClaim);
      setViewingClaimList(false);
    } catch (error) {
      setMessage(`Error fetching claim details for claim ID: ${event.id}, ${error}`);
    } finally {
      setCalendarLoading(false);
    }
  };

  const handleDisplaySyncRequests = async () => {
    setViewingClaimRequests(true);
  };

  const handleCloseClaimRequests = async () => {
    setViewingClaimRequests(false);
  };

  const fetchProviders = async () => {};

  const fetchPatients = async () => {};

  const handleDateSetClick = async (startDate: string, endDate: string) => {
    setFromFilterDate(startDate.slice(0, 10));
    setUntilFilterDate(endDate.slice(0, 10));

    await handleFetchClaims(
      moment.utc(startDate).format('YYYY-MM-DD'),
      moment.utc(endDate).format('YYYY-MM-DD'),
    );
  };

  if (clinicOptions.length === 0) {
    return <Loader />;
  }

  return (
    <>
      <Title title='Claims' />
      <Box className='claims' display='flex' flexDirection='column'>
        <Box mb={2}>
          <Filters
            claimType={claimType}
            clinic={clinics}
            clinics={clinicOptions}
            forceEhrFetch={forceEhrFetching}
            fromSyncDate={fromSyncDate}
            onClaimStatusChange={handleStatusChange}
            onClaimTypeChange={handleClaimTypeChange}
            onClinicChange={handleClinicChange}
            onFilter={handleFilter}
            onForceEhrFetch={handleForceEhrFetch}
            onFromSyncDateChange={handleFromSyncDateChange}
            onPatientChange={handlePatientChange}
            onProviderChange={handleProviderChange}
            onSyncRange={handleSyncRange}
            onUntilSyncDateChange={handleUntilSyncDateChange}
            onSyncRequests={handleDisplaySyncRequests}
            patient={patient}
            patients={patients}
            provider={provider}
            providers={providers}
            status={status}
            syncLoading={syncLoading}
            untilSyncDate={untilSyncDate}
          />
        </Box>
        <Box display='flex'>
          <Box p={1} flex='1'>
            <ClaimLegend />
            <ClaimsCalendar
              onEventClick={handleEventClick}
              onDateSetClick={handleDateSetClick}
              claims={claims}
              isLoading={calendarLoading}
            />
          </Box>
          {!viewingClaimRequests && viewingClaimList && selectedDate && (
            <ClaimList
              date={selectedDate}
              claims={selectedDateClaims}
              onSelectEvent={handleSelectEventFromList}
              onClose={handleCloseDetails}
            />
          )}
          {!viewingClaimRequests && !viewingClaimList && selectedClaim && (
            <Box className='detail-box'>
              <ClaimDetails
                clinics={clinicOptions}
                forceEhrFetch={forceResyncEhrFetching}
                onForceEhrFetch={handleForceResyncEhrFetch}
                claim={selectedClaim}
                onClose={handleCloseDetails}
                onResync={handleAttemptResync}
                onBack={handleBackToList}
              />
            </Box>
          )}
        </Box>
      </Box>
      {viewingClaimRequests && (
        <Box className='claim-requests side-panel'>
          <ClaimSyncRequests onClose={handleCloseClaimRequests} />
        </Box>
      )}
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!message}
          autoHideDuration={10000}
          onClose={() => setMessage('')}
          message={message}
        />
      </Portal>
    </>
  );
};
