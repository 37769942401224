export const upperCaseWords = (str: string) =>
  str?.replace(/^(.)|\s+(.)/g, (c) => c?.toUpperCase());

export const getClinicNameFromClinicCode = (clinicCode: string) =>
  clinicCode?.split('.')[2]?.replace('-', ' ') ?? '';

export const getFullClinicNameFromClinicCode = (clinicCode: string) => {
  const clinic = clinicCode?.split('.');
  const group = clinic[1]?.replace('-', ' ') ?? '';
  const name = clinic[2]?.replace('-', ' ') ?? '';
  return `${group} ${name}`;
};

export const normalizeReferralFileName = (fileName: string): string => {
  return fileName.replaceAll(/\s+/g, '-');
};

export const getCapitalizedWord = <T extends string>(word: string): T => {
  const lowercaseWord = word.toLowerCase();
  return `${lowercaseWord[0].toUpperCase()}${lowercaseWord.slice(1)}` as T;
};

export const camelCaseToSentence = (text: string) => {
  return text.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
};
