import { Record } from 'react-admin';

export interface IRole {
  id: number;
  name: string;
}

export type RecordType = Record | undefined;

export interface SnackBarProps {
  autoHideDuration?: number;
  horizontal?: 'left' | 'center' | 'right';
  message?: string;
  onClose?: () => void;
  open?: boolean;
  severity?: 'success' | 'info' | 'warning' | 'error';
  vertical?: 'top' | 'bottom';
}

export interface AlertComponentProps {
  key: string;
  title: string;
  content: React.ReactNode;
  severity: 'success' | 'info' | 'warning' | 'error';
}

export type SelectOption = {
  label: string;
  value: string;
};

export enum FormAction {
  CREATE = 'create',
  UPDATE = 'update',
}

export type Option<T> = { label: string; value: T };

export type ApiResponse<T> = {
  success: boolean;
  statusCode: number;
  message?: string;
  data?: T;
};
