import { Box, Button } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNotify } from 'react-admin';
import { RpmCalculationFilters } from '../../../interfaces/billing';
import { BillingClaimPreview, BillingPagination } from '../../../interfaces/billing.rpm';
import { IClinic } from '../../../interfaces/clinic';
import { SelectOption } from '../../../interfaces/common';
import { PayerItem } from '../../../interfaces/eligibility';
import { BillingService } from '../../../services';
import { CommonService } from '../../../services/common';
import coreService from '../../../services/core';
import eligibilityService from '../../../services/eligibility';
import { StyledTheme } from '../../../styles/styleTheme';
import { formatDateStringToYYYYMMDD } from '../../../util/dateFormat';
import { RPMFilters } from '../common/RPMFilters';
import { FilterConfiguration, Filters, LoadingStates, RPMCptCodes } from '../types/rpms.types';
import { DiscardCalculationModal } from './DiscardCalculationModal';
import { ProcessClaimsModal } from './ProcessClaimsModal';
import { RPMCalculationTable } from './RPMCalculationTable';

interface RPMCalculationProps {
  onAdvance: () => void;
  onMoveTo: (tabIndex: number) => void;
}

const filterConfigurations = [
  {
    key: 'clinic',
    label: 'Clinic',
    options: [],
    section: 'Patient Selection',
    type: 'select',
  },
  {
    key: 'insuranceCompany',
    label: 'Insurance Company',
    options: [],
    section: 'Patient Selection',
    type: 'autocomplete',
    fetchOptions: async (searchTerm: string) => {
      try {
        const response = await eligibilityService.getPayers({
          filter: searchTerm || null,
        });
        const options =
          response?.data?.map((payer: PayerItem) => ({
            label: payer.name,
            value: payer.id,
          })) || [];
        return options;
      } catch (error) {
        console.error('Error fetching insurance companies:', error);
        return [];
      }
    },
  },
  {
    key: 'dateOfService',
    label: 'Date Of Service',
    type: 'date',
  },
  {
    key: 'cptCodes',
    label: 'CPT Codes',
    options: Object.values(RPMCptCodes).map((value) => ({ value, label: value })),
    section: 'Patient Selection',
    type: 'select',
  },
  {
    key: 'memberSearch',
    label: 'Member Search',
    options: [],
    section: 'Patient Selection',
    type: 'autocomplete',
    fetchOptions: async (searchTerm: string) => {
      try {
        if (!searchTerm) {
          return [];
        }

        const response = await coreService.fetchMembers({
          searchTerm,
        });
        const options =
          response?.data?.map((member) => ({
            label: member.fullName,
            value: member.uuid,
          })) || [];
        return options;
      } catch (error) {
        console.error('Error fetching members:', error);
        return [];
      }
    },
  },
] as FilterConfiguration<Filters>[];

const initialFilters: Filters = {
  clinic: [],
  insuranceCompany: [],
  dos: [],
  cptCodes: [],
  memberSearch: [],
};

export const RPMCalculation: React.FC<RPMCalculationProps> = ({ onAdvance, onMoveTo }) => {
  const [claimsData, setClaimsData] = useState<BillingClaimPreview[]>([]);
  const [pagination, setPagination] = useState<BillingPagination>({
    totalCount: 0,
    totalPages: 0,
    currentPage: 1,
    limit: 30,
  });
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [clinicOptions, setClinicOptions] = useState<SelectOption[]>([]);
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({
    isApplying: false,
    isClearing: false,
    isProcessing: false,
    isDiscarding: false,
  });
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
  const [isProcessModalOpen, setIsProcessModalOpen] = useState(false);
  const [skipEffect, setSkipEffect] = useState(false);
  const [allRecordsSelected, setAllRecordsSelected] = useState(false);
  const [excludedRows, setExcludedRows] = useState<{ id: number; index: number }[]>([]);

  const notify = useNotify();

  const fetchClinics = async () => {
    try {
      const clinics = await CommonService.getClinics();
      setClinicOptions(
        clinics.map((clinic: IClinic) => ({ label: clinic.name, value: `${clinic.id}` })),
      );
    } catch (error) {
      console.error('Error fetching clinics:', error);
    }
  };

  const fetchClaims = async (parameters: RpmCalculationFilters) => {
    setLoadingStates((prev) => ({ ...prev, isApplying: true }));

    try {
      const response = await new BillingService().getClaimsCalculated(parameters);
      if (response) {
        setClaimsData(response.data);
        setPagination(response.pagination);
      }
    } catch (error) {
      console.error('Error fetching claims:', error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, isApplying: false }));
    }
  };

  const handlePageChange = (page: number) => {
    const parameters: RpmCalculationFilters = {
      clinicIds: filters.clinic.map((clinic) => parseInt(clinic, 10)),
      insuranceCompanyIds: filters.insuranceCompany || [],
      cptCodes: filters.cptCodes,
      page,
      limit: pagination.limit,
      dateOfService: formatDateStringToYYYYMMDD(filters?.dateOfService as unknown as Date),
      memberUuids: filters.memberSearch || [],
    };
    fetchClaims(parameters);
  };

  const handleRowsPerPageChange = (newLimit: number) => {
    const parameters: RpmCalculationFilters = {
      clinicIds: filters.clinic.map((clinic) => parseInt(clinic, 10)),
      insuranceCompanyIds: filters.insuranceCompany || [],
      cptCodes: filters.cptCodes,
      page: pagination.currentPage,
      limit: newLimit,
      dateOfService: formatDateStringToYYYYMMDD(filters?.dateOfService as unknown as Date),
      memberUuids: filters.memberSearch || [],
    };
    fetchClaims(parameters);
  };

  const updatedFilterConfigurations = useMemo(
    () =>
      filterConfigurations.map((config) =>
        config.key === 'clinic' ? { ...config, options: clinicOptions } : config,
      ),
    [clinicOptions],
  );

  useEffect(() => {
    fetchClinics();
  }, []);

  useEffect(() => {
    if (!skipEffect) {
      const parameters: RpmCalculationFilters = {
        clinicIds: filters.clinic.map((clinic) => parseInt(clinic, 10)),
        insuranceCompanyIds: filters.insuranceCompany || [],
        cptCodes: filters.cptCodes,
        page: pagination.currentPage,
        limit: pagination.limit,
        dateOfService: formatDateStringToYYYYMMDD(filters?.dateOfService as unknown as Date),
        memberUuids: filters.memberSearch || [],
      };

      fetchClaims(parameters);
    }
  }, [filters, pagination.currentPage, pagination.limit, skipEffect]);

  const handleApplyFilters = async (appliedFilters: typeof initialFilters) => {
    setLoadingStates((prev) => ({ ...prev, isApplying: true }));
    try {
      const parameters: RpmCalculationFilters = {
        clinicIds: appliedFilters.clinic.map((clinic) => parseInt(clinic, 10)),
        insuranceCompanyIds: appliedFilters.insuranceCompany || [],
        cptCodes: appliedFilters.cptCodes,
        page: 1,
        limit: pagination.limit,
        dateOfService: formatDateStringToYYYYMMDD(appliedFilters?.dateOfService as unknown as Date),
        memberUuids: appliedFilters.memberSearch || [],
      };

      await fetchClaims(parameters);
      setFilters(appliedFilters);
    } catch (error) {
      console.error('Error applying filters:', error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, isApplying: false }));
    }
  };

  const handleClearFilters = async () => {
    setLoadingStates((prev) => ({ ...prev, isClearing: true }));
    setSkipEffect(true);

    try {
      await getInitialClaimsRequest();
      setFilters(initialFilters);
      setSelectedRows([]);
      setPagination((prev) => ({ ...prev, currentPage: 1 }));
    } finally {
      setLoadingStates((prev) => ({ ...prev, isClearing: false }));
      setSkipEffect(false);
    }
  };

  const handleProcessFilters = () => {
    setIsProcessModalOpen(true);
  };

  const handleDiscardPreview = () => {
    setIsDiscardModalOpen(true);
  };

  const handleConfirmDiscard = async () => {
    try {
      const response = await new BillingService().discardClaims(selectedRows);
      if (!response) {
        setLoadingStates((prev) => ({ ...prev, isDiscarding: true }));
        setIsDiscardModalOpen(false);
        setSelectedRows([]);
        notify(`can't discard the claims`, 'warning');

        return;
      }

      notify(`${response.message}`, 'success');

      setLoadingStates((prev) => ({ ...prev, isDiscarding: true }));
      setIsDiscardModalOpen(false);
      setSelectedRows([]);

      await getInitialClaimsRequest();

      setFilters(initialFilters);
      if (claimsData.length === 0) {
        onMoveTo(0);
      }
    } catch (error) {
      console.error('Error discarding calculation:', error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, isDiscarding: false }));
    }
  };

  const handleConfirmProcess = async () => {
    setLoadingStates((prev) => ({ ...prev, isProcessing: true }));
    try {
      let response;
      const billingService = new BillingService();
      if (allRecordsSelected) {
        response = await billingService.exportClaimsWithFilters(
          {
            clinicIds: filters.clinic?.map((clinicId) => Number.parseInt(clinicId)),
            insuranceCompanyIds: filters.insuranceCompany,
            dateOfService: formatDateStringToYYYYMMDD(filters.dateOfService as unknown as Date),
            cptCodes: filters.cptCodes,
            memberUuids: filters.memberSearch,
          },
          excludedRows.length ? excludedRows.map(({ id }) => id) : undefined,
        );
      } else {
        const claimIds = selectedRows.map((index) => claimsData[index].id);
        response = await billingService.exportClaims(claimIds);
      }

      if (response) {
        onAdvance();
      }
    } catch (error) {
      console.error('Error processing claims:', error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, isProcessing: false }));
      setIsProcessModalOpen(false);
    }
  };

  const getInitialClaimsRequest = async () => {
    const parameters: RpmCalculationFilters = {
      clinicIds: [],
      insuranceCompanyIds: [],
      cptCodes: [],
      page: 1,
      limit: pagination.limit,
      dateOfService: undefined,
      memberUuids: [],
    };

    await fetchClaims(parameters);
  };

  return (
    <Box>
      <RPMFilters<Filters>
        configurations={updatedFilterConfigurations}
        onApplyFilters={handleApplyFilters}
        onClearFilters={handleClearFilters}
        onProcessFilters={handleProcessFilters}
        onProcessLabel={
          allRecordsSelected ? 'Export all selected claims' : `Export ${selectedRows.length} claims`
        }
        initialFilters={initialFilters}
        loadingStates={loadingStates}
        selectedCount={selectedRows.length}
        additionalButtons={
          <>
            <Button
              variant='outlined'
              style={{ color: StyledTheme.tealDark, borderColor: StyledTheme.tealDark }}
              onClick={handleDiscardPreview}>
              Discard Preview
            </Button>
          </>
        }
        allRecordsSelection={allRecordsSelected}
      />
      <RPMCalculationTable
        onRowSelectionChange={setSelectedRows}
        selectedRows={selectedRows}
        onRowExclusionChange={setExcludedRows}
        excludedRows={excludedRows}
        onAllRecordsSelected={setAllRecordsSelected}
        allRecordsSelected={allRecordsSelected}
        data={claimsData}
        pagination={pagination}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        isLoading={loadingStates.isApplying}
      />

      <DiscardCalculationModal
        open={isDiscardModalOpen}
        onClose={() => setIsDiscardModalOpen(false)}
        onConfirm={handleConfirmDiscard}
        isLoading={loadingStates.isDiscarding}
      />

      <ProcessClaimsModal
        open={isProcessModalOpen}
        onClose={() => setIsProcessModalOpen(false)}
        onConfirm={handleConfirmProcess}
        claimsCount={selectedRows.length}
        isLoading={loadingStates.isProcessing}
        allClaimsSelected={allRecordsSelected}
      />
    </Box>
  );
};
