import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { CreateMedicationPayload } from '../../../../interfaces/health-data';
import { MedicationForm } from './MedicationForm/MedicationForm';

export const CreateMedication = () => {
  const [form, setForm] = useState<CreateMedicationPayload | null>(null);

  const breadcrumbLabels = ['medications', 'create'];

  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = async () => {
    if (!form) {
      return;
    }

    // TODO: Uncomment this line when implementation is done
    //const result = await healthDataService.createMedication(form);

    const result = null;

    if (!result) {
      notify('There was an error saving medication', 'warning');
      return;
    }

    notify('Medication created successfully', 'success');
    //redirect(`/medical-setup/medications/${result.id}/show`);
  };

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' className='tab-section-action-bar'>
        <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
          {breadcrumbLabels.map((text, index) => {
            return (
              <Typography key={`text-medication-breadcrumb-${index}`} textTransform='capitalize'>
                {text}
              </Typography>
            );
          })}
        </Breadcrumbs>

        <Button
          onClick={handleSubmit}
          disabled={!form}
          variant='contained'
          sx={{ textTransform: 'capitalize' }}>
          Save
        </Button>
      </Box>

      <MedicationForm onChangeForm={setForm} />
    </Box>
  );
};
