import { TableContainer, TablePagination } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { CustomTableProps, TableComponents } from './types';

interface Props extends CustomTableProps {
  components?: TableComponents;
  useStickyBottomRow?: boolean;
}

export const CustomTable = ({
  columns,
  rows,
  minWidth = 600,
  defaultText = 'No data available',
  components = {},
  useStickyBottomRow = false,
  pagination,
  columnGroups = [],
}: Props) => {
  const {
    TableContainer: CustomTableContainer = TableContainer,
    Table: CustomTable = Table,
    TableCell: CustomTableCell = TableCell,
    TableRow: CustomTableRow = TableRow,
    TablePagination: CustomTablePagination = TablePagination,
  } = components;

  if (!columns.length && !rows.length) {
    return null;
  }

  return (
    <>
      <CustomTableContainer>
        <CustomTable sx={{ minWidth }} size='small'>
          <TableHead>
            {columnGroups && columnGroups.length > 0 && (
              <>
                {columnGroups.map((group, index) => {
                  const emptyCellsBefore =
                    group.startColumn -
                    (index > 0
                      ? columnGroups[index - 1].startColumn + columnGroups[index - 1].colspan
                      : 0);

                  return (
                    <React.Fragment key={group.id}>
                      {emptyCellsBefore > 0 &&
                        Array.from({ length: emptyCellsBefore }).map((_, i) => (
                          <CustomTableCell key={`empty-${group.id}-${i}`} />
                        ))}
                      <CustomTableCell
                        colSpan={group.colspan}
                        align='center'
                        sx={{
                          fontWeight: 'bold',
                          border: '1px #e0e0e0 solid',
                        }}>
                        {group.title}
                      </CustomTableCell>
                    </React.Fragment>
                  );
                })}

                {columns.length -
                  (columnGroups[columnGroups.length - 1].startColumn +
                    columnGroups[columnGroups.length - 1].colspan) >
                  0 &&
                  Array.from({
                    length:
                      columns.length -
                      (columnGroups[columnGroups.length - 1].startColumn +
                        columnGroups[columnGroups.length - 1].colspan),
                  }).map((_, i) => <CustomTableCell key={`empty-end-${i}`} />)}
              </>
            )}

            <CustomTableRow>
              {columns.map((column) => (
                <CustomTableCell
                  key={column.key}
                  align={column.align || 'left'}
                  width={column.width}
                  sx={{
                    minWidth: column.minWidth,
                    maxWidth: column.width,
                  }}>
                  {column.label}
                </CustomTableCell>
              ))}
            </CustomTableRow>
          </TableHead>

          <TableBody>
            {rows.length > 0 ? (
              rows.map((row, rowIndex) => (
                <CustomTableRow
                  key={`custom-row-${
                    useStickyBottomRow && (rows.length - 1 === rowIndex || rows.length === 0)
                      ? rowIndex * Number.MAX_SAFE_INTEGER
                      : rowIndex
                  }`}>
                  {columns.map((column, columnIndex) => (
                    <CustomTableCell
                      key={`custom-cell-${columnIndex}`}
                      align={column.align || 'left'}
                      sx={{
                        maxWidth: column.width,
                        minWidth: column.minWidth || '50px',
                        ...(column.groupId && {
                          borderLeft:
                            columnIndex ===
                            columns.findIndex((col) => col.groupId === column.groupId)
                              ? '1px solid #e0e0e0'
                              : undefined,
                          borderRight:
                            columnIndex ===
                            columns.map((col) => col.groupId).lastIndexOf(column.groupId)
                              ? '1px solid #e0e0e0'
                              : undefined,
                        }),
                      }}>
                      {row[column.key]}
                    </CustomTableCell>
                  ))}
                </CustomTableRow>
              ))
            ) : (
              <CustomTableRow>
                <CustomTableCell align='center' colSpan={columns.length}>
                  {defaultText}
                </CustomTableCell>
              </CustomTableRow>
            )}
          </TableBody>
        </CustomTable>

        {pagination && (
          <CustomTablePagination
            rowsPerPageOptions={pagination.rowsPerPageOptions}
            count={pagination.totalCount}
            rowsPerPage={pagination.rowPerPage}
            page={pagination.page - 1}
            onPageChange={(e, page) => pagination.onPageChange(page + 1)}
            onRowsPerPageChange={pagination.onRowsPerPageChange}
          />
        )}
      </CustomTableContainer>
    </>
  );
};
