import axios from 'axios';
import { CoreUsersByNameResponse } from '../interfaces/core';
import { InternalUserByNameResponse } from '../interfaces/core.adapter';
import { AuthService } from './auth';
import { toInternalUserByNameResponse } from './core.adapter';

const CORE_API_URL = process.env.REACT_APP_ENARA_URL;

class CoreService {
  public async fetchMembers({
    searchTerm = null,
    target,
  }: {
    searchTerm?: string | null;
    target?: 'providers' | 'members';
  }): Promise<InternalUserByNameResponse> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get<CoreUsersByNameResponse>(
        `${CORE_API_URL}/api/v4/users/search_by_name`,
        {
          headers: {
            Authorization: `Bearer ${authData?.auth_token}`,
          },
          params: {
            ...(searchTerm && { name: searchTerm }),
            ...(target && { target }),
          },
        },
      );
      return toInternalUserByNameResponse(response.data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

const coreService = new CoreService();

export default coreService;
