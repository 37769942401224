import { Tab, Tabs, styled } from '@mui/material';
import React from 'react';
import { customTabStyles } from './styles';

type TabLabel = {
  label: string;
  icon?: React.ReactElement;
  onClick?: () => void;
};

interface CustomTabsProps {
  tabLabels: TabLabel[];
  value: number;
  onChange: (event: React.SyntheticEvent, value: number) => void;
  enabledTabs?: number[];
  'aria-label'?: string;
  useEnabledTabs?: boolean;
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
  ...customTabStyles(theme),
}));

export const CustomTabs: React.FC<CustomTabsProps> = ({
  tabLabels,
  value,
  onChange,
  enabledTabs = [],
  'aria-label': ariaLabel,
  useEnabledTabs = false,
}) => (
  <StyledTabs value={value} onChange={onChange} aria-label={ariaLabel}>
    {tabLabels.map((tab, index) => (
      <Tab
        onClick={tab.onClick}
        key={index}
        label={tab.label}
        icon={React.isValidElement(tab.icon) ? tab.icon : undefined}
        iconPosition='start'
        disabled={useEnabledTabs ? !enabledTabs.includes(index) : false}
      />
    ))}
  </StyledTabs>
);
