import { CheckBox } from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FilePresent from '@mui/icons-material/FilePresent';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import ScienceIcon from '@mui/icons-material/Science';
import { FC, useEffect, useState } from 'react';
import { Menu, MenuItemLink, MenuProps } from 'react-admin';
import { useLocation } from 'react-router-dom';
import featuresClient, { FeaturesName } from '../lib/features';
import { AuthService } from '../services/auth';
import MixpanelService from '../services/mixpanel';
import { Roles } from '../util/constants';

export const CustomMenu: FC<MenuProps> = (props) => {
  const [shouldShow, setShouldShow] = useState<{
    patientLabResults: boolean;
    medicalSetup: boolean;
    patientConditions: boolean;
    auditLogs: boolean;
  }>({ patientLabResults: false, medicalSetup: false, patientConditions: false, auditLogs: false });

  const { pathname } = useLocation();

  useEffect(() => {
    (async () => {
      const [patientLabResults, medicalSetup, patientConditions, auditLogs] =
        await featuresClient.fetchFeatureFlagsStatus([
          { name: FeaturesName.OpsDashShowPatientsLabResults },
          { name: FeaturesName.OpsDashShowMedicalSetup },
          { name: FeaturesName.OpsDashShowPatientConditions },
          { name: FeaturesName.OpsDashShowAuditLogs },
        ]);

      setShouldShow({ patientLabResults, medicalSetup, patientConditions, auditLogs });
    })();
  }, []);

  return (
    <Menu {...props} dense>
      {AuthService.checkRoleAccess(Roles.Billing) && (
        <>
          <MenuItemLink
            to='/eligibility'
            primaryText='Eligibility'
            leftIcon={<CheckBox />}
            onClick={() => {
              MixpanelService.trackButtonClicked({
                Field: 'Eligibility',
                source: pathname,
                Type: 'link',
              });
            }}
          />

          <MenuItemLink
            to='/claims'
            primaryText='Claims'
            leftIcon={<AccountBalanceIcon />}
            onClick={() => {
              MixpanelService.trackButtonClicked({
                Field: 'Claims',
                source: pathname,
                Type: 'link',
              });
            }}
          />

          <MenuItemLink
            to='/rpms'
            primaryText='RPMs'
            leftIcon={<MonitorHeartIcon />}
            onClick={() => {
              MixpanelService.trackButtonClicked({
                Field: 'RPMs',
                source: pathname,
                Type: 'link',
              });
            }}
          />
        </>
      )}

      <MenuItemLink
        to='/referrals'
        primaryText='Referrals'
        leftIcon={<FilePresent />}
        onClick={() => {
          MixpanelService.trackButtonClicked({
            Field: 'Referrals',
            source: pathname,
            Type: 'link',
          });
        }}
      />

      {AuthService.checkRoleAccess(Roles.Billing) && shouldShow.medicalSetup && (
        <MenuItemLink
          to='/medical-setup/conditions'
          primaryText='Medical Setup'
          leftIcon={<MedicalServicesIcon />}
          onClick={() => {
            MixpanelService.trackButtonClicked({
              Field: 'Medical Setup',
              source: pathname,
              Type: 'link',
            });
          }}
        />
      )}

      {AuthService.checkRoleAccess(Roles.Billing) && shouldShow.patientLabResults && (
        <MenuItemLink
          to='/laboratory-results'
          primaryText='Laboratory Results'
          leftIcon={<ScienceIcon />}
          onClick={() => {
            MixpanelService.trackButtonClicked({
              Field: 'Laboratory Results',
              source: pathname,
              Type: 'link',
            });
          }}
        />
      )}

      {AuthService.checkRoleAccess(Roles.Billing) && shouldShow.patientConditions && (
        <MenuItemLink
          to='/patients/conditions'
          primaryText='Patients'
          leftIcon={<AssignmentIndIcon />}
          onClick={() => {
            MixpanelService.trackButtonClicked({
              Field: 'Patients',
              source: pathname,
              Type: 'link',
            });
          }}
        />
      )}

      {AuthService.checkRoleAccess(Roles.Billing) && shouldShow.auditLogs && (
        <MenuItemLink
          to='/audit-logs'
          primaryText='Audit Logs'
          leftIcon={<AnalyticsIcon />}
          onClick={() => {
            MixpanelService.trackButtonClicked({
              Field: 'Audit Logs',
              source: pathname,
              Type: 'link',
            });
          }}
        />
      )}
    </Menu>
  );
};
