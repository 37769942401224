import { Box, Button, Chip, Snackbar, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRedirect } from 'react-admin';
import useDebounce from '../../../../hooks/useDebounce';
import { Pagination, Procedure, ProcedureStatus } from '../../../../interfaces/health-data';
import healthDataService from '../../../../services/health-data';
import { DEFAULT_PAGE_SIZE, INITIAL_PAGINATION_STATE } from '../../../../util/constants';
import { getCapitalizedWord } from '../../../../util/stringFormat';
import { CustomTable } from '../../../common/CustomTable';
import './css/procedures.css';

export const Procedures = () => {
  const [procedures, setProcedures] = useState<Procedure[]>([]);
  const [message, setMessage] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);

  const redirect = useRedirect();

  const [pagination, setPagination] = useState<Pagination>(INITIAL_PAGINATION_STATE);

  const fetchProcedures = async ({ name, cptCode }: { name?: string; cptCode?: string } = {}) => {
    const response = await healthDataService.findAllProceduresByParams({
      name,
      cptCode,
      status: ProcedureStatus.ACTIVE,
      page,
      pageSize: DEFAULT_PAGE_SIZE,
    });

    if (!response.data) {
      setMessage(response.message || 'There is a problem fetching procedures');
      return;
    }
    setPagination(response.data.pagination);
    setProcedures(response.data.procedures);
  };

  const debouncedFetchProcedures = useDebounce(fetchProcedures);

  const handleChangePage = (page: number) => setPage(page);

  useEffect(() => {
    (async () => await fetchProcedures())();
  }, [page]);

  return (
    <>
      <Box className='tab-section procedures-section' marginTop={0}>
        <Box className='procedures-header'>
          <Typography>Procedures</Typography>
          <Box>
            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <Typography
                mb={1}
                sx={{
                  '&.MuiTypography-root': { fontSize: '14px' },
                }}>
                Search by name or CPT
              </Typography>
              <TextField
                key={`autocomplete-procedures`}
                size='small'
                placeholder='Type a name or cpt code'
                sx={{
                  width: '400px',
                  minWidth: '250px',
                }}
                onChange={(e) =>
                  debouncedFetchProcedures({
                    name: e.target.value,
                    cptCode: e.target.value,
                  })
                }
              />
            </Box>

            <Button
              size='small'
              sx={{ fontSize: '12px', padding: '7px 8px' }}
              variant='contained'
              onClick={() => redirect(`/medical-setup/procedures/create`)}>
              Add Procedure
            </Button>
          </Box>
        </Box>

        <CustomTable
          columns={[
            { key: 'status', label: 'Status' },
            { key: 'name', label: 'Name' },
            { key: 'cptCode', label: 'CPT' },
            { key: 'actions', label: 'Actions', width: '150px', minWidth: '150px' },
          ]}
          defaultText='No procedures created'
          key={'procedures-table'}
          rows={procedures.map((data) => {
            const { status, name, cptCode } = data;

            return {
              status: (
                <Chip
                  size='small'
                  label={getCapitalizedWord(status)}
                  sx={{
                    fontSize: '11px',
                    color: 'white',
                    backgroundColor: status === ProcedureStatus.ACTIVE ? '#1FAF84' : '#FF4A47',
                  }}
                />
              ),
              name,
              cptCode: cptCode,
              actions: [
                <Button
                  size='small'
                  variant='text'
                  onClick={() => redirect(`/medical-setup/procedures/${data?.id}/show`)}
                  sx={{
                    textTransform: 'capitalize',
                  }}>
                  View
                </Button>,
                <Button
                  size='small'
                  variant='text'
                  onClick={() => redirect(`/medical-setup/procedures/${data?.id}/edit`)}
                  sx={{
                    textTransform: 'capitalize',
                  }}>
                  Edit
                </Button>,
              ],
            };
          })}
          pagination={{
            rowsPerPageOptions: [DEFAULT_PAGE_SIZE],
            rowPerPage: pagination.perPage,
            page: pagination.currentPage,
            totalCount: pagination.totalCount,
            onPageChange: handleChangePage,
          }}
        />
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!message}
        autoHideDuration={10000}
        onClose={() => setMessage('')}
        message={message}
        ContentProps={{
          style: { marginTop: '50px' },
        }}
      />
    </>
  );
};
