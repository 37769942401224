import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { CreateProcedurePayload, ProcedureStatus } from '../../../../../interfaces/health-data';
import { getCapitalizedWord } from '../../../../../util/stringFormat';
import './procedure.css';

type Props = {
  procedure?: CreateProcedurePayload;
  onChangeForm: (v: CreateProcedurePayload) => void;
};

export const ProcedureForm = ({ procedure, onChangeForm }: Props) => {
  const [procedureForm, setProcedureForm] = useState<CreateProcedurePayload>({
    name: '',
    cptCode: '',
    status: ProcedureStatus.ACTIVE,
  });

  const handleChangeForm = (e: SelectChangeEvent<string> | ChangeEvent<Element>) => {
    const { value, name } = e.target as {
      value: string;
      name: string;
    };

    setProcedureForm({ ...procedureForm, [name]: value });
  };

  useEffect(() => {
    onChangeForm(procedureForm);
  }, [procedureForm]);

  useEffect(() => {
    if (procedure) {
      setProcedureForm(procedure);
    }
  }, [procedure]);

  return (
    <Box>
      <Box className='tab-section conditions-section'>
        <Box className='tab-section-form-field procedure-form-procedure-section'>
          <Typography variant='body2'>Status:</Typography>
          <FormControl sx={{ minWidth: 120 }} size='small' key='form-control-procedure-status'>
            <Select name='status' value={procedureForm.status} onChange={handleChangeForm}>
              {Object.entries(ProcedureStatus).map(([label, value], index) => {
                return (
                  <MenuItem value={value} key={`item-procedure-status-${index}`}>
                    {getCapitalizedWord(label)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <Box className='tab-section-form-field procedure-form-procedure-section'>
          <Typography variant='body2'>Name:</Typography>
          <TextField
            key={'procedure-text-field-name'}
            size='small'
            placeholder='Write a name'
            value={procedureForm.name}
            name='name'
            onChange={handleChangeForm}
          />
        </Box>

        <Box className='tab-section-form-field procedure-form-procedure-section'>
          <Typography variant='body2'>CPT Code:</Typography>
          <TextField
            key={'procedure-text-field-cpt-code'}
            name='cptCode'
            size='small'
            placeholder='Write a CPT Code'
            value={procedureForm.cptCode}
            onChange={handleChangeForm}
          />
        </Box>
      </Box>
    </Box>
  );
};
