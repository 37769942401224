import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { StyledChip, TooltipText } from '../styles';
import { getDetailChipColor } from './ChipColors';

const DEFAULT_CHIP_COUNT = 4;

interface CodeChipListProps {
  codes: string[];
  allowSlice?: boolean;
}

export const CodeChipList: React.FC<CodeChipListProps> = ({ codes, allowSlice = true }) => {
  return (
    <Box>
      {(allowSlice ? codes.slice(0, DEFAULT_CHIP_COUNT) : codes).map((code) => (
        <StyledChip key={code} label={code} size='small' sx={{ ...getDetailChipColor(code) }} />
      ))}

      {allowSlice && codes.length > DEFAULT_CHIP_COUNT && (
        <Tooltip
          title={
            <Box>
              {codes.slice(DEFAULT_CHIP_COUNT).map((code) => (
                <StyledChip key={code} label={code} />
              ))}
            </Box>
          }
          arrow>
          <TooltipText>+{codes.length - DEFAULT_CHIP_COUNT}</TooltipText>
        </Tooltip>
      )}
    </Box>
  );
};
