import { Box, CircularProgress } from '@mui/material';
import { StyledTheme } from '../../../styles/styleTheme';

export const RpmLoading: React.FC = () => {
  return (
    <Box
      position='absolute'
      top={0}
      left={0}
      right={0}
      bottom={0}
      display='flex'
      alignItems='center'
      justifyContent='center'
      bgcolor='rgba(255, 255, 255, 0.7)'
      zIndex={1}>
      <CircularProgress style={{ color: StyledTheme.tealDark }} />
    </Box>
  );
};
