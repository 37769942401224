import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-admin';

export type LinkableBreadcrumbProps = {
  items: {
    text: string;
    location?: string;
  }[];
};

const LinkableBreadcrumb: FC<LinkableBreadcrumbProps> = ({ items }) => (
  <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
    {items.map(({ text, location }, index) =>
      location ? (
        <Link to={location} key={`breadcrumb-link-${text}-${index}`}>
          <Typography textTransform='capitalize'>{text}</Typography>
        </Link>
      ) : (
        <Typography key={`breadcrumb-text-${text}-${index}`} textTransform='capitalize'>
          {text}
        </Typography>
      ),
    )}
  </Breadcrumbs>
);

export default LinkableBreadcrumb;
