import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SelectOption } from '../../../interfaces/common';

interface ReusableAutocompleteProps {
  label: string;
  options: SelectOption[];
  value: string[];
  onChange: (value: string[]) => void;
  multiple?: boolean;
  includeSelectAll?: boolean;
  disabled?: boolean;
  fetchOptions?: (searchTerm: string) => Promise<SelectOption[]>;
  minSearchLength?: number;
  debounceTime?: number;
  defaultSearchTerm?: string;
  placeholder?: string;
}

export const ReusableAutocomplete: React.FC<ReusableAutocompleteProps> = ({
  label,
  options = [],
  value = [],
  onChange,
  multiple = true,
  includeSelectAll = false,
  disabled = false,
  fetchOptions,
  minSearchLength = 3,
  debounceTime = 300,
  defaultSearchTerm = '',
  placeholder = '',
}) => {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [fetchedOptions, setFetchedOptions] = useState<SelectOption[]>([]);
  const [open, setOpen] = useState(false);

  const allOptions = useMemo(() => {
    const baseOptions = [...options, ...fetchedOptions];
    return includeSelectAll ? [{ value: 'all', label: 'Select All' }, ...baseOptions] : baseOptions;
  }, [options, fetchedOptions, includeSelectAll]);

  const loadOptions = useCallback(
    async (searchTerm: string) => {
      if (!fetchOptions) return;

      setLoading(true);
      try {
        const newOptions = await fetchOptions(searchTerm);
        if (Array.isArray(newOptions)) {
          setFetchedOptions(newOptions);
        }
      } catch (error) {
        console.error(`Error loading options for search term "${searchTerm}":`, error);
      } finally {
        setLoading(false);
      }
    },
    [fetchOptions],
  );

  useEffect(() => {
    loadOptions(defaultSearchTerm);
  }, [loadOptions, defaultSearchTerm]);

  useEffect(() => {
    if (!fetchOptions || !open || inputValue.length < minSearchLength) return;

    const timer = setTimeout(() => {
      loadOptions(inputValue);
    }, debounceTime);

    return () => clearTimeout(timer);
  }, [inputValue, fetchOptions, minSearchLength, debounceTime, open, loadOptions]);

  const selectedOptions = useMemo(() => {
    const optionsMap = new Map(allOptions.map((opt) => [opt.value, opt]));
    return value
      .map((v) => optionsMap.get(v))
      .filter((opt): opt is SelectOption => opt !== undefined);
  }, [value, allOptions]);

  const handleChange = async (
    _: React.SyntheticEvent,
    newValue: SelectOption | SelectOption[] | null,
    reason: string,
  ) => {
    if (!newValue || reason === 'clear' || reason === 'removeOption') {
      onChange([]);
      setInputValue('');
      loadOptions(defaultSearchTerm);
      return;
    }

    if (Array.isArray(newValue)) {
      if (includeSelectAll && newValue.some((opt) => opt.value === 'all')) {
        const allValues = allOptions.filter((opt) => opt.value !== 'all').map((opt) => opt.value);
        onChange(value.length === allValues.length ? [] : allValues);
        return;
      }

      onChange(newValue.map((opt) => opt.value));
    } else {
      onChange([newValue.value]);
      if (!multiple) {
        setOpen(false);
      }
    }
  };

  return (
    <Autocomplete
      size='small'
      disabled={disabled}
      multiple={multiple}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={allOptions}
      loading={loading}
      value={multiple ? selectedOptions : selectedOptions[0] || null}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      disableCloseOnSelect={multiple}
      clearOnBlur={false}
      getOptionLabel={(option) => option?.label || ''}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      filterOptions={(x) => x}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          variant='filled'
          key={`option-${value}`}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color='inherit' size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
