import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import { BillingPatient } from '../../../interfaces/billing.rpm';
import { formatDateStringToMMDDYYYY } from '../../../util/dateFormat';
import { getEnaraUrl, getHintUrl } from '../../../util/url';
import { CodeChipList } from './CodeChipList';

interface PatientDetailsModalProps {
  open: boolean;
  onClose: () => void;
  patient: BillingPatient | null;
}

export const PatientDetailsModal: React.FC<PatientDetailsModalProps> = ({
  open,
  onClose,
  patient,
}) => {
  if (!patient) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          fontSize: '1.5rem',
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        Patient Details
        <IconButton onClick={onClose} sx={{ color: 'inherit' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                border: '1px solid #e0e0e0',
                borderRadius: 2,
                padding: 2,
              }}>
              <Box mb={2}>
                <Typography variant='h6' fontWeight='bold' gutterBottom>
                  Name: {patient.firstName} {patient.lastName}
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography>
                  ID:{' '}
                  <Link
                    href={`${getEnaraUrl(`/activeadmin/users/${patient.memberId}`)}`}
                    target='_blank'
                    rel='noopener noreferrer'>
                    {patient.memberId}
                  </Link>
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography>
                  Clinic:{' '}
                  <Link
                    href={`${getEnaraUrl(`/activeadmin/clinics/${patient.clinicId}`)}`}
                    target='_blank'
                    rel='noopener noreferrer'>
                    {patient.clinicName}
                  </Link>
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography>ICD10 Codes:</Typography>
                <Box mt={1}>
                  <CodeChipList codes={patient.icd10Codes} />
                </Box>
              </Box>
              <Box mb={1}>
                <Typography>
                  Membership ID:{' '}
                  {patient.membershipId ? (
                    <Link
                      href={getHintUrl(
                        `/patients/patients_page=1&patients_sort=last_name&patients_q=${patient.membershipId}`,
                      )}
                      target='_blank'
                      rel='noopener noreferrer'>
                      {patient.membershipId}
                    </Link>
                  ) : (
                    'Not Available'
                  )}
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography>
                  Membership Start: {formatDateStringToMMDDYYYY(patient.membershipStartDate)}
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography>Membership Status: {patient.membershipComputeStatus}</Typography>
              </Box>
              <Box mb={1}>
                <Typography fontWeight='bold'>Available RPM CPT Codes:</Typography>
                <Box mt={1}>
                  {!patient?.cptCodes || patient.cptCodes.length === 0 ? (
                    <Typography variant='body1'>No CPT Codes Available</Typography>
                  ) : (
                    patient.cptCodes.map((code) => (
                      <Chip
                        key={code}
                        label={code}
                        sx={{
                          marginRight: 1,
                          marginBottom: 1,
                          backgroundColor: '#f0f0f0',
                        }}
                      />
                    ))
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                border: '1px solid #e0e0e0',
                borderRadius: 2,
              }}>
              <Box
                mb={2}
                sx={{
                  borderBottom: '1px solid #e0e0e0',
                  padding: 2,
                }}>
                <Box mb={2}>
                  <Typography variant='h6' fontWeight='bold' gutterBottom>
                    RPM Configuration Summary
                  </Typography>
                </Box>
                {/* TODO: Review needed changes to get optOutDate */}

                {/* <Box mb={1}>
                <Typography>Opt-out Date: {patient.rpmConfiguration.optOutDate}</Typography>
                </Box> */}
                <Box mb={1}>
                  <Typography>
                    Disable RPM: {patient.rpmExportStatus === 'disabled' ? 'Yes' : 'No'}
                  </Typography>
                </Box>
                {/* TODO: Restore link in next version */}
                <Box mb={1}>
                  <Typography>
                    Insurance Company: {patient.insuranceCompany} ({patient.insuranceCompanyId})
                  </Typography>
                </Box>
              </Box>

              {/* TODO: Add Payer configuration */}
              <Box
                sx={{
                  padding: 2,
                  borderBottom: '1px solid #e0e0e0',
                }}>
                <Box mb={1}>
                  <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                    Insurance Company Configuration
                  </Typography>
                </Box>

                <Box pl={4} mt={1}>
                  <Typography variant='body2' sx={{ mb: 1 }}>
                    Payer Id: {patient.clinicPayerConfig.payerId}
                  </Typography>
                </Box>
              </Box>

              {/* TODO: Add Clinic Configuration */}
              <Box sx={{ padding: 2 }}>
                <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                  Clinic Specific Configuration
                </Typography>
                <Box mb={2}>
                  <Typography variant='body1' fontWeight='bold'>
                    {patient.clinicName}
                  </Typography>
                  {Object.entries(patient.clinicPayerConfig.cptCodesConfig).map(
                    ([cptCode, config]) => (
                      <Box
                        key={cptCode}
                        sx={{
                          ml: 2,
                          mb: 1,
                          pb: 1,
                          borderBottom: '1px solid #e0e0e0',
                        }}>
                        <Typography sx={{ mb: 0.5 }}>
                          CPT Code: <strong>{cptCode}</strong> •{' '}
                          {config.enabled ? 'Enabled' : 'Disabled'}
                        </Typography>
                        <Typography sx={{ mb: 0.5 }}>
                          Max Claims Per Cycle: {config.max_claims_per_cycle} • POS: {config.pos}
                        </Typography>
                      </Box>
                    ),
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
