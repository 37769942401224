import { Box, Chip, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import useDebounce from '../../hooks/useDebounce';
import { AuditLogSource } from '../../interfaces/audit-log';
import { FindAllAuditLogsByUser, Pagination } from '../../interfaces/health-data';
import healthDataService from '../../services/health-data';
import { DEFAULT_PAGE_SIZE, INITIAL_PAGINATION_STATE } from '../../util/constants';
import { formatDateStringToMMDDYYYYAndHour } from '../../util/dateFormat';
import { camelCaseToSentence, getCapitalizedWord } from '../../util/stringFormat';
import { CustomTable } from '../common/CustomTable';
import '../common/tab-section.css';
import './audit-logs.css';

export const AuditLogs = () => {
  const [auditLogs, setAuditLogs] = useState<FindAllAuditLogsByUser['logs']>([]);
  const [page, setPage] = useState<number>(1);
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  const [filters, setFilters] = useState<{
    source?: AuditLogSource;
    from?: string;
    to?: string;
    userName?: string;
    userId?: string;
    eventTypeSearchString?: string;
    page?: number;
    pageSize?: number;
  }>({});

  const notify = useNotify();
  const [pagination, setPagination] = useState<Pagination>(INITIAL_PAGINATION_STATE);

  const fetchAuditLogs = async (filters?: { [filter: string]: number | string }) => {
    const response = await healthDataService.getAuditLogsByParams({
      ...filters,
      page,
      pageSize: DEFAULT_PAGE_SIZE,
    });

    if (!response?.data) {
      notify(response.message || 'There is a problem fetching auditLogs');
      return;
    }
    setPagination(response.data.pagination);
    setAuditLogs(response.data.logs);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const toggleShowExpanded = (resourceId: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [resourceId]: !prev[resourceId],
    }));
  };

  const handleFilterChanges = (filter?: { [filter: string]: number | string }) => {
    const updatedFilters = { ...filters, ...filter };
    setFilters(updatedFilters);
    debouncedFetchLaboratoryTests(updatedFilters);
  };

  const debouncedFetchLaboratoryTests = useDebounce(fetchAuditLogs);

  useEffect(() => {
    (async () => await fetchAuditLogs())();
  }, [page]);

  return (
    <>
      <Box className='tab-section audit-logs-section' marginTop={0}>
        <Box className='audit-logs-header'>
          <Typography>Audit Logs</Typography>

          <Box display='flex' alignItems='flex-start' gap={1} mt={2} mb={2}>
            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <Typography
                sx={{
                  '&.MuiTypography-root': { fontSize: '13px' },
                }}>
                Start date
              </Typography>

              <TextField
                size='small'
                type='date'
                value={filters.from}
                onChange={(e) => handleFilterChanges({ from: e.target.value })}
              />
            </Box>

            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <Typography
                sx={{
                  '&.MuiTypography-root': { fontSize: '13px' },
                }}>
                End date
              </Typography>

              <TextField
                size='small'
                type='date'
                value={filters.to || ''}
                onChange={(e) => handleFilterChanges({ to: e.target.value })}
              />
            </Box>

            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <Typography
                sx={{
                  '&.MuiTypography-root': { fontSize: '13px' },
                }}>
                Filter by user name
              </Typography>
              <TextField
                key={`autocomplete-laboratory-tests`}
                size='small'
                placeholder='Enter a user name'
                value={filters.userName}
                sx={{
                  width: '180px',
                }}
                onChange={(e) => handleFilterChanges({ userName: e.target.value })}
              />
            </Box>

            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <Typography
                sx={{
                  '&.MuiTypography-root': { fontSize: '13px' },
                }}>
                Filter by user id
              </Typography>
              <TextField
                type='number'
                value={filters.userId}
                key={`autocomplete-laboratory-tests`}
                size='small'
                placeholder='Enter an ID'
                sx={{
                  width: '180px',
                }}
                onChange={(e) => {
                  console.log('HELLO');
                  handleFilterChanges({ userId: e.target.value });
                }}
              />
            </Box>

            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <Typography
                sx={{
                  '&.MuiTypography-root': { fontSize: '13px' },
                }}>
                Filter by type or action
              </Typography>
              <TextField
                value={filters.eventTypeSearchString}
                key={`autocomplete-laboratory-tests`}
                size='small'
                placeholder='Enter a type or action'
                sx={{
                  width: '180px',
                }}
                onChange={(e) => handleFilterChanges({ eventTypeSearchString: e.target.value })}
              />
            </Box>

            <Box
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
              className='audit-logs-select'>
              <Typography
                sx={{
                  '&.MuiTypography-root': { fontSize: '13px' },
                }}>
                Filter by source
              </Typography>
              <Select
                key={`autocomplete-laboratory-tests`}
                size='small'
                placeholder='Enter a resource name or action'
                sx={{
                  width: '150px',
                }}
                onChange={(e) => handleFilterChanges({ source: e.target.value as string })}>
                {Object.values(AuditLogSource).map((value, index) => {
                  return (
                    <MenuItem value={value} key={`item-procedures-validity-from-${index}`}>
                      {`${value
                        .split('-')
                        .map((w) => `${w[0].toUpperCase()}${w.slice(1)}`)
                        .join(' ')}`}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
        </Box>

        <CustomTable
          columns={[
            { key: 'timestamp', label: 'Timestamp', width: '120px', minWidth: '120px' },
            { key: 'user', label: 'User', width: '110px', minWidth: '110px' },
            { key: 'source', label: 'Source', width: '80px', minWidth: '80px' },
            { key: 'action', label: 'Action', width: '50px', minWidth: '50px' },
            { key: 'description', label: 'Type', width: '80px', minWidth: '140px' },
            { key: 'changes', label: 'Changes', minWidth: '120px' },
          ]}
          defaultText='No audit logs created'
          key={'audit-logs-table'}
          rows={auditLogs.map((data) => {
            const isExpanded = expandedRows[data.resourceId] || false;
            const changesToDisplay = isExpanded
              ? Object.entries(data.changes)
              : Object.entries(data.changes).slice(0, 3);

            return {
              timestamp: formatDateStringToMMDDYYYYAndHour(data.createdAt),
              user: (
                <Box display='flex' flexDirection='column'>
                  <Chip
                    size='small'
                    label={`ID: ${data.userId}`}
                    sx={{
                      maxWidth: '80%',
                      marginBottom: '1rem',
                      fontSize: '12px',
                    }}
                  />
                  <Typography>
                    {data.userFullName.split(' ').map(getCapitalizedWord).join(' ')}
                  </Typography>
                </Box>
              ),
              source: data.source.split('-').map(getCapitalizedWord).join(' '),
              action: getCapitalizedWord(data.action),
              description: (
                <Box display='flex' flexDirection='column'>
                  <Typography>{data.resourceName.replace(/([a-z])([A-Z])/g, '$1 $2')}</Typography>
                  <Chip
                    size='small'
                    label={`ID: ${data.resourceId}`}
                    sx={{
                      maxWidth: '80px',
                      marginTop: '0.5rem',
                      fontSize: '12px',
                    }}
                  />
                </Box>
              ),
              changes: (
                <Box>
                  {changesToDisplay.map(([field, [from, to]]) => (
                    <Typography key={field} sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      <strong>{camelCaseToSentence(field)}:</strong> from{' '}
                      <strong>{from ? JSON.stringify(from) : '""'}</strong> to{' '}
                      <strong>{to ? JSON.stringify(to) : '""'}</strong>
                    </Typography>
                  ))}
                  {Object.keys(data.changes).length > 3 && (
                    <Link
                      component='button'
                      variant='body2'
                      onClick={() => toggleShowExpanded(data.resourceId)}
                      sx={{ display: 'block', marginTop: '8px' }}>
                      {isExpanded ? 'show less' : '...more'}
                    </Link>
                  )}
                </Box>
              ),
            };
          })}
          pagination={{
            rowsPerPageOptions: [DEFAULT_PAGE_SIZE],
            rowPerPage: pagination.perPage,
            page: pagination.currentPage,
            totalCount: pagination.totalCount,
            onPageChange: handleChangePage,
          }}
        />
      </Box>
    </>
  );
};
