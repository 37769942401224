// TableContent.tsx
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { FC } from 'react';
import {
  RPMNoteAllergy,
  RPMNoteDiagnosis,
  RPMNoteInteraction,
  RPMNoteMedication,
  RPMNotePlanItem,
  RPMNoteSection,
  RPMNoteSectionKey,
  RPMNoteSubSectionKey,
} from '../../../interfaces/billing.rpm';

interface TableContentProps {
  section: RPMNoteSection;
}

export const TableContent: FC<TableContentProps> = ({ section }) => {
  switch (section.key) {
    case RPMNoteSectionKey.INTERACTION_LOG:
      return (
        <Table size='small' sx={{ mb: 2 }}>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(section.content as RPMNoteInteraction[]).map((item, idx) => (
              <TableRow key={idx}>
                <TableCell>{item.date}</TableCell>
                <TableCell>{item.type}</TableCell>
                <TableCell>{item.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );

    case RPMNoteSectionKey.MEDICATIONS_ALLERGIES:
      if (!Array.isArray(section.content)) return null;

      const subsections = section.content as {
        key: string;
        isTable: boolean;
        content: RPMNoteMedication[] | RPMNoteAllergy[];
      }[];

      const medSection = subsections.find(
        (s) => s.key === RPMNoteSubSectionKey.CURRENT_MEDICATIONS,
      );
      const allergySection = subsections.find((s) => s.key === RPMNoteSubSectionKey.ALLERGIES);

      return (
        <>
          {medSection?.content && (
            <>
              <Typography variant='subtitle1' sx={{ fontWeight: 'bold', mt: 2, mb: 1 }}>
                Current Medications
              </Typography>
              <Table size='small' sx={{ mb: 2 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Medication</TableCell>
                    <TableCell>Sig</TableCell>
                    <TableCell>Dispense</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(medSection.content as RPMNoteMedication[]).map((med, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{med.name}</TableCell>
                      <TableCell>{med.sig}</TableCell>
                      <TableCell>{med.dispense}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}

          {allergySection?.content && (
            <>
              <Typography variant='subtitle1' sx={{ fontWeight: 'bold', mt: 2, mb: 1 }}>
                Allergies
              </Typography>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Allergen</TableCell>
                    <TableCell>Reaction</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(allergySection.content as RPMNoteAllergy[]).map((allergy, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{allergy.allergen}</TableCell>
                      <TableCell>{allergy.reaction || '-'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </>
      );

    case RPMNoteSectionKey.ASSESSMENT:
      if (!Array.isArray(section.content)) return null;
      return (
        <Table size='small' sx={{ mb: 2 }}>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(section.content as RPMNoteDiagnosis[]).map((diagnosis, idx) => (
              <TableRow key={idx}>
                <TableCell>{diagnosis.code}</TableCell>
                <TableCell>{diagnosis.description}</TableCell>
                <TableCell>{diagnosis.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );

    case RPMNoteSectionKey.PLAN:
      const planItems = Array.isArray(section.content)
        ? section.content
        : ((section.content as any)?.content?.[0]?.content as RPMNotePlanItem[]);

      if (!planItems) return null;

      return (
        <Table size='small' sx={{ mb: 2 }}>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>CPT Code</TableCell>
              <TableCell>Modifiers</TableCell>
              <TableCell>Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(section.content as RPMNotePlanItem[]).map((item, idx) => (
              <TableRow key={idx}>
                <TableCell>{item.type}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.cptCode || '-'}</TableCell>
                <TableCell>{item.modifiers || '-'}</TableCell>
                <TableCell>{item.quantity || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );

    default:
      return null;
  }
};
