import { Box, Button, Chip, Snackbar, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRedirect } from 'react-admin';
import useDebounce from '../../../../hooks/useDebounce';
import {
  LaboratoryTest,
  LaboratoryTestStatus,
  Pagination,
} from '../../../../interfaces/health-data';
import healthDataService from '../../../../services/health-data';
import { DEFAULT_PAGE_SIZE, INITIAL_PAGINATION_STATE } from '../../../../util/constants';
import { getCapitalizedWord } from '../../../../util/stringFormat';
import { CustomTable } from '../../../common/CustomTable';
import './css/laboratory-tests.css';

export const LaboratoryTests = () => {
  const [laboratoryTests, setLaboratoryTests] = useState<LaboratoryTest[]>([]);
  const [message, setMessage] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);

  const redirect = useRedirect();

  const [pagination, setPagination] = useState<Pagination>(INITIAL_PAGINATION_STATE);

  const fetchLaboratoryTests = async ({
    name,
    loincCode,
  }: { name?: string; loincCode?: string } = {}) => {
    const response = await healthDataService.findAllLaboratoryTestsByParams({
      name,
      loincCode,
      status: LaboratoryTestStatus.ACTIVE,
      page,
      pageSize: DEFAULT_PAGE_SIZE,
    });

    if (!response.data) {
      setMessage(response.message || 'There is a problem fetching laboratory tests');
      return;
    }
    setPagination(response.data.pagination);
    setLaboratoryTests(response.data.laboratoryTests);
  };

  const debouncedFetchLaboratoryTests = useDebounce(fetchLaboratoryTests);

  const handleChangePage = (page: number) => setPage(page);

  useEffect(() => {
    (async () => await fetchLaboratoryTests())();
  }, [page]);

  return (
    <>
      <Box className='tab-section laboratory-tests-section' marginTop={0}>
        <Box className='laboratory-tests-header'>
          <Typography>Laboratory Tests</Typography>
          <Box>
            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <Typography
                mb={1}
                sx={{
                  '&.MuiTypography-root': { fontSize: '14px' },
                }}>
                Search by name or LOINC code
              </Typography>
              <TextField
                key={`autocomplete-laboratory-tests`}
                size='small'
                placeholder='Type a name or loinc code'
                sx={{
                  width: '400px',
                  minWidth: '250px',
                }}
                onChange={(e) =>
                  debouncedFetchLaboratoryTests({
                    name: e.target.value,
                    loincCode: e.target.value,
                  })
                }
              />
            </Box>

            <Button
              size='small'
              sx={{ fontSize: '12px', padding: '7px 8px' }}
              variant='contained'
              onClick={() => redirect(`/medical-setup/laboratory-tests/create`)}>
              Add Laboratory Test
            </Button>
          </Box>
        </Box>

        <CustomTable
          columns={[
            { key: 'status', label: 'Status' },
            { key: 'name', label: 'Name' },
            { key: 'units', label: 'Units' },
            { key: 'loincCode', label: 'LOINC' },
            { key: 'actions', label: 'Actions', width: '150px', minWidth: '150px' },
          ]}
          defaultText='No laboratory tests created'
          key={'laboratory-tests-table'}
          rows={laboratoryTests.map((data) => {
            const { status, name, units, loincCode } = data;

            return {
              status: (
                <Chip
                  size='small'
                  label={getCapitalizedWord(status)}
                  sx={{
                    fontSize: '11px',
                    color: 'white',
                    backgroundColor: status === LaboratoryTestStatus.ACTIVE ? '#1FAF84' : '#FF4A47',
                  }}
                />
              ),
              name,
              units: units.join(', ') || 'No Units',
              loincCode,
              actions: [
                <Button
                  size='small'
                  variant='text'
                  onClick={() => redirect(`/medical-setup/laboratory-tests/${data?.id}/show`)}
                  sx={{
                    textTransform: 'capitalize',
                  }}>
                  View
                </Button>,
                <Button
                  size='small'
                  variant='text'
                  onClick={() => redirect(`/medical-setup/laboratory-tests/${data?.id}/edit`)}
                  sx={{
                    textTransform: 'capitalize',
                  }}>
                  Edit
                </Button>,
              ],
            };
          })}
          pagination={{
            rowsPerPageOptions: [DEFAULT_PAGE_SIZE],
            rowPerPage: pagination.perPage,
            totalCount: pagination.totalCount,
            page: pagination.currentPage,
            onPageChange: handleChangePage,
          }}
        />
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!message}
        autoHideDuration={10000}
        onClose={() => setMessage('')}
        message={message}
        ContentProps={{
          style: { marginTop: '50px' },
        }}
      />
    </>
  );
};
