import { FC } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import {
  IncomingPatientLaboratoryResult,
  LaboratoryResultPatient,
  LaboratoryResultSource,
} from '../../../interfaces/health-data';
import healthDataService from '../../../services/health-data';
import '../../common/tab-section.css';
import LaboratoryResultsForm from './Form/LaboratoryResultsForm';

const LaboratoryResultManualInput: FC = () => {
  const redirect = useRedirect();
  const notify = useNotify();

  const handleSaveChanges = async (
    patient: LaboratoryResultPatient,
    resultsOn: string,
    labResults: IncomingPatientLaboratoryResult[],
  ): Promise<void> => {
    if (
      await healthDataService.createLaboratoryResult({
        source: LaboratoryResultSource.ManualInput,
        patient,
        resultsOn: new Date(resultsOn).toISOString(),
        labResults,
      })
    ) {
      return redirect('/laboratory-results');
    }

    notify('We were unable to create laboratory results for this patient', 'error');
  };

  return (
    <LaboratoryResultsForm
      items={[
        { text: 'add new lab', location: '/laboratory-results/add' },
        { text: 'manual input' },
      ]}
      onSaveChanges={handleSaveChanges}
    />
  );
};

export default LaboratoryResultManualInput;
